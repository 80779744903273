import { action } from 'mobx';
import { Record, RecordStore } from '../records/RecordStore';
import WaccDbApi from '../../api/WaccDbApi';

export class Lead extends Record {
  constructor(id, rootStore, collectionName) {
    super(id, rootStore);
    this.collectionName = collectionName;
    this.get();
  }

  @action
  convert() {
    /* Converts the lead to entries in clients and ltd companies collections */
  }

  @action.bound
  getFees() {}

  @action.bound
  getLinks() {}

  convert = () => {
    return new Promise((resolve, reject) => {
      const call = WaccDbApi.post(
        '/' + this.collectionName + '/' + this.id + '/convert',
        {},
        data => {
          resolve(data);
        },
        error => {
          reject(error);
        }
      );
    });
  };

  preProcessRecord(record) {
    if (record.shares !== undefined && record.shares != null) {
      record.shares = record.shares === '' ? 0.0 : parseFloat(record.shares);
    }

    if (record.directors) {
      record.directors = record.directors.map(director => {
        return {
          ...director,
          num_shares:
            director.num_shares !== undefined && director.num_shares !== null
              ? director.num_shares === ''
                ? 0.0
                : parseFloat(director.num_shares)
              : 0.0
        };
      });
    }

    return record;
  }

  rawValues = () => {
    var obj = this.rawRecord();

    /* istanbul ignore else */
    if (this.data.directors) {
      obj['directors'] = this.data.directors.toJS().map(director => {
        var dirobj = { ...director };
        /* istanbul ignore else */
        if (dirobj.address) {
          /* istanbul ignore else */
          if (dirobj.address.addr_lines) {
            dirobj.address = {
              addr_lines: [...dirobj.address.addr_lines],
              postcode: dirobj.address.postcode
            };
          } else {
            dirobj.address = {
              addr_lines: [],
              postcode: dirobj.address.postcode
            };
          }
        }
        return dirobj;
      });
    }

    return obj;
  };
}

export class LeadStore extends RecordStore {
  constructor(rootStore) {
    super(rootStore);
    this.collectionName = 'leads';
  }

  @action
  get(id) {
    this.record = new Lead(id, this.rootStore, this.collectionName);
  }

  preProcessRecord(record) {
    if (record.shares !== undefined && record.shares != null) {
      record.shares = record.shares === '' ? 0.0 : parseFloat(record.shares);
    }

    return record;
  }
}
