import React, { Component } from 'react';
import { Segment, Form, Message } from 'semantic-ui-react';
import { rootStore } from '../mobx/RootStore';
import { observer } from 'mobx-react';

@observer
export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: ''
    };
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleSubmit = () => {
    rootStore.sessionStore.login(
      this.state.username,
      this.state.password ? this.state.password : ''
    );
  };

  render() {
    return (
      <Segment compact>
        <Form onSubmit={this.handleSubmit}>
          <Form.Input
            onChange={this.handleChange}
            value={this.state.username}
            name="username"
            size="large"
            label="Username"
          />
          <Form.Input
            onChange={this.handleChange}
            value={this.state.password}
            name="password"
            size="large"
            label="Password"
            type="password"
          />
          <Form.Button fluid type="submit">
            Login
          </Form.Button>
          {rootStore.sessionStore.loginError && (
            <Message negative>
              <Message.Header>Failed to log in</Message.Header>
            </Message>
          )}
        </Form>
      </Segment>
    );
  }
}
