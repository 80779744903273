import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FieldWrapper } from '../FormApi';

export const CheckboxAdapter = ({
  input: { name, onBlur, onChange, onFocus, value },
  ...rest
}) => (
  <Checkbox
    id={name}
    checked={!!value}
    onBlur={(e, d) => onBlur(e)}
    onChange={(e, d) => onChange(e)}
    onFocus={(e, d) => onFocus(e)}
    {...rest}
  />
);

CheckboxAdapter.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    value: PropTypes.any.isRequired
  })
};

export const CheckboxField = props => (
  <FieldWrapper type="checkbox" Component={CheckboxAdapter} {...props} />
);

CheckboxField.propTypes = CheckboxAdapter.propTypes;
