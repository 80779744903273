import React from 'react';
import {
  Button,
  Container,
  Divider,
  Form,
  Grid,
  Icon,
  Segment
} from 'semantic-ui-react';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';
import {
  validateEmail,
  validateNINumber,
  validatePhoneNumber
} from '../../../types/validation';
import {
  TITLE_OPTIONS,
  AML_STATUS_OPTIONS,
  AML_RISK_OPTIONS
} from '../../../types/enum-options';
import { SelectField } from './SelectField';
import { InputField } from './InputField';
import { DatePickerField } from './DatePickerField';
import { AddressField } from './AddressField';

export const DirectorField = ({ name, onRemove }) => (
  <Container fluid>
    <Grid>
      <Grid.Row columns={16} textAlign="left">
        <Grid.Column width={8}>
          <Segment>
            <Form.Group widths="equal">
              <SelectField
                name={name + '.title'}
                fluid
                label="Title"
                options={TITLE_OPTIONS}
              />
              <InputField
                fluid
                name={name + '.name'}
                placeholder="Name..."
                label="Name"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <InputField
                fluid
                name={name + '.nationality'}
                placeholder="Nationality..."
                label="Nationality"
              />
              <DatePickerField label="Date of Birth" name={name + '.dob'} />
            </Form.Group>
            <Form.Group widths="equal">
              <InputField
                label="Email"
                name={name + '.email'}
                fluid
                validate={validateEmail}
              />
              <InputField
                label="N.I."
                name={name + '.ni_number'}
                fluid
                validate={validateNINumber}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <InputField
                label="Telephone"
                name={name + '.tel'}
                fluid
                validate={validatePhoneNumber}
              />
              <InputField
                label="Alt. Telephone"
                name={name + '.alt_tel'}
                fluid
                validate={validatePhoneNumber}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <InputField
                label="Number of Shares"
                name={name + '.num_shares'}
                fluid
                type="number"
              />
            </Form.Group>
            <Form.Group widths="equal">
              <SelectField
                label="AML Status"
                name={name + '.aml_status'}
                fluid
                options={AML_STATUS_OPTIONS}
              />
              <SelectField
                label="AML Risk"
                name={name + '.aml_risk'}
                fluid
                options={AML_RISK_OPTIONS}
              />
            </Form.Group>
          </Segment>
        </Grid.Column>
        <Grid.Column width={7}>
          <AddressField label="Address" name={name + '.address'} />
        </Grid.Column>
        <Grid.Column width={1}>
          <Button
            negative
            icon
            onClick={(event, data) => {
              onRemove();
              event.preventDefault();
            }}
          >
            <Icon name="trash" />
          </Button>
        </Grid.Column>
      </Grid.Row>
      <Divider />
    </Grid>
  </Container>
);

DirectorField.propTypes = {
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired
};

export const DirectorsAdapter = ({ fields, label, ...rest }) => {
  return (
    <Container fluid>
      <Grid>
        <Grid.Row>
          <Form.Field>
            <label>
              {(fields.length || '0') + ' '} {label}
            </label>
          </Form.Field>
        </Grid.Row>
        {fields.map((director, index) => (
          <Grid.Row key={index} columns={16} verticalAlign="middle">
            <DirectorField
              name={`directors[${index}]`}
              onRemove={() => fields.remove(index)}
            />
          </Grid.Row>
        ))}
        <Grid.Row columns={16}>
          <Grid.Column>
            <Button
              positive
              icon
              onClick={(event, data) => {
                fields.push({
                  name: ''
                });
                event.preventDefault();
              }}
            >
              <Icon name="plus" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

DirectorsAdapter.propTypes = {
  fields: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired
};

export const DirectorArrayField = ({ name, label, ...props }) => (
  <FieldArray
    name={name}
    render={({ fields, ...rest }) => {
      return <DirectorsAdapter fields={fields} label={label} {...props} />;
    }}
  />
);

DirectorArrayField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired
};
