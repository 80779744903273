import React, { Component } from 'react';
import {
  Accordion,
  Loader,
  Icon,
  Feed,
  Grid,
  Container,
  Header,
  Pagination
} from 'semantic-ui-react';
import moment from 'moment';
import { diffChars } from 'diff';
import { rootStore } from '../mobx/RootStore';
import { observer } from 'mobx-react';
import {
  PARTNERSHIP_DESCRIPTOR,
  CLIENT_DESCRIPTOR,
  LTDCO_DESCRIPTOR,
  LEAD_DESCRIPTOR
} from '../types/descriptors';

const getDiff = (old_field, new_field) => {
  var old_value = JSON.stringify(old_field);
  var new_value = JSON.stringify(new_field);

  return diffChars(old_value, new_value).map((part, index) => {
    return (
      <span
        key={index}
        style={{
          backgroundColor: part.added
            ? '#e6ffed'
            : part.removed
              ? '#ffeef0'
              : 'white'
        }}
      >
        {part.value.split('\n').map((item, idx) => {
          return <span key={idx}>{item}</span>;
        })}
      </span>
    );
  });
};

const getIcon = action => {
  switch (action) {
    case 'Created':
      return {
        name: 'plus',
        color: 'green'
      };
    case 'Deleted':
      return {
        name: 'minus',
        color: 'red'
      };
    case 'Modified':
      return {
        name: 'edit',
        color: 'grey'
      };
    case 'Cleared':
      return {
        name: 'trash',
        color: 'orange'
      };
  }

  return 'question';
};

const getRecordType = collection => {
  switch (collection) {
    case CLIENT_DESCRIPTOR.collection:
      return CLIENT_DESCRIPTOR.singularPrintableName.toLowerCase();

    case LTDCO_DESCRIPTOR.collection:
      return LTDCO_DESCRIPTOR.singularPrintableName.toLowerCase();

    case PARTNERSHIP_DESCRIPTOR.collection:
      return PARTNERSHIP_DESCRIPTOR.singularPrintableName.toLowerCase();

    case LEAD_DESCRIPTOR.collection:
      return LEAD_DESCRIPTOR.singularPrintableName.toLowerCase();

    case 'notes':
      return 'note';

    case 'fee':
      return 'fee';
  }

  return 'record';
};

const RecordActivitySummary = ({ event }) => (
  <div>
    {rootStore.name('users', event.modified.user)}{' '}
    {event.action.toLowerCase() +
      ' ' +
      getRecordType(event.record.collection) +
      ' '}
    <a
      href={'/' + event.record.collection + '/' + event.record.id}
      // target="_blank"
    >
      {rootStore.name(event.record.collection, event.record.id)}
    </a>
  </div>
);

const TodoActivitySummary = ({ event }) => (
  <span>
    {rootStore.name('users', event.modified.user)} {event.action.toLowerCase()}{' '}
    todo with id
    {' ' + event.record.id}
    <a />
  </span>
);

const QuickUrlSummary = ({ event }) => (
  <span>
    {rootStore.name('users', event.modified.user)} {event.action.toLowerCase()}{' '}
    quick urls <a />
  </span>
);

const NoteActivitySummary = ({ event }) => (
  <div>
    {rootStore.name('users', event.modified.user)} {event.action.toLowerCase()}{' '}
    a note for{' '}
    {getRecordType(event.association ? event.association.collection : null) +
      ' '}
    {event.association && (
      <a
        href={'/' + event.association.collection + '/' + event.association.id}
        // target="_blank"
      >
        {rootStore.name(event.association.collection, event.association.id)}
      </a>
    )}
  </div>
);

const FeeActivitySummary = ({ event }) => {
  var association = null;
  if (event.association) {
    if (event.association.collection) {
      association = event.association;
    } else {
      const record = rootStore.find(
        event.association.id.$oid
          ? event.association.id.$oid
          : event.association.id
      );
      if (record) {
        association = {
          collection: record.collection,
          id: event.association.id
        };
      }
    }
  }
  return (
    <div>
      {rootStore.name('users', event.modified.user)}{' '}
      {event.action.toLowerCase()} a fee for{' '}
      {getRecordType(association ? association.collection : null) + ' '}
      {association && (
        <a
          href={
            '/' +
            association.collection +
            '/' +
            (association.id.$oid ? association.id.$oid : association.id)
          }
          // target="_blank"
        >
          {rootStore.name(
            association.collection,
            association.id.$oid
              ? event.association.id.$oid
              : event.association.id
          )}
        </a>
      )}
    </div>
  );
};

const ActivityActivitySummary = ({ event }) => {
  return (
    <div>
      {rootStore.name('users', event.modified.user)} cleared all activity
      history
    </div>
  );
};

const getSummaryType = event => {
  switch (event.record.collection) {
    case 'todos':
      return <TodoActivitySummary event={event} />;

    case 'notes':
      return <NoteActivitySummary event={event} />;

    case 'fees':
      return <FeeActivitySummary event={event} />;

    case 'quick_urls':
      return <QuickUrlSummary event={event} />;

    case 'activity':
      return <ActivityActivitySummary event={event} />;

    default:
      return <RecordActivitySummary event={event} />;
  }
};

const RecordActivityDetails = ({ event }) => {
  if (event.fields) {
    if (event.action === 'Modified') {
      return (
        <div>
          {event.fields.map(field => {
            return (
              <div key={field.field}>
                <strong>{field.field}: </strong>
                {getDiff(field.old_value, field.new_value)}
              </div>
            );
          })}
        </div>
      );
    } else if (event.action === 'Created') {
      const new_value = event.fields[0].new_value;
      return (
        <div>
          {Object.keys(new_value).map(key => (
            <div
              key={key}
              style={{ display: 'inline', backgroundColor: '#e6ffed' }}
            >
              <Icon name="plus" color="green" />
              <strong>{key}: </strong>
              {getDiff('', new_value[key])}
              <br />
            </div>
          ))}
        </div>
      );
    } else if (event.action === 'Deleted') {
      const old_value = event.fields[0].old_value;
      return (
        <div>
          {Object.keys(old_value).map(key => (
            <div
              key={key}
              style={{ display: 'inline', backgroundColor: '#ffeef0' }}
            >
              <Icon name="minus" color="red" />
              <strong>{key}: </strong>
              {getDiff(old_value[key], '')}
              <br />
            </div>
          ))}
        </div>
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

const compareLinks = (a, b) => {
  if (a.name == b.name && a.url == b.url) {
    return true;
  }

  return false;
};

const QuickUrlDetails = ({ event }) => {
  const old_value = event.fields[0].old_value;
  const new_value = event.fields[0].new_value;
  const newItems = new_value.filter(e => {
    var found = false;
    old_value.some(value => {
      found = compareLinks(value, e);
      return found;
    });
    return !found;
  });
  const removedItems = old_value.filter(e => {
    var found = false;
    new_value.some(value => {
      found = compareLinks(value, e);
      return found;
    });
    return !found;
  });

  return (
    <div>
      {newItems.map((value, index) => (
        <div
          key={index}
          style={{ display: 'inline', backgroundColor: '#e6ffed' }}
        >
          <Icon name="plus" color="green" />
          Link named {"'" + value.name + "'"} to{' '}
          {/* <a href={value.url} target="_blank">{value.url}</a> */}
          <a href={value.url}>{value.url}</a>
          <br />
        </div>
      ))}
      {removedItems.map((value, index) => (
        <div
          key={index}
          style={{ display: 'inline', backgroundColor: '#ffeef0' }}
        >
          <Icon name="minus" color="red" />
          Link named {"'" + value.name + "'"} to{' '}
          {/* <a href={value.url} target="_blank">{value.url}</a> */}
          <a href={value.url}>{value.url}</a>
          <br />
        </div>
      ))}
    </div>
  );
  // return null;
};

const TodoDetails = ({ event }) => {
  return null;
};

const getDetailType = event => {
  switch (event.record.collection) {
    case 'todos':
      return <TodoDetails event={event} />;

    case 'quick_urls':
      return <QuickUrlDetails event={event} />;

    case 'activity':
      return null;

    default:
      return <RecordActivityDetails event={event} />;
  }
};

class ActivityItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }
  render() {
    const { event } = this.props;
    const { open } = this.state;
    return (
      <Feed.Event>
        <Feed.Label icon={getIcon(event.action)} />
        <Feed.Content>
          <Feed.Summary
            style={{ cursor: 'pointer' }}
            onClick={() => this.setState({ open: !open })}
          >
            {getSummaryType(event)}
            <Icon name={open ? 'dropdown' : 'triangle right'} />
            <Feed.Date>{moment(event.modified.time).fromNow()}</Feed.Date>
          </Feed.Summary>
          {open && <Feed.Extra text>{getDetailType(event)}</Feed.Extra>}
        </Feed.Content>
      </Feed.Event>
    );
  }
}

@observer
export default class ActivityFeed extends Component {
  componentDidMount() {
    rootStore.activityStore.refreshPage();
  }

  handlePageChange = (e, { activePage }) => {
    rootStore.activityStore.setPage(activePage);
  };

  render() {
    const { activity } = rootStore.activityStore;
    const loading =
      rootStore.clientStore.recordsPending ||
      rootStore.ltdcoStore.recordsPending ||
      rootStore.partnershipStore.recordsPending ||
      rootStore.userStore.recordsPending ||
      rootStore.activityStore.activityPending;
    return (
      <Container fluid>
        <Grid columns={16} textAlign="center" verticalAlign="middle">
          <Grid.Row columns={16}>
            <Grid.Column width={14}>
              <Header>Recent Activity</Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              {rootStore.homeUiStore.activityMinimised ? (
                <Icon
                  link
                  name="window restore"
                  onClick={(event, data) => {
                    rootStore.homeUiStore.toggleActivityVisibility();
                    rootStore.activityStore.refreshPage();
                  }}
                />
              ) : (
                <Icon
                  link
                  name="window minimize"
                  onClick={(event, data) => {
                    rootStore.homeUiStore.toggleActivityVisibility();
                  }}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          {rootStore.homeUiStore.activityMinimised
            ? null
            : [
                <Grid.Row key={0} columns={16} textAlign="center">
                  <Grid.Column width={12}>
                    <Pagination
                      defaultActivePage={1}
                      onPageChange={this.handlePageChange}
                      totalPages={rootStore.activityStore.pageCount}
                    />
                  </Grid.Column>
                  <Grid.Column width={4} textAlign="center">
                    {rootStore.activityStore.pageOffset} to{' '}
                    {rootStore.activityStore.pageLimit} of{' '}
                    {rootStore.activityStore.total}
                  </Grid.Column>
                </Grid.Row>,
                <Grid.Row key={1} columns={16} textAlign="left">
                  <Grid.Column width={16} textAlign="left">
                    {loading ? (
                      <Loader />
                    ) : (
                      <Feed>
                        {activity.map(event => (
                          <ActivityItem key={event._id.$oid} event={event} />
                        ))}
                      </Feed>
                    )}
                  </Grid.Column>
                </Grid.Row>
              ]}
        </Grid>
      </Container>
    );
  }
}
