import { observable } from 'mobx';
import {
  lastModifiedAccessor,
  lastModifiedSort,
  privsAccessor
} from '../../utils/utils';
import { TableUiStore } from './TableUiStore';

export class UserUiStore extends TableUiStore {
  @observable
  sortBy = [
    {
      id: 'name',
      desc: true
    }
  ];

  @observable
  columns = [
    {
      Header: 'Name',
      accessor: 'name',
      show: true
    },
    {
      Header: 'Display Name',
      accessor: 'display_name',
      show: true
    },
    {
      id: 'privs',
      Header: 'Privileges',
      accessor: privsAccessor,
      show: true
    },
    {
      accessor: 'email',
      Header: 'Email',
      show: true
    },
    {
      Header: 'Last Login',
      accessor: 'last_login',
      show: false
    },
    {
      id: 'last_modified',
      Header: 'Last Modified',
      accessor: lastModifiedAccessor,
      sortMethod: lastModifiedSort,
      show: false
    }
  ];

  constructor(rootStore) {
    super(rootStore, 'user');
  }
}
