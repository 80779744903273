import React from 'react';
import { Container, Form, Grid, Segment } from 'semantic-ui-react';
import { FIELD_STATUS_OPTIONS, STYLE_OPTIONS } from '../../types/enum-options';
import { InputField } from './components/InputField';
import { CheckboxField } from './components/CheckboxField';
import { EmailField } from './components/EmailField';
import { FormWrapper } from './FormApi';
import {
  validateRequired,
  validateEmail,
  validatePhoneNumber,
  validatePersonalUTR
} from '../../types/validation';
import { SubmissionHistoryField } from './components/SubmissionHistoryField';
import { SelectField } from './components/SelectField';
import { AddressField } from './components/AddressField';

export const PartnershipForm = ({
  initialValues,
  formSubmitHandler,
  onCancel
}) => (
  <FormWrapper
    initialValues={initialValues || { active: true }}
    formSubmitHandler={formSubmitHandler}
    onCancel={onCancel}
  >
    <Form.Group>
      <Container fluid>
        <Grid verticalAlign="middle">
          <Grid.Row width={16}>
            <Grid.Column width={13}>
              <InputField
                label="Name"
                name="name"
                placeholder="Name"
                validate={validateRequired}
              />
            </Grid.Column>
            <Grid.Column width={3}>
              <CheckboxField
                name="active"
                toggle
                fitted
                width={3}
                label="Active"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Form.Group>
    <Grid stackable>
      <Grid.Row columns={16}>
        <Grid.Column mobile={16} computer={16} largeScreen={8} widescreen={8}>
          <AddressField label="Address" name="address" />
        </Grid.Column>
        <Grid.Column mobile={16} computer={16} largeScreen={8} widescreen={8}>
          <Segment>
            <EmailField
              label="Email"
              name="email"
              fluid
              validate={validateEmail}
              email={initialValues ? initialValues.email || '' : ''}
            />
            <InputField label="Partners" name="partners" fluid />
            <Form.Group widths="equal">
              <InputField
                label="Telephone"
                name="tel"
                fluid
                validate={validatePhoneNumber}
              />
              <InputField
                label="Mobile"
                name="mob"
                fluid
                validate={validatePhoneNumber}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <InputField
                label="UTR"
                name="utr"
                fluid
                validate={validatePersonalUTR}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <SelectField
                label="UTR Status"
                name="utr_status"
                fluid
                options={FIELD_STATUS_OPTIONS}
              />
              <SelectField
                label="Agent Authority Status"
                name="agent_authority_status"
                fluid
                options={FIELD_STATUS_OPTIONS}
              />
            </Form.Group>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Form.Group widths="equal">
      <InputField label="Source" name="source" fluid />
      <InputField label="Nature Of Work" name="nature" fluid />
    </Form.Group>
    <Segment>
      <Form.Group widths="equal">
        <SelectField label="Style" name="style" fluid options={STYLE_OPTIONS} />
      </Form.Group>
      <SubmissionHistoryField
        label="Submission History"
        name="submission_history"
      />
    </Segment>
  </FormWrapper>
);
