import React from 'react';
import { observable } from 'mobx';
import {
  getVATStatusColour,
  getVATStatus,
  VAT_PERIODS,
  vatStatusSort
} from '../../utils/utils';
import { TableUiStore } from './TableUiStore';
import { FormWrapper } from '../../components/form/FormApi';
import { IndividualVATStatusField } from '../../components/form/components/VATStatusField';
import { Popup } from 'semantic-ui-react';
import { rootStore } from '../RootStore';

export class VATSchedulerUiStore extends TableUiStore {
  @observable
  sortBy = [
    {
      id: 'name',
      desc: true
    }
  ];

  @observable
  columns = [
    {
      Header: 'Name',
      accessor: 'name',
      show: true
    },
    {
      Header: 'VAT Number',
      accessor: 'vat_no',
      show: false
    },
    {
      Header: 'VAT Schemes',
      accessor: 'vat_schemes',
      show: true
    },
    {
      Header: 'VAT Source',
      accessor: 'vat.source',
      show: true
    },
    ...VAT_PERIODS.map(period => {
      return {
        id: 'vat.submissions.' + period,
        accessor: getVATStatus(period),
        Header:
          period.charAt(0).toUpperCase() +
          period.substr(1, 2) +
          ' ' +
          period.substr(4, 4),
        show: true,
        sortMethod: vatStatusSort,
        Cell: row => {
          return (
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: getVATStatusColour(
                  row.value ? row.value.status : null
                )
              }}
            >
              <Popup
                trigger={
                  <div style={{ width: '100%', height: '100%' }}>
                    {row.value
                      ? row.value.comment
                        ? row.value.comment
                        : row.value.status
                      : ''}
                  </div>
                }
                on="click"
              >
                <FormWrapper
                  formSubmitHandler={(formData, callback) => {
                    const promise = new Promise((resolve, reject) => {
                      rootStore.vatStore.updateOne(
                        row.original._id.$oid,
                        formData,
                        data => {
                          callback();
                          resolve(data);
                        },
                        error => {
                          if (error.responseAsText) {
                            error.responseAsText.then(text => {
                              callback({
                                ...error,
                                FORM_ERROR: text
                              });
                            });
                          } else {
                            callback({
                              FORM_ERROR:
                                'Failed to submit: ' + error.toString()
                            });
                          }
                          reject(error);
                        }
                      );
                    });
                    return promise;
                  }}
                  initialValues={row.original}
                >
                  <IndividualVATStatusField
                    name={'vat.submissions.' + period}
                    period={period}
                    fluid
                  />
                </FormWrapper>
              </Popup>
            </div>
          );
        }
      };
    })
  ];

  constructor(rootStore) {
    super(rootStore, 'vatsched');
  }
}
