import { action, computed } from 'mobx';
import { Record, RecordStore } from '../records/RecordStore';
import { needData } from '../../utils/utils';
import { LTDCO_DESCRIPTOR } from '../../types/descriptors';
import moment from 'moment';

export class LimitedCompany extends Record {
  constructor(id, rootStore, collectionName) {
    super(id, rootStore);
    this.collectionName = collectionName;
    this.get();
  }
}

export class LtdCoStore extends RecordStore {
  constructor(rootStore) {
    super(rootStore);
    this.collectionName = 'ltd_companies';
  }

  @action
  get(id) {
    this.record = new LimitedCompany(id, this.rootStore, this.collectionName);
  }

  @computed
  get needPayeRef() {
    const filtered = this.records.filter(item => {
      if (!item.active) {
        return false;
      }
      return needData(item.paye_ref_status);
    });

    return filtered.map(item => {
      return {
        descriptor: LTDCO_DESCRIPTOR,
        id: item._id.$oid,
        name: item.name,
        status: item.paye_ref_status
      };
    });
  }

  @computed
  get needAccCode() {
    const filtered = this.records.filter(item => {
      if (!item.active) {
        return false;
      }
      return needData(item.acc_code_status);
    });

    return filtered.map(item => {
      return {
        descriptor: LTDCO_DESCRIPTOR,
        id: item._id.$oid,
        name: item.name,
        status: item.acc_code_status
      };
    });
  }

  @computed
  get needAmlStatus() {
    return [];
  }

  @computed
  get trialExpired() {
    const timeNow = moment();
    const filtered = this.records.filter(item => {
      if (!item.active) {
        return false;
      }
      if (item.trial_end && moment(item.trial_end).isSameOrBefore(timeNow)) {
        return true;
      }
      return false;
    });

    return filtered.map(item => {
      return {
        descriptor: LTDCO_DESCRIPTOR,
        id: item._id.$oid,
        name: item.name,
        status: item.trial_end
      };
    });
  }
}
