import React from 'react';
import { Form, Grid, Segment } from 'semantic-ui-react';
import { InputField } from './components/InputField';
import { CheckboxField } from './components/CheckboxField';
import { EmailField } from './components/EmailField';
import { FormWrapper } from './FormApi';
import {
  validatePhoneNumber,
  validateRequired,
  validateEmail
} from '../../types/validation';
import { TextAreaField } from './components/TextAreaField';
import { AddressField } from './components/AddressField';

export const ContactForm = ({ initialValues, formSubmitHandler, onCancel }) => (
  <FormWrapper
    initialValues={initialValues || {}}
    formSubmitHandler={formSubmitHandler}
    onCancel={onCancel}
  >
    <Grid stackable verticalAlign="middle">
      <Grid.Row columns={16}>
        <Grid.Column mobile={16} computer={16} largeScreen={13} widescreen={13}>
          <InputField label="Name" name="name" validate={validateRequired} />
        </Grid.Column>
        <Grid.Column
          textAlign="center"
          mobile={8}
          computer={8}
          largeScreen={3}
          widescreen={3}
        >
          <CheckboxField label="Public" name="public" toggle fitted />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={16}>
        <Grid.Column width={16}>
          <InputField label="Description" name="description" />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Grid stackable>
      <Grid.Row columns={16}>
        <Grid.Column mobile={16} computer={16} largeScreen={8} widescreen={8}>
          <AddressField label="Address" name="address" />
        </Grid.Column>
        <Grid.Column mobile={16} computer={16} largeScreen={8} widescreen={8}>
          <Segment>
            <EmailField
              label="Email"
              name="email"
              fluid
              validate={validateEmail}
              email={initialValues ? initialValues.email || '' : ''}
            />
            <Form.Group widths="equal">
              <InputField
                label="Telephone 1"
                name="tel_1"
                fluid
                validate={validatePhoneNumber}
              />
              <InputField
                label="Telephone 2"
                name="tel_2"
                fluid
                validate={validatePhoneNumber}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <InputField
                label="Telephone 3"
                name="tel_3"
                fluid
                validate={validatePhoneNumber}
              />
            </Form.Group>
          </Segment>
          <Segment>
            <TextAreaField
              name="notes"
              rows={3}
              label="Notes"
              placeholder="Notes..."
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </FormWrapper>
);
