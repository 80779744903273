import { observable, computed, action } from 'mobx';
import WaccDbApi from '../../api/WaccDbApi';
import moment from 'moment';
import { TAX_YEARS, getTaxStatus } from '../../utils/utils';
import { toJS } from 'mobx';

export class Record {
  etag = null;
  @observable data = null;
  @observable fees = [];
  @observable links = [];
  @observable notes = [];
  @observable todos = [];
  @observable dataPending = false;
  @observable feesPending = false;
  @observable linksPending = false;
  @observable notesPending = false;
  @observable todosPending = false;

  constructor(id, rootStore) {
    this.id = id;
    this.rootStore = rootStore;
  }

  @action.bound
  clear() {
    this.etag = null;
    this.data = null;
    this.fees = [];
    this.links = [];
    this.notes = [];
    this.todos = [];
    this.dataPending = false;
    this.feesPending = false;
    this.linksPending = false;
    this.notesPending = false;
    this.todosPending = false;
  }

  @action.bound
  get() {
    this.dataPending = true;
    return WaccDbApi.get(
      '/' + this.collectionName + '/' + this.id,
      this.fetchRecordSuccess,
      this.fetchRecordError
    );
  }

  preProcessRecord(record) {
    return record;
  }

  @action.bound
  update(record, onSuccess, onError) {
    return WaccDbApi.patchWithEtag(
      '/' + this.collectionName + '/' + this.id,
      this.etag,
      this.preProcessRecord(record),
      data => {
        this.get();
        onSuccess(data);
      },
      error => {
        onError(error);
      }
    );
  }

  @action.bound
  getFees() {
    this.feesPending = true;
    return WaccDbApi.get(
      '/' + this.collectionName + '/' + this.id + '/fees?$expand=1',
      this.fetchFeesSuccess,
      this.fetchFeesError
    );
  }

  @action.bound
  getLinks() {
    this.linksPending = true;
    return WaccDbApi.get(
      '/' + this.collectionName + '/' + this.id + '/links?$expand=1',
      this.fetchLinksSuccess,
      this.fetchLinksError
    );
  }

  @action.bound
  getNotes() {
    this.notesPending = true;
    return WaccDbApi.get(
      '/' + this.collectionName + '/' + this.id + '/notes?$expand=1',
      this.fetchNotesSuccess,
      this.fetchNotesError
    );
  }

  @action.bound
  getTodos() {
    this.todosPending = true;
    return WaccDbApi.get(
      '/' + this.collectionName + '/' + this.id + '/todos?$expand=1',
      this.fetchTodosSuccess,
      this.fetchTodosError
    );
  }

  @action.bound
  fetchRecordSuccess(data) {
    // Trigger retrieval of links, notes and fees
    this.dataPending = false;
    this.data = data.json;
    this.etag = data.headers.get('ETag');
    this.getFees();
    this.getLinks();
    this.getNotes();
    this.getTodos();
  }

  @action.bound
  fetchRecordError(error) {
    this.dataPending = false;
  }

  @action.bound
  fetchFeesSuccess(data) {
    this.feesPending = false;
    this.fees = data.json.Members;
  }

  @action.bound
  fetchFeesError(error) {
    this.feesPending = false;
  }

  @action.bound
  fetchLinksSuccess(data) {
    this.linksPending = false;
    this.links = data.json.Members;
  }

  @action.bound
  fetchLinksError(error) {
    this.linksPending = false;
  }

  @action.bound
  fetchNotesSuccess(data) {
    this.notesPending = false;
    this.notes = data.json.Members;
  }

  @action.bound
  fetchNotesError(error) {
    this.notesPending = false;
  }

  @action.bound
  fetchTodosSuccess(data) {
    this.todosPending = false;
    this.todos = data.json.Members;
  }

  @action.bound
  fetchTodosError(error) {
    this.todosPending = false;
  }

  refreshFees = () => {
    this.getFees();
  };

  addFee = (fee, onSuccess, onFailure) => {
    if (!fee.effective_date) {
      /* If no date is selected, force the date to today */
      fee.effective_date = moment().format('YYYY-MM-DD');
    }

    return WaccDbApi.post(
      '/' + this.collectionName + '/' + this.id + '/fees',
      fee,
      data => {
        this.refreshFees();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  updateFee = (fee, onSuccess, onFailure) => {
    return WaccDbApi.patch(
      '/fees/' + fee._id.$oid,
      fee,
      data => {
        this.refreshFees();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  deleteFee = (fee, onSuccess, onFailure) => {
    return WaccDbApi.delete(
      '/fees/' + fee._id.$oid,
      data => {
        this.refreshFees();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  refreshLinks = () => {
    this.getLinks();
  };

  addLink = (link, onSuccess, onFailure) => {
    if (link && link.includes(this.id)) {
      /* Don't allow a link to be added to ourself */
      return;
    }

    return WaccDbApi.post(
      '/links',
      {
        links: [
          '/waccdb/v1/' + this.collectionName + '/' + this.id,
          '/waccdb/v1/' + link
        ]
      },
      data => {
        this.refreshLinks();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  removeLink = (link, onSuccess, onFailure) => {
    const fullLink = '/waccdb/v1/' + link;
    this.links.forEach((item, idx, arr) => {
      if (item.links.includes(fullLink)) {
        const call = WaccDbApi.delete(
          '/links/' + item._id.$oid,
          data => {
            this.refreshLinks();
            onSuccess(data);
          },
          error => {
            onFailure(error);
          }
        );
      }
    });
  };

  refreshNotes = () => {
    this.getNotes();
  };

  addNote = (note, onSuccess, onFailure) => {
    if (note.note === '') {
      /* No note - ignore */
      return;
    }

    return WaccDbApi.post(
      '/' + this.collectionName + '/' + this.id + '/notes',
      note,
      data => {
        this.refreshNotes();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  updateNote = (note, onSuccess, onFailure) => {
    if (note.note === '') {
      /* No note - ignore */
      return;
    }
    return WaccDbApi.patch(
      '/notes/' + note._id.$oid,
      note,
      data => {
        this.refreshNotes();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  deleteNote = (note, onSuccess, onFailure) => {
    return WaccDbApi.delete(
      '/notes/' + note._id.$oid,
      data => {
        this.refreshNotes();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  refreshTodos = () => {
    this.getTodos();
  };

  addTodo = (todo, onSuccess, onFailure) => {
    return WaccDbApi.post(
      '/' + this.collectionName + '/' + this.id + '/todos',
      todo,
      data => {
        this.refreshTodos();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  updateTodo = (todo, onSuccess, onFailure) => {
    return WaccDbApi.patch(
      '/todos/' + todo._id.$oid,
      todo,
      data => {
        this.refreshTodos();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  deleteTodo = (todo, onSuccess, onFailure) => {
    return WaccDbApi.delete(
      '/todos/' + todo._id.$oid,
      data => {
        this.refreshTodos();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  rawRecord = () => {
    var obj = { ...this.data };

    /* istanbul ignore next */
    if (this.data.address) {
      /* istanbul ignore next */
      if (this.data.address.addr_lines) {
        obj.address = {
          addr_lines: [...this.data.address.addr_lines],
          postcode: this.data.address.postcode
        };
      } else {
        obj.address = {
          addr_lines: [],
          postcode: this.data.address.postcode
        };
      }
    }

    /* istanbul ignore next */
    if (this.data.operating_address) {
      /* istanbul ignore next */
      if (this.data.operating_address.addr_lines) {
        obj.operating_address = {
          addr_lines: [...this.data.operating_address.addr_lines],
          postcode: this.data.operating_address.postcode
        };
      } else {
        obj = {
          ...this.data,
          operating_address: {
            addr_lines: [],
            postcode: this.data.operating_address.postcode
          }
        };
      }
    }

    if (this.data.submission_history) {
      obj.submission_history = toJS(this.data.submission_history);
      TAX_YEARS.forEach(year => {
        obj.submission_history['year_end_' + year] = getTaxStatus(
          parseInt(year)
        )(obj);
      });
    }

    delete obj['period_end'];

    return obj;
  };

  rawValues = () => {
    return this.rawRecord();
  };
}

export class RecordStore {
  @observable records = [];
  @observable recordsById = new Map();
  @observable recordsPending = false;
  @observable record = null;

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.collectionLastModified = null;
  }

  getRecords = () => {
    this.refreshCollection();
    return this.records;
  };

  @action
  get(id) {
    this.record = new Record(id, this.rootStore);
  }

  @action
  clear() {
    this.record = null;
  }

  @action
  refreshCollection() {
    this.recordsPending = true;
    return WaccDbApi.get(
      '/' + this.collectionName + '?$expand=1',
      this.fetchRecordsSuccess,
      this.fetchRecordsError,
      [
        {
          header: 'If-Modified-Since',
          value: this.collectionLastModified
        }
      ]
    );
  }

  @action.bound
  fetchRecordsSuccess(data) {
    this.recordsPending = false;
    if (data.status != 304) {
      this.records = data.json.Members;
      this.recordsById.replace(
        this.records.map(record => [record._id.$oid, record])
      );
      this.collectionLastModified = data.headers.get('Last-Modified');
    }
  }

  @action.bound
  fetchRecordsError(error) {
    this.recordsPending = false;
  }

  deleteOne = (id, onSuccess, onFailure) => {
    return WaccDbApi.delete(
      '/' + this.collectionName + '/' + id,
      data => {
        this.refreshCollection();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  preProcessRecord(record) {
    return record;
  }

  createOne = (record, onSuccess, onFailure) => {
    return WaccDbApi.post(
      '/' + this.collectionName,
      this.preProcessRecord(record),
      data => {
        this.refreshCollection();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  updateOne = (id, record, onSuccess, onFailure) => {
    return WaccDbApi.patch(
      '/' + this.collectionName + '/' + id,
      this.preProcessRecord(record),
      data => {
        this.refreshCollection();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  name = id => {
    const record = this.recordsById.get(id);
    if (record) {
      return computed(() => record.name).get();
    } else {
      return computed(() => id).get();
    }
  };
}
