import React from 'react';
import { Segment, Tab, Menu } from 'semantic-ui-react';
import { InputField } from './InputField';

export const AddressField = ({ name, label }) => (
  <Segment>
    {label && (
      <div className="field">
        <label>{label}</label>
      </div>
    )}
    <InputField name={name + '.addr_lines[0]'} />
    <InputField name={name + '.addr_lines[1]'} />
    <InputField name={name + '.addr_lines[2]'} />
    <InputField name={name + '.addr_lines[3]'} />
    <InputField name={name + '.addr_lines[4]'} />
    <InputField label="Postcode" name={name + '.postcode'} />
  </Segment>
);

export const TabbedAddressField = ({ names }) => (
  <Tab
    menu={{ fluid: true, tabular: true }}
    panes={names.map(item => ({
      menuItem: <Menu.Item key={item.name}>{item.title}</Menu.Item>,
      render: () => <AddressField name={item.name} />
    }))}
  />
);
