import React from 'react';
import { Button, Container, Grid, Icon } from 'semantic-ui-react';
import { FieldArray } from 'react-final-form-arrays';
import PropTypes from 'prop-types';
import { CheckboxField } from './CheckboxField';
import { InputField } from './InputField';

export const ignoreEnterKey = (event, data) => {
  if (event.keyCode === 13) {
    event.stopPropagation();
    event.preventDefault();
  }
};

export const TodoField = ({ name, onRemove }) => {
  return (
    <Container fluid>
      <Grid>
        <Grid.Row columns={16} textAlign="left" verticalAlign="middle">
          <Grid.Column width={12}>
            <InputField
              fluid
              name={name + '.text'}
              placeholder="Task..."
              onKeyDown={ignoreEnterKey}
              onKeyUp={ignoreEnterKey}
              onKeyPress={ignoreEnterKey}
            />
          </Grid.Column>
          <Grid.Column width={2}>
            <CheckboxField name={name + '.done'} label="Done" />
          </Grid.Column>
          <Grid.Column width={2}>
            <Button
              negative
              icon
              onClick={(event, data) => {
                onRemove();
                event.preventDefault();
              }}
            >
              <Icon name="trash" />
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

TodoField.propTypes = {
  name: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired
};

export const TodoAdapter = ({ fields, ...rest }) => (
  <Container fluid>
    <Grid>
      {fields.map((task, index) => {
        return (
          <Grid.Row key={index} columns={16} verticalAlign="middle">
            <TodoField
              name={`tasks[${index}]`}
              onRemove={() => fields.remove(index)}
            />
          </Grid.Row>
        );
      })}
      <Grid.Row columns={16}>
        <Grid.Column>
          <Button
            positive
            icon
            onClick={(event, data) => {
              fields.push({
                text: '',
                done: false
              });
              event.preventDefault();
            }}
          >
            <Icon name="plus" />
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </Container>
);

TodoAdapter.propTypes = {
  fields: PropTypes.object.isRequired
};

export const TodoArrayField = ({ name, ...props }) => (
  <FieldArray
    name={name}
    render={({ fields }) => {
      return <TodoAdapter fields={fields} {...props} />;
    }}
  />
);

TodoArrayField.propTypes = {
  name: PropTypes.string.isRequired
};
