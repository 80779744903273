import React from 'react';
import _ from 'lodash';
import { Button, Form as SemanticForm, Message } from 'semantic-ui-react';
import { Field, Form } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import PropTypes from 'prop-types';
import NavigationPrompt from 'react-router-navigation-prompt';
import NavigationConfirmationModal from '../NavigationConfirmationModal';

export const FieldWrapper = ({
  allowNull,
  Component,
  label,
  name,
  type,
  message,
  validate,
  ...rest
}) => (
  <Field
    allowNull={allowNull}
    name={name}
    validate={validate}
    render={renderProps => {
      if (message) {
        if ({}.toString.call(message) === '[object Function]') {
          message = message(renderProps.input.value);
        }
      }
      return (
        <SemanticForm.Field
          error={!!(renderProps.meta.touched && renderProps.meta.error)}
        >
          {label && <label htmlFor={name}>{label}</label>}
          <Component
            label={label}
            {...renderProps}
            {...rest}
            autoComplete="off"
          />
          {renderProps.meta.touched && renderProps.meta.error ? (
            <Message negative>
              <Message.Header>{label} is invalid</Message.Header>
              {renderProps.meta.error.error}
            </Message>
          ) : null}
          {message && <Message content={message} />}
        </SemanticForm.Field>
      );
    }}
    type={type}
  />
);

FieldWrapper.propTypes = {
  allowNull: PropTypes.bool,
  Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  validate: PropTypes.func
};

function isEmptyObject(obj) {
  for (var key in obj) {
    return false;
  }
  return true;
}

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function(result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

/** A CSS style that can be used to hide the components that will fool
 * chrome autocomplete and stop it trying to prepopulate password fields */
const autofillStyle = {
  height: 0,
  width: '1px',
  position: 'absolute',
  left: 0,
  top: 0
};

export const FormWrapper = ({
  children,
  initialValues,
  foolAutoComplete,
  formSubmitHandler,
  onCancel,
  withNavPrompt
}) => (
  <Form
    initialValues={initialValues}
    mutators={{
      ...arrayMutators
    }}
    onSubmit={(values, formApi, callback) => {
      Object.keys(values).forEach(key => {
        if (values[key] === undefined) {
          values[key] = null;
        }
      });
      return formSubmitHandler(values, callback);
    }}
    autoComplete="off"
  >
    {({ form, handleSubmit, submitErrors, values, ...rest }) => {
      const isPristine = initialValues
        ? isEmptyObject(difference(values, initialValues))
        : false;
      return (
        <SemanticForm
          autoComplete="off"
          onSubmit={handleSubmit}
          onReset={form.reset}
        >
          {/* Chrome is wierd... It ignores the autocomplete property and
                    * will autofill fields according to it's own plans. This set
                    * of hidden fields tricks chrome into thinking they are the
                    * right fields to populate */
          foolAutoComplete && (
            <div style={autofillStyle}>
              <input style={autofillStyle} type="text" name="fakeusername" />
              <input
                style={autofillStyle}
                type="password"
                name="fakepassword"
              />
            </div>
          )}
          {withNavPrompt && (
            <NavigationPrompt
              // Confirm navigation if going to a path that does not start with current path:
              when={(crntLocation, nextLocation) => {
                return (
                  !isPristine &&
                  (!nextLocation ||
                    !nextLocation.pathname.startsWith(crntLocation.pathname))
                );
              }}
            >
              {({ isActive, onCancel, onConfirm }) => {
                if (isActive) {
                  return (
                    <NavigationConfirmationModal
                      active={isActive}
                      onCancel={onCancel}
                      onConfirm={onConfirm}
                    />
                  );
                }
                return null;
              }}
            </NavigationPrompt>
          )}
          {children}
          {submitErrors && (
            <Message negative>
              <Message.Header>Error submitting form</Message.Header>
              <p>{submitErrors.FORM_ERROR}</p>
            </Message>
          )}
          <SemanticForm.Group>
            <SemanticForm.Field>
              <Button disabled={isPristine} type="submit" positive>
                Save
              </Button>
            </SemanticForm.Field>
            <SemanticForm.Field>
              {onCancel ? (
                <Button
                  type="button"
                  negative
                  onClick={(e, d) => {
                    onCancel(!isPristine);
                  }}
                >
                  Cancel
                </Button>
              ) : (
                <div>
                  {!isPristine && (
                    <Button type="reset" negative>
                      Clear
                    </Button>
                  )}
                </div>
              )}
            </SemanticForm.Field>
          </SemanticForm.Group>
        </SemanticForm>
      );
    }}
  </Form>
);

FormWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.object
  ]).isRequired,
  initialValues: PropTypes.object,
  foolAutoComplete: PropTypes.bool,
  formSubmitHandler: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  withNavPrompt: PropTypes.bool
};

FormWrapper.defaultProps = {
  withNavPrompt: true
};
