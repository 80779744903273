import React from 'react';
import { Divider, Form, Header, Message, Segment } from 'semantic-ui-react';
import { PRIVILEGE_OPTIONS } from '../../types/enum-options';

import { InputField } from './components/InputField';
import { EmailField } from './components/EmailField';
import { CheckboxField } from './components/CheckboxField';
import { SelectField } from './components/SelectField';
import { FormWrapper } from './FormApi';
import { validateEmail } from '../../types/validation';

const UserForm = ({
  changePassword,
  initialValues,
  formSubmitHandler,
  onCancel
}) => (
  <FormWrapper
    initialValues={initialValues || {}}
    formSubmitHandler={formSubmitHandler}
    onCancel={onCancel}
    foolAutoComplete
  >
    <Segment>
      <Form.Group widths="equal">
        <InputField name="name" label="Name" autoComplete="new-user" fluid />
        <InputField
          name="display_name"
          label="Display Name"
          autoComplete="new-user-display-name"
          fluid
        />
      </Form.Group>
      {changePassword && (
        <Form.Group>
          <InputField
            label="Password"
            name="password"
            type="password"
            autoComplete="new-pword"
            width={16}
          />
        </Form.Group>
      )}
      <Form.Group widths="equal">
        <EmailField
          label="Email"
          name="email"
          fluid
          validate={validateEmail}
          autoComplete="new-user-email"
          email={initialValues ? initialValues.email || '' : ''}
        />
        <SelectField
          name="privs"
          fluid
          label="Privileges"
          placeholder="Select Privileges"
          options={PRIVILEGE_OPTIONS}
          multiple
        />
      </Form.Group>
      <Header>Notifications</Header>
      <Divider />
      <Segment.Group horizontal>
        <Segment>
          <CheckboxField name="notifications.assignment" label="Assignment" />
          <Message attached="top">
            <Message.Header>Assignment</Message.Header>
            <p>
              An assignment notification is sent when a todo or note is assigned
              to you by another user
            </p>
          </Message>
        </Segment>
        <Segment>
          <CheckboxField name="notifications.mention" label="Mention" />
          <Message attached="top">
            <Message.Header>Mention</Message.Header>
            <p>
              A mention notification is sent when you are mentioned in a todo or
              note using the @ shortcut (e.g. @username)
            </p>
          </Message>
        </Segment>
      </Segment.Group>
    </Segment>
  </FormWrapper>
);

export default UserForm;
