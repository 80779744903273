import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import Login from '../Login';
import ActivityFeed from '../ActivityFeed';
import QuickLinks from '../QuickLinks';
import ToDo from '../ToDo';
import { Grid, Container, Segment } from 'semantic-ui-react';
import { rootStore } from '../../mobx/RootStore';
import { VAT_PERIODS } from '../../utils/utils';

@observer
export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null
    };
  }

  componentDidMount() {
    rootStore.sessionStore.checkSession();
  }

  render() {
    return (
      <div>
        <Helmet>
          <title>WACCDB - Home</title>
        </Helmet>
        <Container fluid>
          <Grid stackable>
            <Grid.Row>
              <Grid.Column width={8}>
                <Grid.Row>
                  <Segment>
                    <QuickLinks />
                  </Segment>
                </Grid.Row>
                {!rootStore.homeUiStore.activityMinimised && (
                  <Grid.Row>
                    <Segment>
                      <ToDo history={this.props.history} />
                    </Segment>
                  </Grid.Row>
                )}
              </Grid.Column>
              <Grid.Column width={8}>
                <Segment>
                  <ActivityFeed />
                </Segment>
              </Grid.Column>
            </Grid.Row>
            {rootStore.homeUiStore.activityMinimised && (
              <Grid.Row columns={16}>
                <Grid.Column width={16}>
                  <Segment>
                    <ToDo history={this.props.history} />
                  </Segment>
                </Grid.Column>
              </Grid.Row>
            )}
          </Grid>
        </Container>
      </div>
    );
  }
}
