export default class StorageManager {
  constructor(key, defaults, sharedSession = false) {
    this.key = key;
    this.defaults = defaults;
    this.sharedSession = sharedSession;
    this.promise = null;
    this.timeout = null;

    if (this.sharedSession) {
      try {
        var theLocalStorage = null;
        this.storage = window.sessionStorage;

        try {
          theLocalStorage = window.localStorage;
        } catch (e) {
          console.warn(
            'Unable to share session storage with local storage event\n',
            e
          );
          this.storage = undefined;
        }

        this.promise = new Promise((resolve, reject) => {
          const storageListener = event => {
            if (event.key == 'getSessionStorage') {
              theLocalStorage.setItem('sessionStorage', window.sessionStorage);
              theLocalStorage.removeItem('sessionStorage');
            } else if (
              event.key == 'sessionStorage' &&
              !window.sessionStorage.length
            ) {
              // sessionStorage is empty -> fill it
              var data = JSON.parse(event.newValue);

              var key = '';
              for (key in data) {
                window.sessionStorage.setItem(key, JSON.stringify(data[key]));
              }

              /* istanbul ignore next */
              if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
              }

              resolve('Got storage');
            }
          };
          this.storageListener = storageListener;
          window.addEventListener('storage', this.storageListener);

          if (window.sessionStorage === undefined) {
            resolve('No storage');
          } else if (!window.sessionStorage.length) {
            this.timeout = setTimeout(() => {
              resolve('No storage');
            }, 250);
            /* Ask another tab for the session storage */
            theLocalStorage.setItem('getSessionStorage', Date.now());
          } else {
            resolve('Already have storage');
          }
        });
      } catch (e) {
        console.warn('Session storage is unavailable\n', e);
        this.storage = undefined;
      }
    } else {
      try {
        this.storage = window.localStorage;
      } catch (e) {
        console.warn('Local storage is unavailable\n', e);
        this.storage = undefined;
      }
    }

    this.get = this.get.bind(this);
    this.put = this.put.bind(this);
  }

  removeListener = () => {
    window.removeEventListener('storage', this.storageListener);
  };

  get() {
    if (this.storage) {
      var item = this.storage.getItem(this.key);
      if (!item) {
        return this.defaults;
      } else {
        var itemObj = JSON.parse(item);
        var write = false;
        for (var property in this.defaults) {
          /* istanbul ignore else  */
          if (this.defaults.hasOwnProperty(property)) {
            /* istanbul ignore else  */
            if (!itemObj.hasOwnProperty(property)) {
              itemObj[property] = this.defaults[property];
              write = true;
            }
          }
        }

        if (write) {
          this.put(itemObj);
        }

        return itemObj;
      }
    } else {
      return this.defaults;
    }
  }

  put(object) {
    if (this.storage) {
      try {
        this.storage.setItem(this.key, JSON.stringify(object));
      } catch (e) {
        /* istanbul ignore next */
        console.warn('Failed to save object to local storage\n', e);
      }
    }
  }
}
