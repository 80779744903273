import React from 'react';
import 'moment/locale/en-gb.js';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import { FieldWrapper } from '../FormApi';
import { ConsistentDatePicker } from '../../ConsistentDatePicker';

export const DatePickerAdapter = ({
  input: { name, onBlur, onChange, onFocus, value },
  label,
  placeholder,
  ...rest
}) => (
  <ConsistentDatePicker
    id={name}
    value={value}
    onBlur={(e, d) => onBlur(e)}
    onChange={date => onChange(date)}
    onFocus={(e, d) => onFocus(e)}
    placeholderText={placeholder || label}
    {...rest}
  />
);

DatePickerAdapter.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    value: PropTypes.any.isRequired
  }),
  label: PropTypes.string,
  placeholder: PropTypes.string
};

export const DatePickerField = props => (
  <FieldWrapper allowNull Component={DatePickerAdapter} {...props} />
);

DatePickerField.propTypes = DatePickerAdapter.propTypes;
