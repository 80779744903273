import React from 'react';
import { Select } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FieldWrapper } from '../FormApi';

export const SelectAdapter = ({
  input: { name, onBlur, onChange, onFocus, value },
  label,
  options,
  placeholder,
  ...rest
}) => (
  <Select
    id={name}
    value={value || ''}
    onBlur={(e, d) => onBlur(e)}
    onChange={(e, d) => onChange(d.value)}
    onFocus={(e, d) => onFocus(e)}
    options={options}
    placeholder={placeholder || label}
    {...rest}
  />
);

SelectAdapter.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    value: PropTypes.any.isRequired
  }),
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      text: PropTypes.string.isRequired,
      value: PropTypes.string
    })
  ),
  placeholder: PropTypes.string
};

export class SelectField extends React.Component {
  shouldComponentUpdate(nextProps, nextState) {
    /* I was finding that selects were being re-rendered when any field's value
     * was changed, which for suggestion fields is a really expensive thing.
     * Adding this check means that they are only re-rendered when necessary */
    if (this.props.options === nextProps.options) {
      return false;
    }

    return true;
  }

  render() {
    return <FieldWrapper Component={SelectAdapter} {...this.props} />;
  }
}

SelectField.propTypes = SelectAdapter.propTypes;
