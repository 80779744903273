import { observable, action } from 'mobx';
import StorageManager from '../../utils/StorageManager';

export class TodoUiStore {
  @observable showCompleted = false;
  @observable selectedOwner = null;
  @observable visiblePriority = null;
  @observable sortBy = null;
  @observable sortDescending = true;

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.uiStorage = new StorageManager('todoUi', {
      showCompleted: this.showCompleted,
      selectedOwner: this.selectedOwner,
      visiblePriority: this.visiblePriority,
      sortBy: this.sortBy,
      sortDescending: this.sortDescending
    });

    const storedUi = this.uiStorage.get();
    Object.keys(storedUi).forEach((key, index) => {
      /* istanbul ignore else  */
      if (this.hasOwnProperty(key)) {
        this[key] = storedUi[key];
      }
    });
  }

  @action.bound
  toggleShowCompleted() {
    this.showCompleted = !this.showCompleted;
    this.store();
  }

  @action.bound
  setSelectedOwner(username) {
    this.selectedOwner = username;
    this.store();
  }

  @action.bound
  setPriority(priority) {
    this.visiblePriority = priority;
    this.store();
  }

  @action.bound
  setSortBy(sortBy) {
    this.sortBy = sortBy;
    this.store();
  }

  @action.bound
  toggleSortDescending() {
    this.sortDescending = !this.sortDescending;
    this.store();
  }

  store = () => {
    this.uiStorage.put({
      showCompleted: this.showCompleted,
      selectedOwner: this.selectedOwner,
      visiblePriority: this.visiblePriority,
      sortBy: this.sortBy,
      sortDescending: this.sortDescending
    });
  };
}
