import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { render, unmountComponentAtNode } from 'react-dom';

export default class ConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
  }

  close = () => {
    removeModalElement();
  };

  keyboardClose = event => {
    if (event.keyCode === 27) {
      this.close();
    }
  };

  componentDidMount = () => {
    document.addEventListener('keydown', this.keyboardClose, false);
  };

  componentWillUnmount = () => {
    document.removeEventListener('keydown', this.keyboardClose, false);
  };

  render() {
    const {
      cancelText,
      confirmText,
      message,
      onConfirm,
      onCancel,
      title
    } = this.props;

    return (
      <Modal open={true}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>{message}</Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="green"
            onClick={() => {
              onConfirm();
              this.close();
            }}
          >
            {confirmText}
          </Button>
          <Button
            color="red"
            onClick={() => {
              onCancel();
              this.close();
            }}
          >
            {cancelText}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

ConfirmationModal.defaultProps = {
  title: 'Are you sure?',
  message: 'Are you sure?',
  confirmText: 'OK',
  cancelText: 'Cancel',
  onConfirm: () => null,
  onCancel: () => null
};

const createModalElement = properties => {
  const divTarget = document.createElement('div');
  divTarget.id = 'confirmation-modal';
  document.body.appendChild(divTarget);

  render(<ConfirmationModal {...properties} />, divTarget);
};

const removeModalElement = () => {
  const target = document.getElementById('confirmation-modal');
  unmountComponentAtNode(target);
  target.parentNode.removeChild(target);
};

export const confirmAlert = properties => {
  createModalElement(properties);
};
