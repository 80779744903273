import { observable, action } from 'mobx';
import WaccDbApi from '../../api/WaccDbApi';

export class QuickLinksStore {
  @observable quickLinks = [];
  @observable quickLinksPending = false;

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  getQuickLinks = () => {
    this.refreshCollection();
    return this.quickLinks;
  };

  @action
  refreshCollection() {
    this.quickLinksPending = true;
    const call = WaccDbApi.get(
      '/quick_urls?$expand=1',
      this.fetchQuickLinksSuccess,
      this.fetchQuickLinksError
    );
  }

  @action.bound
  fetchQuickLinksSuccess(data) {
    this.quickLinksPending = false;
    if (data.status != 304) {
      this.quickLinks = data.json.urls;
    }
  }

  @action.bound
  fetchQuickLinksError(error) {
    this.quickLinksPending = false;
  }

  @action.bound
  replace(quickLinks, onSuccess, onError) {
    const filteredLinks = {
      urls: quickLinks.filter(url => {
        return !(!url.name && !url.url);
      })
    };
    const call = WaccDbApi.put(
      '/quick_urls',
      filteredLinks,
      data => {
        this.refreshCollection();
        onSuccess(data);
      },
      error => {
        onError(error);
      }
    );
  }
}
