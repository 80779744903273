import React from 'react';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Input } from 'semantic-ui-react';

import 'react-datepicker/dist/react-datepicker.css';

export class CustomDatePickerInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.value
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps) {
      if (nextProps.value != this.state.value) {
        this.setState({ value: nextProps.value });
      }
    }
  }

  onChange = (event, data) => {
    this.setState({ value: data.value });
    if (data.value === '') {
      this.props.onChange(event, { ...data, value: null });
    } else if (moment(data.value, 'DD/MM/YYYY', true).isValid()) {
      /* Only pass the date on if it is valid */
      this.props.onChange(event, data);
    }
  };

  onKeyEvent = (event, data) => {
    if (event.keyCode === 13) {
      event.stopPropagation();
      event.preventDefault();
    }
  };

  render() {
    return (
      <Input
        onClick={this.props.onClick}
        onChange={this.onChange}
        value={this.state.value}
        onKeyDown={this.onKeyEvent}
        onKeyUp={this.onKeyEvent}
        onKeyPress={this.onKeyEvent}
        placeholder={this.props.placeholder || this.props.placeholderText}
      />
    );
  }
}

CustomDatePickerInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  onChange: PropTypes.func
};

export const ConsistentDatePicker = ({ onChange, value, ...rest }) => (
  <DatePicker
    customInput={<CustomDatePickerInput />}
    onChange={date => onChange(date ? date.format('YYYY-MM-DD') : null)}
    dateForm="DD/MM/YYYY"
    isClearable={true}
    selected={value ? moment(value) : null}
    peekNextMonth
    showMonthDropdown
    showYearDropdown
    dropdownMode="select"
    locale="en-gb"
    value={value ? moment(value).format('DD/MM/YYYY') : null}
    {...rest}
  />
);

ConsistentDatePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any
};
