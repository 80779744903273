import { observable, action, computed } from 'mobx';
import StorageManager from '../../utils/StorageManager';
import WaccDbApi from '../../api/WaccDbApi';
import jwt from 'jsonwebtoken';
import moment from 'moment';

export class SessionStore {
  @observable access = '';
  @observable loggedIn = false;
  @observable loginError = null;
  @observable validatingInitialAuth = true;
  refresh = '';

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.sessionStorage = new StorageManager(
      'session',
      {
        access: this.access,
        refresh: this.refresh,
        loggedIn: this.loggedIn
      },
      true
    );

    /* istanbul ignore else */
    if (this.sessionStorage.promise) {
      this.sessionStorage.promise.then(
        action(() => {
          const storedSession = this.sessionStorage.get();
          Object.keys(storedSession).forEach((key, index) => {
            /* istanbul ignore else  */
            if (this.hasOwnProperty(key)) {
              if (
                key === 'username' ||
                key === 'displayName' ||
                key === 'privs' ||
                key === 'favourite_filters'
              ) {
                /* Skip */
              } else {
                this[key] = storedSession[key];
              }
            }
          });

          this.validatedAuth();
        })
      );
    } else {
      const storedSession = this.sessionStorage.get();
      Object.keys(storedSession).forEach((key, index) => {
        /* istanbul ignore else  */
        if (this.hasOwnProperty(key)) {
          this[key] = storedSession[key];
        }
      });

      this.validatedAuth();
    }
  }

  @action.bound
  validatedAuth() {
    this.validatingInitialAuth = false;

    this.updateRefreshTimer();
  }

  @action.bound
  login(username, password) {
    const call = WaccDbApi.post(
      '/login',
      {
        username,
        password
      },
      data => {
        this.authSuccess(data);
      },
      error => {
        this.clearAuth('Login request failed with status ' + error.status);
      }
    );
  }

  @action.bound
  logout() {
    if (this.access) {
      WaccDbApi.post(
        '/logout',
        {
          token: this.access
        },
        data => {
          this.clearAuth();
        },
        error => {
          this.clearAuth();
        }
      );
    }
  }

  @action.bound
  clearAuth(error = null) {
    this.access = '';
    this.refresh = '';
    this.loggedIn = false;
    this.loginError = error;
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }
    this.save();
  }

  @action.bound
  authSuccess(sessionResp) {
    this.access = sessionResp.json.access_token;
    this.refresh = sessionResp.json.refresh_token;
    const decoded = jwt.decode(this.access);
    this.loggedIn = true;
    this.loginError = null;
    this.updateRefreshTimer();

    this.save();
  }

  checkSession() {
    if (this.access) {
      const decoded = jwt.decode(this.access);
      if (decoded) {
        if (moment.unix(decoded.exp).isBefore(moment())) {
          this.refreshSession();
        }
      } else {
        this.clearAuth();
      }
    }
  }

  @action.bound
  refreshSession() {
    if (this.refresh) {
      /* Refresh the token if we can */
      WaccDbApi.post(
        '/refresh',
        {
          refresh_token: this.refresh
        },
        data => {
          this.authSuccess(data);
        },
        error => {
          this.clearAuth();
        }
      );
    } else {
      this.clearAuth();
    }
  }

  save = () => {
    this.sessionStorage.put({
      access: this.access,
      refresh: this.refresh,
      username: this.username,
      displayName: this.displayName,
      loggedIn: this.loggedIn,
      privs: this.privs
    });
  };

  isFavourite = filter => {
    /* istanbul ignore if */
    if (!this.favourite_filters) {
      return computed(() => false).get();
    } else {
      return computed(() => this.favourite_filters.includes(filter)).get();
    }
  };

  @action.bound
  addFavouriteFilter = filter => {
    if (this.access) {
      var favourite_filters = [];
      /* istanbul ignore else */
      if (this.favourite_filters) {
        favourite_filters = [...this.favourite_filters];
      }

      favourite_filters.push(filter);
      WaccDbApi.patch(
        '/users/' + this.username,
        { favourite_filters },
        data => {
          this.refreshSession();
        },
        error => {}
      );
    }
  };

  @action.bound
  removeFavouriteFilter = filter => {
    if (this.access) {
      var favourite_filters = [];
      /* istanbul ignore else */
      if (this.favourite_filters) {
        favourite_filters = this.favourite_filters.filter(id => filter !== id);
      }

      WaccDbApi.patch(
        '/users/' + this.username,
        { favourite_filters },
        data => {
          this.refreshSession();
        },
        error => {}
      );
    }
  };

  updateRefreshTimer() {
    if (this.refreshTimer) {
      clearTimeout(this.refreshTimer);
    }

    if (this.access) {
      const decoded = jwt.decode(this.access);
      if (decoded) {
        var msTimeout = moment
          .duration(
            moment
              .unix(decoded.exp)
              .subtract(2, 'minutes')
              .diff(moment())
          )
          .asMilliseconds();
        /* istanbul ignore next */
        if (msTimeout < 0) {
          msTimeout = 0;
        }
        this.refreshTimer = setTimeout(() => {
          this.refreshSession();
          this.refreshTimer = null;
        }, msTimeout);
      }
    }
  }

  @computed
  get username() {
    if (this.access) {
      const decoded = jwt.decode(this.access);
      if (decoded) {
        return decoded.user_claims.name;
      }
    }

    return '';
  }

  @computed
  get displayName() {
    if (this.access) {
      const decoded = jwt.decode(this.access);
      if (decoded) {
        return decoded.user_claims.display_name;
      }
    }

    return '';
  }

  @computed
  get privs() {
    if (this.access) {
      const decoded = jwt.decode(this.access);
      if (decoded) {
        return decoded.user_claims.privs;
      }
    }

    return [];
  }

  @computed
  get favourite_filters() {
    if (this.access) {
      const decoded = jwt.decode(this.access);
      if (decoded) {
        return decoded.user_claims.favourite_filters;
      }
    }

    return [];
  }
}
