import { action, computed } from 'mobx';
import { Record, RecordStore } from '../records/RecordStore';
import { CLIENT_DESCRIPTOR } from '../../types/descriptors';
import moment from 'moment';

export class Client extends Record {
  constructor(id, rootStore, collectionName) {
    super(id, rootStore);
    this.collectionName = collectionName;
    this.get();
  }
}

export class ClientStore extends RecordStore {
  constructor(rootStore) {
    super(rootStore);
    this.collectionName = 'clients';
  }

  @action
  get(id) {
    this.record = new Client(id, this.rootStore, this.collectionName);
  }

  @computed
  get trialExpired() {
    const timeNow = moment();
    const filtered = this.records.filter(item => {
      if (!item.active) {
        return false;
      }
      if (item.trial_end && moment(item.trial_end).isSameOrBefore(timeNow)) {
        return true;
      }
      return false;
    });

    return filtered.map(item => {
      return {
        descriptor: CLIENT_DESCRIPTOR,
        id: item._id.$oid,
        name: item.name,
        status: item.trial_end
      };
    });
  }

  @computed
  get needVatNo() {
    const filtered = this.records.filter(item => {
      if (!item.active) {
        return false;
      }
      return needData(item.vatno_status);
    });

    return filtered.map(item => {
      return {
        descriptor: CLIENT_DESCRIPTOR,
        id: item._id.$oid,
        name: item.name,
        status: item.vatno_status
      };
    });
  }
}
