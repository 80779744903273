import { observable, action } from 'mobx';
import WaccDbApi from '../../api/WaccDbApi';

export class NoteStore {
  @observable notes = [];
  @observable notesPending = false;

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.collectionLastModified = null;
  }

  getNotes = () => {
    this.refreshCollection();
    return this.notes;
  };

  @action
  refreshCollection() {
    this.notesPending = true;
    const call = WaccDbApi.get(
      '/notes?$expand=1',
      this.fetchNotesSuccess,
      this.fetchNotesError,
      [
        {
          header: 'If-Modified-Since',
          value: this.collectionLastModified
        }
      ]
    );
  }

  @action.bound
  fetchNotesSuccess(data) {
    this.notesPending = false;
    if (data.status != 304) {
      this.notes = data.json.Members;
      this.collectionLastModified = data.headers.get('Last-Modified');
    }
  }

  @action.bound
  fetchNotesError(error) {
    this.notesPending = false;
  }

  deleteNote = (id, onSuccess, onFailure) => {
    const call = WaccDbApi.delete(
      '/notes/' + id,
      data => {
        this.refreshCollection();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  createNote = (note, onSuccess, onFailure) => {
    const call = WaccDbApi.post(
      '/notes',
      note,
      data => {
        this.refreshCollection();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  updateNote = (note, onSuccess, onFailure) => {
    const call = WaccDbApi.patch(
      '/notes/' + note._id.$oid,
      note,
      data => {
        this.refreshCollection();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };
}
