import React from 'react';
import { observable } from 'mobx';
import {
  dateAccessor,
  addressAccessor,
  lastModifiedAccessor,
  dateSort,
  lastModifiedSort,
  boolAccessor,
  TAX_YEARS,
  getTaxStatusColour,
  getTaxStatus
} from '../../utils/utils';
import { TableUiStore } from './TableUiStore';

export class ClientUiStore extends TableUiStore {
  @observable
  sortBy = [
    {
      id: 'created',
      desc: true
    }
  ];

  @observable
  columns = [
    {
      id: 'created',
      Header: 'Created',
      accessor: dateAccessor('created'),
      sortMethod: dateSort,
      show: true
    },
    {
      Header: 'Name',
      accessor: 'name',
      show: true
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: addressAccessor,
      show: true
    },
    {
      id: 'active',
      Header: 'Active',
      accessor: boolAccessor('active'),
      show: false
    },
    {
      Header: 'Email',
      accessor: 'email',
      show: false
    },
    {
      id: 'dob',
      Header: 'D.O.B.',
      accessor: dateAccessor('dob'),
      sortMethod: dateSort,
      show: true
    },
    {
      Header: 'Tel',
      accessor: 'tel',
      show: false
    },
    {
      Header: 'Mob',
      accessor: 'mob',
      show: false
    },
    {
      Header: 'N.I. Number',
      accessor: 'ni_number',
      show: false
    },
    {
      Header: 'UTR',
      accessor: 'utr',
      show: false
    },
    {
      Header: 'Source',
      accessor: 'source',
      show: false
    },
    {
      Header: 'Nature',
      accessor: 'nature',
      show: false
    },
    {
      Header: 'Style',
      accessor: 'style',
      show: false
    },
    {
      Header: 'AML Status',
      accessor: 'aml_status',
      show: false
    },
    {
      Header: 'AML Risk',
      accessor: 'aml_risk',
      show: false
    },
    {
      Header: 'UTR Status',
      accessor: 'utr_status',
      show: false
    },
    {
      Header: 'VAT Number Status',
      accessor: 'vatno_status',
      show: false
    },
    {
      Header: 'Agent Authority Status',
      accessor: 'agent_authority_status',
      show: false
    },
    {
      id: 'last_modified',
      Header: 'Last Modified',
      accessor: lastModifiedAccessor,
      sortMethod: lastModifiedSort,
      show: false
    },
    {
      id: 'trial_end',
      Header: 'Trial End',
      accessor: dateAccessor('trial_end'),
      sortMethod: dateSort,
      show: false
    },
    ...TAX_YEARS.map(year => {
      const intYear = parseInt(year);
      const taxYear =
        (intYear - 1).toString().substring(2) +
        '/' +
        intYear.toString().substr(2);
      return {
        id: 'submission_history.year_end_' + year,
        accessor: getTaxStatus(intYear),
        Header: taxYear,
        show: false,
        Cell: row => {
          return (
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: getTaxStatusColour(row.value)
              }}
            >
              {row.value}
            </div>
          );
        }
      };
    })
  ];

  constructor(rootStore) {
    super(rootStore, 'client');
  }
}
