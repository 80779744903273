import { observable, action, computed } from 'mobx';
import StorageManager from '../../utils/StorageManager';
import { arrayMove } from '../../utils/utils';

export class TableUiStore {
  @observable
  pageSize = 50;
  @observable
  resizedColumns = [];
  @observable
  columnOrder = [];
  @observable
  highlight = false;

  constructor(rootStore, name) {
    this.rootStore = rootStore;
    this.storeName = name + 'Ui';
    this.sortStorage = new StorageManager(
      this.storeName + '.sort',
      this.sortBy
    );
    this.columnStorage = new StorageManager(
      this.storeName + '.columns',
      this.columns
    );
    this.columnSizeStorage = new StorageManager(
      this.storeName + '.colsizes',
      this.resizedColumns
    );
    this.pageStorage = new StorageManager(this.storeName + '.page', {
      pageSize: this.pageSize
    });
    this.highlightStorage = new StorageManager(this.storeName + '.highlight', {
      highlight: this.highlight
    });
    this.columnOrderStorage = new StorageManager(
      this.storeName + '.colorder',
      this.columnOrder
    );

    this.sortBy = this.sortStorage.get();
    const storedColumns = this.columnStorage.get();
    this.columns = this.columns.map(col => {
      var name = TableUiStore.fieldName(col);
      /* istanbul ignore else */
      if (storedColumns.hasOwnProperty(name)) {
        col.show = storedColumns[name].show;
      }
      return {
        ...col
      };
    });
    this.resizedColumns = this.columnSizeStorage.get().filter(size => {
      /* Make sure that only 'known' columns are included in the resize */
      for (var i = 0; i < this.columns.length; ++i) {
        if (TableUiStore.fieldName(this.columns[i]) === size.id) {
          return true;
        }
      }
      return false;
    });

    this.pageSize = this.pageStorage.get().pageSize;
    this.highlight = this.highlightStorage.get().highlight;
    this.columnOrder = this.columnOrderStorage.get();

    this.columns.forEach(col => {
      var name = TableUiStore.fieldName(col);
      if (col.show) {
        if (!this.columnOrder.includes(name)) {
          this.columnOrder.push(name);
        }
      } else {
        if (this.columnOrder.includes(name)) {
          this.columnOrder = this.columnOrder.filter(e => e !== name);
        }
      }
    });
  }

  @action.bound
  sortByFields(fields) {
    this.sortBy = fields;
    this.sortStorage.put(this.sortBy);
  }

  @action.bound
  toggleVisibility(field) {
    this.columns = this.columns.map(col => {
      const name = TableUiStore.fieldName(col);
      if (name === field) {
        col.show = !col.show;
        if (col.show === true) {
          this.columnOrder.push(name);
        } else {
          this.columnOrder = this.columnOrder.filter(e => e !== name);
        }
      }
      return { ...col };
    });
    this.storeColumns();
    this.storeOrder();
  }

  @action.bound
  updateColSizes(sizes) {
    this.resizedColumns = sizes;
    this.columnSizeStorage.put(this.resizedColumns);
  }

  @action.bound
  setPageSize(pageSize) {
    this.pageSize = pageSize;
    this.pageStorage.put({ pageSize: this.pageSize });
  }

  @action.bound
  toggleHighlight() {
    this.highlight = !this.highlight;
    this.highlightStorage.put({ highlight: this.highlight });
  }

  @action.bound
  updateColumnOrder(from, to) {
    arrayMove(this.columnOrder, from, to);
    this.storeOrder();
  }

  @computed
  get orderedColumns() {
    const columns = [];

    this.columnOrder.forEach(name => {
      for (var i = 0; i < this.columns.length; ++i) {
        if (name === TableUiStore.fieldName(this.columns[i])) {
          columns.push(this.columns[i]);
          break;
        }
      }
    });

    return columns;
  }

  static fieldName = col => {
    if (typeof col.accessor === 'function') {
      return col.id;
    }

    return col.accessor;
  };

  storeColumns = () => {
    this.columnStorage.put(
      this.columns.reduce((map, col) => {
        map[TableUiStore.fieldName(col)] = {
          show: col.show
        };
        return map;
      }, {})
    );
  };

  storeOrder = () => {
    this.columnOrderStorage.put(this.columnOrder);
  };
}
