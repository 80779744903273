import React from 'react';
import { Form, Grid, Segment } from 'semantic-ui-react';
import {
  TITLE_OPTIONS,
  FIELD_STATUS_OPTIONS,
  STYLE_OPTIONS,
  AML_STATUS_OPTIONS,
  AML_RISK_OPTIONS
} from '../../types/enum-options';
import { FormWrapper } from './FormApi';
import {
  validatePhoneNumber,
  validateNINumber,
  validatePersonalUTR,
  validateRequired,
  validateEmail,
  validateVATNo
} from '../../types/validation';
import { SubmissionHistoryField } from './components/SubmissionHistoryField';
import { SelectField } from './components/SelectField';
import { InputField } from './components/InputField';
import { DatePickerField } from './components/DatePickerField';
import { CheckboxField } from './components/CheckboxField';
import { EmailField } from './components/EmailField';
import { AddressField } from './components/AddressField';
import { VATStatusField } from './components/VATStatusField';

export const ClientForm = ({ initialValues, formSubmitHandler, onCancel }) => (
  <FormWrapper
    initialValues={initialValues || { active: true }}
    formSubmitHandler={formSubmitHandler}
    onCancel={onCancel}
  >
    <Grid stackable verticalAlign="middle">
      <Grid.Row columns={16}>
        <Grid.Column mobile={8} computer={8} largeScreen={3} widescreen={3}>
          <SelectField
            name="title"
            fluid
            label="Title"
            options={TITLE_OPTIONS}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={16} largeScreen={7} widescreen={7}>
          <InputField label="Name" name="name" validate={validateRequired} />
        </Grid.Column>
        <Grid.Column
          textAlign="center"
          mobile={8}
          computer={8}
          largeScreen={3}
          widescreen={3}
        >
          <DatePickerField label="Trial End" name="trial_end" />
        </Grid.Column>
        <Grid.Column
          textAlign="center"
          mobile={8}
          computer={8}
          largeScreen={3}
          widescreen={3}
        >
          <CheckboxField label="Active" name="active" toggle fitted />
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Grid stackable>
      <Grid.Row columns={16}>
        <Grid.Column mobile={16} computer={16} largeScreen={8} widescreen={8}>
          <AddressField label="Address" name="address" />
        </Grid.Column>
        <Grid.Column mobile={16} computer={16} largeScreen={8} widescreen={8}>
          <Segment>
            <EmailField
              label="Email"
              name="email"
              fluid
              validate={validateEmail}
              email={initialValues ? initialValues.email || '' : ''}
            />
            <DatePickerField label="Date of Birth" name="dob" />
            <Form.Group widths="equal">
              <InputField
                label="Telephone"
                name="tel"
                fluid
                validate={validatePhoneNumber}
              />
              <InputField
                label="Mobile"
                name="mob"
                fluid
                validate={validatePhoneNumber}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <InputField
                label="N.I."
                name="ni_number"
                fluid
                validate={validateNINumber}
              />
              <InputField
                label="UTR"
                name="utr"
                fluid
                validate={validatePersonalUTR}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <InputField
                label="VAT Number"
                name="vat_no"
                fluid
                validate={validateVATNo}
              />
              <InputField label="VAT Schemes" name="vat_schemes" fluid />
            </Form.Group>
            <Form.Group widths="equal">
              <SelectField
                label="UTR Status"
                name="utr_status"
                fluid
                options={FIELD_STATUS_OPTIONS}
              />
              <SelectField
                label="Agent Authority Status"
                name="agent_authority_status"
                fluid
                options={FIELD_STATUS_OPTIONS}
              />
              <SelectField
                label="VAT Number Status"
                name="vatno_status"
                fluid
                options={FIELD_STATUS_OPTIONS}
              />
            </Form.Group>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Form.Group widths="equal">
      <InputField label="Source" name="source" fluid />
      <InputField label="Nature Of Work" name="nature" fluid />
    </Form.Group>
    <Segment>
      <Form.Group widths="equal">
        <SelectField label="Style" name="style" fluid options={STYLE_OPTIONS} />
        <SelectField
          label="AML Status"
          name="aml_status"
          fluid
          options={AML_STATUS_OPTIONS}
        />
        <SelectField
          label="AML Risk"
          name="aml_risk"
          fluid
          options={AML_RISK_OPTIONS}
        />
      </Form.Group>
      <SubmissionHistoryField
        label="Submission History"
        name="submission_history"
      />
      <VATStatusField label="VAT Status" name="vat" />
    </Segment>
  </FormWrapper>
);
