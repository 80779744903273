import React from 'react';
import { TextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FieldWrapper } from '../FormApi';

export const TextAreaAdapter = ({
  input: { name, onBlur, onChange, onFocus, value },
  label,
  placeholder,
  ...rest
}) => (
  <TextArea
    id={name}
    value={value || ''}
    onBlur={(e, d) => onBlur(e)}
    onChange={(e, d) => onChange(e)}
    onFocus={(e, d) => onFocus(e)}
    placeholder={placeholder || label}
    {...rest}
  />
);

TextAreaAdapter.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func,
    value: PropTypes.any.isRequired
  }),
  label: PropTypes.string,
  placeholder: PropTypes.string
};

export const TextAreaField = props => (
  <FieldWrapper Component={TextAreaAdapter} {...props} />
);

TextAreaField.propTypes = TextAreaAdapter.propTypes;
