import React from 'react';
import { Modal, Button, Icon } from 'semantic-ui-react';

export default class NavigationConfirmationModal extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onConfirm, onCancel } = this.props;

    return (
      <Modal open={true}>
        <Modal.Header>Are you sure?</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            <p>Some data in this record has been modified.</p>
            <p>Are you sure you wish to navigate away from the page?</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <Button color="red" onClick={(event, data) => onConfirm()}>
            <Icon name="trash" /> Discard
          </Button>
          <Button color="green" onClick={(event, data) => onCancel()}>
            <Icon name="cancel" /> Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}
