import moment from 'moment';
import _ from 'lodash';
import { SIC_CODES } from './sic_codes';

export const splitCollectionAndId = (fullId) => {
  var collectionIdRegex = /(.*)\/(.*)/g;
  var match = collectionIdRegex.exec(fullId);
  if (match && match.length >= 2) {
    return {
      collection: match[1],
      id: match[2]
    };
  }

  return null;
};

export const needData = (status) => {
  switch (status) {
    case 'Work Required':
    case 'Awaiting Data':
    case 'Client Information Required':
      return true;
  }

  return false;
};

export const needAmlStatus = (status) => {
  return status === 'Pending';
};

export const directorsAccessor = (data) => {
  var directors = [];
  if (data.directors) {
    directors = data.directors.map((current) => current.name);
  }
  return directors.toString();
};

export const directorsEmailsAccessor = (data) => {
  var emails = [];
  if (data.directors) {
    emails = data.directors.map((current) => current.email);
  }
  return emails.toString();
};

export const lastModifiedAccessor = (data) => {
  return data.last_modified
    ? moment(data.last_modified.time).format('HH:mm:ss DD/MM/YYYY')
    : moment(data.created).format('DD/MM/YYYY');
};

export const lastModifiedSort = (a, b) => {
  if (a === '' && b === '') {
    /* Both are empty and therefore they are equal */
    return 0;
  }

  if (a === '') {
    /* a is empty, and b is not therefore a must be less than b */
    return -1;
  }

  if (b === '') {
    /* b is empty, and a is not therefore a must be greater than b */
    return 1;
  }

  const atime = moment.utc(a, 'HH:mm:ss DD/MM/YYYY', true);
  const adate = moment.utc(a, 'DD/MM/YYYY', true);
  const btime = moment.utc(b, 'HH:mm:ss DD/MM/YYYY', true);
  const bdate = moment.utc(b, 'DD/MM/YYYY', true);
  var aval = null;
  var bval = null;

  if (atime.isValid()) {
    aval = atime;
  } else if (adate.isValid()) {
    aval = adate;
  }

  if (btime.isValid()) {
    bval = btime;
  } else if (bdate.isValid()) {
    bval = bdate;
  }

  if (aval && bval) {
    return aval.diff(bval);
  } else {
    if (aval) {
      return 1;
    }

    if (bval) {
      return -1;
    }

    return 0;
  }
};

export const dateAccessor = (field) => {
  return (data) => {
    const date = data[field];
    return date ? moment(date).format('DD/MM/YYYY') : '';
  };
};

export const boolAccessor = (field) => {
  return (data) => {
    const value = data[field];
    return value ? 'True' : 'False';
  };
};

export const dateSort = (a, b) => {
  if ((a === '' || !a) && (b === '' || !b)) {
    /* Both are empty and therefore they are equal */
    return 0;
  }

  if (a === '' || !a) {
    /* a is empty, and b is not therefore a must be less than b */
    return -1;
  }

  if (b === '' || !b) {
    /* b is empty, and a is not therefore a must be greater than b */
    return 1;
  }

  return moment.utc(a, 'DD/MM/YYYY').diff(moment.utc(b, 'DD/MM/YYYY'));
};

export const vatStatusSort = (a, b) => {
  if ((a === null && b === null) || (a === undefined && b === undefined)) {
    /* Both are empty and therefore they are equal */
    return 0;
  }

  if (a === undefined || a === null || a.status === '' || a.status === null) {
    /* a is empty, and b is not therefore a must be less than b */
    return -1;
  }

  if (b === undefined || b == null || b.status === '' || b.status === null) {
    /* b is empty, and a is not therefore a must be greater than b */
    return 1;
  }

  if (
    (a.status === '' && b.status === '') ||
    (a.status === null && b.status === null)
  ) {
    /* Neither is null/undefined - check if their statuses match */
    return 0;
  }

  return a.status.localeCompare(b.status);
};

export const addressAccessor = (data) => {
  const address = data.address;
  return address && address.addr_lines
    ? address.addr_lines.join(', ') +
        (address.postcode ? ', ' + address.postcode : '')
    : '';
};

export const privsAccessor = (d) => d.privs.join(', ');

export const arrayMove = (arr, from, to) => {
  if (to >= arr.length) {
    var i = to - arr.length + 1;
    while (i--) {
      arr.push(undefined);
    }
  }

  arr.splice(to, 0, arr.splice(from, 1)[0]);
};

export const TAX_YEARS = [
  moment().subtract(3, 'years').year().toString(),
  moment().subtract(2, 'years').year().toString(),
  moment().subtract(1, 'years').year().toString(),
  moment().year().toString(),
  moment().add(1, 'years').year().toString()
];

export const getTaxStatusColour = (value) => {
  var colour = null;
  switch (value) {
    case 'Pending':
      colour = '#ffb5bd';
      break;

    case 'Submitted':
      colour = '#bcffcf';
      break;

    case 'De-register':
      colour = '#33bbff';
      break;

    case 'Not Required':
      colour = null;
      break;

    case 'Unknown':
    case undefined:
    case null:
      colour = 'rgba(0, 0, 0, 0.1)';
      break;
  }

  return colour;
};

export const getTaxStatus = (year) => {
  return (data) => {
    var status = _.get(data, `submission_history.year_end_${year}`, null);
    if (status) {
      return status;
    }

    if (year.toString() == TAX_YEARS[TAX_YEARS.length - 1]) {
      /* Get the previous year's and base this year's on that if it isn't
       * already in the table */
      status = _.get(data, `submission_history.year_end_${year - 1}`, null);
      if (status) {
        if (status === 'De-register') {
          status = 'Not Required';
        }
        return status;
      }
    }

    return status;
  };
};

export const lookupSicCode = (sic) => {
  if (sic) {
    sic = sic.padStart(5, '0');
    return _.get(SIC_CODES, sic, 'Unknown');
  }

  return null;
};

const getVATPeriod = (duration) => {
  const period = moment().add(duration, 'months');
  return (
    moment.monthsShort('-MMM-', period.month()).toLowerCase() +
    '_' +
    period.year().toString()
  );
};

export const VAT_PERIODS = [
  getVATPeriod(-5),
  getVATPeriod(-4),
  getVATPeriod(-3),
  getVATPeriod(-2),
  getVATPeriod(-1),
  getVATPeriod(0),
  getVATPeriod(1),
  getVATPeriod(2),
  getVATPeriod(3),
  getVATPeriod(4),
  getVATPeriod(5),
  getVATPeriod(6)
];

export const getVATStatusColour = (value) => {
  var colour = null;
  switch (value) {
    case 'Pending':
      colour = '#ffb5bd';
      break;

    case 'Submitted':
      colour = '#bcffcf';
      break;

    case 'Adjustment':
      colour = '#33bbff';
      break;

    case 'Not Required':
      colour = null;
      break;

    case 'Unknown':
    case undefined:
    case null:
      colour = 'rgba(0, 0, 0, 0.1)';
      break;
  }

  return colour;
};

export const getVATStatus = (period) => {
  return (data) => {
    const status = _.get(data, `vat.submissions.${period}`, null);
    if (status) {
      return status;
    }

    const monthly = _.get(data, 'vat.monthly', false);
    const periods = _.get(data, 'vat.period_type');
    if (monthly || periods) {
      if (monthly || periods.toLowerCase().includes(period.substr(0, 3))) {
        return {
          status: 'Pending',
          comment: ''
        };
      }
    }
    return undefined;
  };
};
