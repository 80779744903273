export const CLIENT_COLOUR = {
  name: 'blue',
  rgb: '#2185d0',
  text: 'white'
};

export const LEAD_COLOUR = {
  name: 'red',
  rgb: '#db2828',
  text: 'white'
};

export const PARTNERSHIP_COLOUR = {
  name: 'orange',
  rgb: '#f2711c',
  text: 'black'
};

export const LTDCO_COLOUR = {
  name: 'olive',
  rgb: '#b5cc18',
  text: 'black'
};

export const DEADLINE_PERIOD_END_COLOUR = {
  name: 'black',
  text: 'white'
};

export const DEADLINE_FILING_COLOUR = {
  name: 'violet',
  text: 'black'
};

export const DEADLINE_REF_DATE_COLOUR = {
  name: 'orange',
  text: 'black'
};

export const DEADLINE_TASK_COLOUR = {
  name: 'teal',
  text: 'black'
};

export const UNKNOWN_COLOUR = {
  name: 'grey',
  rgb: '#767676',
  text: 'black'
};

export const USER_COLOUR = {
  name: 'brown',
  text: 'white'
};

export const CONTACT_COLOUR = {
  name: 'black',
  text: 'white'
};

export const getCollectionTheme = collection => {
  switch (collection) {
    case 'clients':
      return CLIENT_COLOUR;

    case 'partnerships':
      return PARTNERSHIP_COLOUR;

    case 'ltd_companies':
      return LTDCO_COLOUR;

    case 'users':
      return USER_COLOUR;

    case 'notes':
      return DEADLINE_TASK_COLOUR;

    case 'leads':
      return LEAD_COLOUR;
  }

  return UNKNOWN_COLOUR;
};
