import React from 'react';
import { Divider, Form, Grid, Segment } from 'semantic-ui-react';
import { InputField } from './components/InputField';
import { CheckboxField } from './components/CheckboxField';
import { DirectorArrayField } from './components/DirectorArrayField';
import { DatePickerField } from './components/DatePickerField';
import { FormWrapper } from './FormApi';
import {
  validateRequired,
  validateCompaniesHouseRegNo,
  validateLtdCoUTR,
  validateCompaniesHouseAccCode,
  validateSICCode
} from '../../types/validation';
import {
  BANKING_PREF_OPTIONS,
  SPREADSHEET_PREF_OPTIONS,
  SIC_OPTIONS
} from '../../types/enum-options';
import { TabbedAddressField } from './components/AddressField';
import { SelectField } from './components/SelectField';

export const LeadForm = ({
  initialValues,
  formSubmitHandler,
  loading,
  onCancel
}) => {
  return (
    <FormWrapper
      initialValues={
        initialValues || {
          active: true,
          new: true,
          address: {
            addr_lines: [
              'Unit 24 Highcroft Industrial Estate',
              'Enterprise Road',
              'Horndean',
              'Waterlooville',
              'United Kingdom'
            ],
            postcode: 'PO8 0BT'
          }
        }
      }
      formSubmitHandler={formSubmitHandler}
      onCancel={onCancel}
    >
      <Grid stackable verticalAlign="middle">
        <Grid.Row columns={16}>
          <Grid.Column
            textAlign="center"
            mobile={8}
            computer={8}
            largeScreen={3}
            widescreen={3}
          >
            <CheckboxField label="Active" name="active" toggle fitted />
          </Grid.Column>
          <Grid.Column
            textAlign="center"
            mobile={8}
            computer={8}
            largeScreen={3}
            widescreen={3}
          >
            <CheckboxField label="New" name="new" toggle fitted />
          </Grid.Column>
          <Grid.Column mobile={16} computer={16} largeScreen={7} widescreen={7}>
            <InputField label="Name" name="name" validate={validateRequired} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Row columns={16}>
          <Grid.Column mobile={16} computer={16} largeScreen={8} widescreen={8}>
            <TabbedAddressField
              names={[
                {
                  title: 'Registered Office Address',
                  name: 'address'
                },
                {
                  title: 'Operating Address',
                  name: 'operating_address'
                }
              ]}
            />
          </Grid.Column>
          <Grid.Column mobile={16} computer={16} largeScreen={8} widescreen={8}>
            <Segment>
              <Form.Group widths="equal">
                <InputField label="Shares" name="shares" fluid type="number" />
              </Form.Group>
              <Form.Group widths="equal">
                <InputField
                  label="Registration Number"
                  name="reg_no"
                  fluid
                  validate={validateCompaniesHouseRegNo}
                />
                <InputField
                  label="UTR"
                  name="utr"
                  fluid
                  validate={validateLtdCoUTR}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <InputField
                  label="Companies House Auth Code"
                  name="companies_house_acc_code"
                  fluid
                  validate={validateCompaniesHouseAccCode}
                />
                <SelectField
                  label="SIC Code"
                  name="sic_code"
                  fluid
                  search
                  options={SIC_OPTIONS}
                  validate={validateSICCode}
                />
              </Form.Group>
              <Form.Group widths="equal">
                <DatePickerField
                  label="Incorporation Date"
                  name="incorporation_date"
                />
              </Form.Group>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid>
        <Grid.Row columns={16}>
          <Grid.Column width={4}>
            <Segment>
              <DatePickerField
                label="First Call Deadline"
                name="first_call_deadline"
              />
              <CheckboxField label="Complete" name="first_call_complete" />
            </Segment>
          </Grid.Column>
          <Grid.Column width={4}>
            <Segment>
              <DatePickerField
                label="Second Call Deadline"
                name="second_call_deadline"
              />
              <Form.Group widths="equal">
                <CheckboxField label="Complete" name="second_call_complete" />
                <CheckboxField label="Sent" name="second_call_data_sent" />
              </Form.Group>
            </Segment>
          </Grid.Column>
          <Grid.Column width={4}>
            <Segment>
              <DatePickerField
                label="Pre-kill Email Deadline"
                name="prekill_deadline"
              />
              <CheckboxField label="Complete" name="prekill_complete" />
            </Segment>
          </Grid.Column>
          <Grid.Column width={4}>
            <Segment>
              <DatePickerField
                label="Kill Email Deadline"
                name="kill_deadline"
              />
              <CheckboxField label="Complete" name="kill_complete" />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Segment>
        <Form.Group widths="equal">
          <SelectField
            label="Banking Preference"
            name="bank_pref"
            fluid
            options={BANKING_PREF_OPTIONS}
          />
          <SelectField
            label="Spreadsheet Preference"
            name="spreadsheet_pref"
            fluid
            options={SPREADSHEET_PREF_OPTIONS}
          />
          <CheckboxField label="Xero" name="xero" />
        </Form.Group>
      </Segment>
      <Grid stackable>
        <Grid.Row columns={16}>
          <Grid.Column width={16}>
            {!loading && (
              <Segment secondary>
                <DirectorArrayField fluid label="Directors" name="directors" />
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
    </FormWrapper>
  );
};
