import { observable, computed, action, toJS } from 'mobx';
import WaccDbApi from '../../api/WaccDbApi';

export class User {
  etag = null;
  @observable data = null;
  @observable dataPending = false;

  constructor(id) {
    this.id = id;
    this.get();
  }

  @action.bound
  clear() {
    this.etag = null;
    this.data = null;
    this.dataPending = false;
  }

  @action.bound
  get() {
    this.dataPending = true;
    return WaccDbApi.get(
      '/users/' + this.id,
      this.fetchUserSuccess,
      this.fetchUserError
    );
  }

  @action.bound
  update(user, onSuccess, onError) {
    if (user.password == '') {
      user.password = null;
    }
    return WaccDbApi.patchWithEtag(
      '/users/' + this.id,
      this.etag,
      user,
      data => {
        this.get();
        onSuccess(data);
      },
      error => {
        onError(error);
      }
    );
  }

  @action.bound
  fetchUserSuccess(data) {
    this.dataPending = false;
    this.data = data.json;
    this.etag = data.headers.get('ETag');
  }

  @action.bound
  fetchUserError(error) {
    this.dataPending = false;
  }

  rawValues = () => {
    return {
      ...this.data,
      privs: [...this.data.privs],
      password: null
    };
  };
}

export class UserStore {
  @observable records = [];
  @observable usersById = new Map();
  @observable recordsPending = false;
  @observable user = null;
  @observable userFilter = '';

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.collectionLastModified = null;
  }

  getUsers = () => {
    this.refreshCollection();
    return this.records;
  };

  @action
  get(id) {
    this.user = new User(id);
  }

  @action
  clearUser() {
    this.user = null;
  }

  @action
  refreshCollection() {
    this.recordsPending = true;
    return WaccDbApi.get(
      '/users?$expand=1',
      this.fetchUsersSuccess,
      this.fetchUsersError,
      [
        {
          header: 'If-Modified-Since',
          value: this.collectionLastModified
        }
      ]
    );
  }

  @action.bound
  fetchUsersSuccess(data) {
    this.recordsPending = false;
    if (data.status != 304) {
      this.records = data.json.Members;
      this.usersById.replace(this.records.map(user => [user._id, user]));
      this.collectionLastModified = data.headers.get('Last-Modified');
    }
  }

  @action.bound
  fetchUsersError(error) {
    this.recordsPending = false;
  }

  deleteUser = (id, onSuccess, onFailure) => {
    return WaccDbApi.delete(
      '/users/' + id,
      data => {
        this.refreshCollection();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  deleteOne = (id, onSuccess, onFailure) => {
    this.deleteUser(id, onSuccess, onFailure);
  };

  createUser = (user, onSuccess, onFailure) => {
    return WaccDbApi.post(
      '/users',
      user,
      data => {
        this.refreshCollection();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  createOne = (user, onSuccess, onFailure) => {
    return this.createUser(user, onSuccess, onFailure);
  };

  getDisplayName(id) {
    const user = this.usersById.get(id);
    if (user) {
      if (user.display_name) {
        return computed(() => user.display_name).get();
      }

      return computed(() => user.name).get();
    } else {
      return computed(() => id).get();
    }
  }

  @computed
  get userOptions() {
    var userOptions = this.records.map((e, index) => ({
      key: index,
      text: e.display_name ? e.display_name : e.name,
      value: e.name
    }));

    userOptions.unshift({
      key: -1,
      text: '',
      value: null
    });

    return userOptions;
  }

  @action.bound
  updateUserFilter = filter => {
    this.userFilter = filter;
  };

  @computed
  get filteredUsers() {
    const filter = this.userFilter.toLowerCase();
    return this.userOptions.filter(item => {
      return (
        item.text.toLowerCase().includes(filter) ||
        (item.value && item.value.includes(filter))
      );
    });
  }
}
