import {
  CLIENT_COLOUR,
  LTDCO_COLOUR,
  PARTNERSHIP_COLOUR,
  USER_COLOUR,
  LEAD_COLOUR,
  CONTACT_COLOUR,
  DEADLINE_FILING_COLOUR
} from '../utils/theme';

export const CLIENT_DESCRIPTOR = {
  collection: 'clients',
  printableName: 'Clients',
  singularPrintableName: 'Client',
  colour: CLIENT_COLOUR
};

export const LEAD_DESCRIPTOR = {
  collection: 'leads',
  printableName: 'Leads',
  singularPrintableName: 'Lead',
  colour: LEAD_COLOUR
};

export const LTDCO_DESCRIPTOR = {
  collection: 'ltd_companies',
  printableName: 'Limited Companies',
  singularPrintableName: 'Limited Company',
  colour: LTDCO_COLOUR
};

export const VAT_DESCRIPTOR = {
  printableName: 'VAT Schedule',
  singularPrintableName: 'VAT Schedule',
  colour: DEADLINE_FILING_COLOUR
};

export const PARTNERSHIP_DESCRIPTOR = {
  collection: 'partnerships',
  printableName: 'Partnerships',
  singularPrintableName: 'Partnership',
  colour: PARTNERSHIP_COLOUR
};

export const USER_DESCRIPTOR = {
  collection: 'users',
  printableName: 'Users',
  singularPrintableName: 'User',
  colour: USER_COLOUR
};

export const CONTACT_DESCRIPTOR = {
  collection: 'contacts',
  printableName: 'Contacts',
  singularPrintableName: 'Contact',
  colour: CONTACT_COLOUR
};
