import React from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import importedComponent from 'react-imported-component';
import { rootStore } from '../mobx/RootStore';

import Home from './pages/Home';
import Loading from './Loading';
import Layout from './Layout';
import {
  CLIENT_DESCRIPTOR,
  LTDCO_DESCRIPTOR,
  PARTNERSHIP_DESCRIPTOR,
  USER_DESCRIPTOR,
  LEAD_DESCRIPTOR,
  CONTACT_DESCRIPTOR,
  VAT_DESCRIPTOR
} from '../types/descriptors';
import UserForm from './form/UserForm';
import { ClientForm } from './form/ClientForm';
import { ContactForm } from './form/ContactForm';
import { LtdcoForm } from './form/LtdcoForm';
import { PartnershipForm } from './form/PartnershipForm';
import { LeadForm } from './form/LeadForm';
import moment from 'moment';

const AsyncRecordPage = importedComponent(
  () => import(/* webpackChunkName: 'Record' */ './pages/Record'),
  {
    LoadingComponent: Loading
  }
);

const AsyncRecordTablePage = importedComponent(
  () => import(/* webpackChunkName: 'RecordTable' */ './pages/RecordTable'),
  {
    LoadingComponent: Loading
  }
);

const AsyncIndividualUserPage = importedComponent(
  () =>
    import(/* webpackChunkName: 'IndividualUser' */ './pages/IndividualUser'),
  {
    LoadingComponent: Loading
  }
);

const AsyncSelfPage = importedComponent(
  () => import(/* webpackChunkName: 'Self' */ './pages/Self'),
  {
    LoadingComponent: Loading
  }
);

const AsyncDeadlinesPage = importedComponent(
  () => import(/* webpackChunkName: 'Deadlines' */ './pages/Deadlines'),
  {
    LoadingComponent: Loading
  }
);

const AsyncReportsPage = importedComponent(
  () => import(/* webpackChunkName: 'Reports' */ './pages/Reports'),
  {
    LoadingComponent: Loading
  }
);

const AsyncAdminPage = importedComponent(
  () => import(/* webpackChunkName: 'Admin' */ './pages/Admin'),
  {
    LoadingComponent: Loading
  }
);

const AsyncNoMatch = importedComponent(
  () => import(/* webpackChunkName: 'NoMatch' */ './pages/NoMatch'),
  {
    LoadingComponent: Loading
  }
);

const App = () => {
  return (
    <Router>
      <div>
        <Layout>
          <Switch>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path="/clients"
              render={props => (
                <AsyncRecordTablePage
                  {...props}
                  RecordForm={ClientForm}
                  dataStore={rootStore.clientStore}
                  uiStore={rootStore.clientUiStore}
                  descriptor={CLIENT_DESCRIPTOR}
                  highlightFields={{
                    name: 'Trial',
                    shouldHighlight: item => {
                      return item.trial_end;
                    },
                    class: '-trialclient'
                  }}
                />
              )}
            />
            <Route
              exact
              path="/clients/:id"
              render={props => (
                <AsyncRecordPage
                  {...props}
                  RecordForm={ClientForm}
                  store={rootStore.clientStore}
                  descriptor={CLIENT_DESCRIPTOR}
                />
              )}
            />
            <Route
              exact
              path="/leads"
              render={props => (
                <AsyncRecordTablePage
                  {...props}
                  RecordForm={LeadForm}
                  dataStore={rootStore.leadStore}
                  uiStore={rootStore.leadUiStore}
                  descriptor={LEAD_DESCRIPTOR}
                  highlightFields={{
                    name: 'New',
                    shouldHighlight: item => {
                      return item.new;
                    },
                    class: '-newlead'
                  }}
                />
              )}
            />
            <Route
              exact
              path="/leads/:id"
              render={props => (
                <AsyncRecordPage
                  {...props}
                  RecordForm={LeadForm}
                  store={rootStore.leadStore}
                  descriptor={LEAD_DESCRIPTOR}
                />
              )}
            />
            <Route exact path="/deadlines" component={AsyncDeadlinesPage} />
            <Route path="/reports" component={AsyncReportsPage} />
            <Route
              exact
              path="/ltd_companies"
              render={props => (
                <AsyncRecordTablePage
                  {...props}
                  RecordForm={LtdcoForm}
                  dataStore={rootStore.ltdcoStore}
                  uiStore={rootStore.ltdcoUiStore}
                  descriptor={LTDCO_DESCRIPTOR}
                  highlightFields={{
                    name: 'Trial',
                    shouldHighlight: item => {
                      return item.trial_end;
                    },
                    class: '-trialcompany'
                  }}
                />
              )}
            />
            <Route
              exact
              path="/ltd_companies/:id"
              render={props => (
                <AsyncRecordPage
                  {...props}
                  RecordForm={LtdcoForm}
                  store={rootStore.ltdcoStore}
                  descriptor={LTDCO_DESCRIPTOR}
                />
              )}
            />
            <Route exact path="/me" component={AsyncSelfPage} />
            <Route
              exact
              path="/partnerships"
              render={props => (
                <AsyncRecordTablePage
                  {...props}
                  RecordForm={PartnershipForm}
                  dataStore={rootStore.partnershipStore}
                  uiStore={rootStore.partnershipUiStore}
                  descriptor={PARTNERSHIP_DESCRIPTOR}
                />
              )}
            />
            <Route
              exact
              path="/partnerships/:id"
              render={props => (
                <AsyncRecordPage
                  {...props}
                  RecordForm={PartnershipForm}
                  store={rootStore.partnershipStore}
                  descriptor={PARTNERSHIP_DESCRIPTOR}
                />
              )}
            />
            <Route
              exact
              path="/users"
              render={props => (
                <AsyncRecordTablePage
                  {...props}
                  RecordForm={UserForm}
                  formProps={{ changePassword: true }}
                  dataStore={rootStore.userStore}
                  uiStore={rootStore.userUiStore}
                  descriptor={USER_DESCRIPTOR}
                />
              )}
            />
            <Route
              exact
              path="/users/:id"
              component={AsyncIndividualUserPage}
            />
            <Route
              exact
              path="/vat"
              render={props => (
                <AsyncRecordTablePage
                  {...props}
                  RecordForm={LtdcoForm}
                  dataStore={rootStore.vatStore}
                  uiStore={rootStore.vatSchedulerUiStore}
                  descriptor={VAT_DESCRIPTOR}
                  viewOnly
                />
              )}
            />
            <Route
              exact
              path="/contacts"
              render={props => (
                <AsyncRecordTablePage
                  {...props}
                  RecordForm={ContactForm}
                  dataStore={rootStore.contactStore}
                  uiStore={rootStore.contactUiStore}
                  descriptor={CONTACT_DESCRIPTOR}
                />
              )}
            />
            <Route
              exact
              path="/contacts/:id"
              render={props => (
                <AsyncRecordPage
                  {...props}
                  RecordForm={ContactForm}
                  store={rootStore.contactStore}
                  descriptor={CONTACT_DESCRIPTOR}
                />
              )}
            />
            <Route exact path="/admin" component={AsyncAdminPage} />
            <Route component={AsyncNoMatch} />
          </Switch>
        </Layout>
      </div>
    </Router>
  );
};

export default App;
