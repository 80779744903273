import React, { Component } from 'react';
import { Button, Icon, Menu, Popup, Loader } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { rootStore } from '../mobx/RootStore';
import { observer } from 'mobx-react';

export const SessionManagerPopupMenu = ({ history }) => (
  <Menu vertical inverted compact>
    <Menu.Item>
      <Button inverted as={Link} to="/me" icon size="mini">
        <Icon name="setting" />Settings
      </Button>
    </Menu.Item>
    <Menu.Item>
      <Button
        inverted
        icon
        size="mini"
        onClick={() => {
          rootStore.sessionStore.logout();
          history.push('/');
        }}
      >
        <Icon name="sign out" />Log Out
      </Button>
    </Menu.Item>
  </Menu>
);

@observer
export class SessionManager extends Component {
  render() {
    if (rootStore.sessionStore.validatingInitialAuth) {
      return <Loader active />;
    }

    if (rootStore.sessionStore.loggedIn) {
      rootStore.sessionStore.checkSession();
      return (
        <Popup
          size="small"
          keepInViewPort
          position="bottom right"
          inverted
          trigger={
            <Menu size="tiny" inverted compact>
              <Menu.Item as={'a'}>
                {rootStore.sessionStore.displayName
                  ? rootStore.sessionStore.displayName
                  : rootStore.sessionStore.username}
              </Menu.Item>
            </Menu>
          }
          on="click"
        >
          <SessionManagerPopupMenu history={this.props.history} />
        </Popup>
      );
    } else {
      return (
        <Menu size="tiny" inverted compact>
          <Menu.Item>Login Required</Menu.Item>
        </Menu>
      );
    }
  }
}

export default withRouter(SessionManager);
