import React from 'react';
import ReactDOM from 'react-dom';
import {
  Checkbox,
  Input,
  Menu,
  Portal,
  Loader,
  Label
} from 'semantic-ui-react';
import { rootStore } from '../mobx/RootStore';
import { observer } from 'mobx-react';

@observer
export default class SearchAll extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchstring: '',
      suggestionsPos: {
        x: 0,
        y: 0
      },
      suggestionsVisible: false,
      selectedIndex: 0
    };

    this.selectSuggestion = this.selectSuggestion.bind(this);
    this.renderSelections = this.renderSelections.bind(this);
  }

  componentDidMount() {
    rootStore.refreshAll();
  }

  componentDidUpdate() {
    window.requestAnimationFrame(() => {
      this.scrollActiveIntoView();
    });
  }

  scrollActiveIntoView = () => {
    const itemComponent = this.refs.activeItem;
    if (itemComponent) {
      const domNode = ReactDOM.findDOMNode(itemComponent);
      this.scrollActiveIntoViewIfNeeded(domNode);
    }
  };

  scrollActiveIntoViewIfNeeded = (domNode) => {
    const containerDomNode = ReactDOM.findDOMNode(this.refs.menu);
    if (containerDomNode && domNode) {
      if (
        domNode.offsetTop + domNode.offsetHeight >
          containerDomNode.scrollTop + containerDomNode.offsetHeight ||
        domNode.offsetTop < containerDomNode.scrollTop
      ) {
        containerDomNode.scrollTop =
          domNode.offsetTop - containerDomNode.offsetTop;
      }
    }
  };

  selectSuggestion(suggestion) {
    rootStore.suggestionsStore.updateSearchFilter('');
    this.setState({
      suggestionsVisible: false,
      selectedIndex: 0
    });
    this.props.history.push('/' + suggestion.key);
  }

  renderSelections(suggestions) {
    const pending =
      rootStore.clientStore.recordsPending ||
      rootStore.ltdcoStore.recordsPending ||
      rootStore.partnershipStore.recordsPending ||
      rootStore.noteStore.notesPending ||
      rootStore.todoStore.todosPending;
    if (pending) {
      return (
        <Menu compact vertical>
          <Menu.Item disabled={true}>
            <Loader active={true} />
          </Menu.Item>
        </Menu>
      );
    }

    if (!suggestions.length) {
      return (
        <Menu compact vertical>
          <Menu.Item disabled={true}>No matching suggestions</Menu.Item>
        </Menu>
      );
    }

    return (
      <Menu
        ref="menu"
        compact
        vertical
        style={{ overflowY: 'auto', maxHeight: '250px' }}
      >
        {suggestions.map((suggestion, index) => {
          const activeItem = index === this.state.selectedIndex;
          return (
            <Menu.Item
              ref={activeItem ? 'activeItem' : undefined}
              as={'a'}
              key={suggestion.key}
              active={activeItem}
              onClick={(event, data) => {
                this.selectSuggestion(suggestion);
              }}
            >
              <span>
                {suggestion.label && <Label {...suggestion.label} />}
                {suggestion.label && ' '}
                {suggestion.render ? suggestion.render() : suggestion.text}
                {suggestion.suffix && suggestion.suffix}
              </span>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }

  updateFilter = _.debounce((value) => {
    rootStore.suggestionsStore.updateSearchFilter(value);
    var suggestionsVisible = false;
    if (value) {
      suggestionsVisible = true;
    }

    this.setState({ suggestionsVisible });
  }, 150);

  render() {
    return (
      <div>
        <Checkbox
          label="Inactive"
          checked={rootStore.searchInactive}
          onChange={(event, data) => {
            rootStore.toggleSearchInactive();
          }}
        />
        <Input
          value={this.state.searchstring}
          icon="search"
          placeholder="Search..."
          onKeyDown={(event) => {
            if (event.key === 'ArrowUp') {
              /* Up arrow */
              if (this.state.selectedIndex > 0) {
                this.setState({
                  selectedIndex: this.state.selectedIndex - 1
                });
              }
              event.preventDefault();
            }
            if (event.key == 'ArrowDown') {
              /* Down arrow */
              if (
                this.state.selectedIndex <
                rootStore.suggestionsStore.filteredSearch.length
              ) {
                this.setState({
                  selectedIndex: this.state.selectedIndex + 1
                });
              }
              event.preventDefault();
            }
            if (event.key === 'Enter') {
              if (rootStore.suggestionsStore.searchFilter !== '') {
                this.selectSuggestion(
                  rootStore.suggestionsStore.filteredSearch[
                    this.state.selectedIndex
                  ]
                );
              }
            }
          }}
          onChange={(event, data) => {
            const searchPos = event.target.getBoundingClientRect();
            this.setState({
              searchstring: data.value,
              suggestionsPos: {
                x: Math.trunc(searchPos.left),
                y: Math.trunc(searchPos.top + searchPos.height)
              },
              selectedIndex: 0
            });

            this.updateFilter(data.value);
          }}
        />
        <Portal
          onClose={() =>
            this.setState({
              suggestionsVisible: !this.state.suggestionsVisible
            })
          }
          open={this.state.suggestionsVisible}
        >
          <div
            style={{
              position: 'fixed',
              top: this.state.suggestionsPos.y + 'px',
              left: this.state.suggestionsPos.x + 'px',
              zIndex: 1000
            }}
          >
            {this.renderSelections(rootStore.suggestionsStore.filteredSearch)}
          </div>
        </Portal>
      </div>
    );
  }
}
