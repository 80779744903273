import { observable } from 'mobx';
import {
  dateAccessor,
  addressAccessor,
  lastModifiedAccessor,
  dateSort,
  lastModifiedSort,
  boolAccessor
} from '../../utils/utils';
import { TableUiStore } from './TableUiStore';

export class LtdcoUiStore extends TableUiStore {
  @observable
  sortBy = [
    {
      id: 'created',
      desc: true
    }
  ];

  @observable
  columns = [
    {
      id: 'created',
      Header: 'Created',
      accessor: dateAccessor('created'),
      sortMethod: dateSort,
      show: true
    },
    {
      Header: 'Name',
      accessor: 'name',
      show: true
    },
    {
      id: 'address',
      Header: 'Registered Address',
      accessor: addressAccessor,
      show: true
    },
    {
      id: 'operating_address',
      Header: 'Operating Address',
      accessor: addressAccessor,
      show: false
    },
    {
      id: 'active',
      Header: 'Active',
      accessor: boolAccessor('active'),
      show: false
    },
    {
      Header: 'Email',
      accessor: 'email',
      show: false
    },
    {
      Header: 'Company Status',
      accessor: 'status',
      show: false
    },
    {
      Header: 'Directors',
      accessor: 'directors',
      show: true
    },
    {
      Header: 'Secretary',
      accessor: 'secretary',
      show: true
    },
    {
      Header: 'Tel',
      accessor: 'tel',
      show: false
    },
    {
      Header: 'Mob',
      accessor: 'mob',
      show: false
    },
    {
      Header: 'Registration Number',
      accessor: 'reg_no',
      show: false
    },
    {
      Header: 'UTR',
      accessor: 'utr',
      show: false
    },
    {
      Header: 'Companies House Auth Code',
      accessor: 'companies_house_acc_code',
      show: false
    },
    {
      Header: 'VAT Number',
      accessor: 'vat_no',
      show: false
    },
    {
      Header: 'PAYE Reference',
      accessor: 'paye_ref',
      show: false
    },
    {
      Header: 'SIC Code',
      accessor: 'sic_code',
      show: false
    },
    {
      Header: 'Source',
      accessor: 'source',
      show: false
    },
    {
      Header: 'VAT Schemes',
      accessor: 'vat_schemes',
      show: false
    },
    {
      Header: 'Accounting Reference Date',
      id: 'acc_ref_date',
      accessor: dateAccessor('acc_ref_date'),
      sortMethod: dateSort,
      show: false
    },
    {
      Header: 'Filing Deadline',
      id: 'filing_deadline',
      accessor: dateAccessor('filing_deadline'),
      sortMethod: dateSort,
      show: false
    },
    {
      Header: 'Annual Return Date',
      id: 'annual_return_date',
      accessor: dateAccessor('annual_return_date'),
      sortMethod: dateSort,
      show: false
    },
    {
      Header: 'Period 1 End',
      id: 'period_1_end',
      accessor: dateAccessor('period_1_end'),
      sortMethod: dateSort,
      show: false
    },
    {
      Header: 'Period 2 End',
      id: 'period_2_end',
      accessor: dateAccessor('period_2_end'),
      sortMethod: dateSort,
      show: false
    },
    {
      Header: 'Period 3 End',
      id: 'period_3_end',
      accessor: dateAccessor('period_3_end'),
      sortMethod: dateSort,
      show: false
    },
    {
      Header: 'Period 4 End',
      id: 'period_4_end',
      accessor: dateAccessor('period_4_end'),
      sortMethod: dateSort,
      show: false
    },
    {
      Header: 'UTR Status',
      accessor: 'utr_status',
      show: false
    },
    {
      Header: 'VAT Number Status',
      accessor: 'vatno_status',
      show: false
    },
    {
      Header: 'PAYE Reference Status',
      accessor: 'paye_ref_status',
      show: false
    },
    {
      Header: 'Companies House Auth Code Status',
      accessor: 'acc_code_status',
      show: false
    },
    {
      Header: 'Agent Authority Status',
      accessor: 'agent_authority_status',
      show: false
    },
    {
      id: 'last_modified',
      Header: 'Last Modified',
      accessor: lastModifiedAccessor,
      sortMethod: lastModifiedSort,
      show: false
    },
    {
      id: 'trial_end',
      Header: 'Trial End',
      accessor: dateAccessor('trial_end'),
      sortMethod: dateSort,
      show: false
    }
  ];

  constructor(rootStore) {
    super(rootStore, 'ltdco');
  }
}
