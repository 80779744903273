import { computed, action } from 'mobx';
import WaccDbApi from '../api/WaccDbApi';

export class VatStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @action
  refreshCollection() {
    this.rootStore.clientStore.refreshCollection();
    return this.rootStore.ltdcoStore.refreshCollection();
  }

  updateOne = (id, record, onSuccess, onFailure) => {
    return WaccDbApi.patch(
      '/' + this.getCollection({ _id: { $oid: id } }) + '/' + id,
      record,
      data => {
        this.refreshCollection();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  @computed
  get records() {
    var records = this.rootStore.clientStore.records.filter(
      item => (item.vat ? item.vat.track : false)
    );
    return records.concat(
      this.rootStore.ltdcoStore.records.filter(
        item => (item.vat ? item.vat.track : false)
      )
    );
  }

  @computed
  get recordsPending() {
    return (
      this.rootStore.clientStore.recordsPending ||
      this.rootStore.ltdcoStore.recordsPending
    );
  }

  getCollection = record => {
    if (this.rootStore.clientStore.recordsById.get(record._id.$oid)) {
      return 'clients';
    } else if (this.rootStore.ltdcoStore.recordsById.get(record._id.$oid)) {
      return 'ltd_companies';
    } else {
      return 'unknown';
    }
  };
}
