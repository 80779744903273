import { action } from 'mobx';
import { Record, RecordStore } from '../records/RecordStore';

export class Contact extends Record {
  constructor(id, rootStore, collectionName) {
    super(id, rootStore);
    this.collectionName = collectionName;
    this.get();
  }

  @action.bound
  getFees() {}

  @action.bound
  getLinks() {}

  @action.bound
  getNotes() {}
}

export class ContactStore extends RecordStore {
  constructor(rootStore) {
    super(rootStore);
    this.collectionName = 'contacts';
  }

  @action
  get(id) {
    this.record = new Contact(id, this.rootStore, this.collectionName);
  }
}
