import React from 'react';
import { Form, Message } from 'semantic-ui-react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import { TAX_STATUS_OPTIONS } from '../../../types/enum-options';
import { TAX_YEARS, getTaxStatusColour } from '../../../utils/utils';
import { SelectAdapter } from './SelectField';

export const SubmissionHistoryField = ({ name, label, ...props }) => (
  <div>
    {label && (
      <label as="h5" style={{ fontWeight: 'bold', paddingBottom: '1em' }}>
        {label}
      </label>
    )}
    <br />
    <Form.Group widths="equal">
      {TAX_YEARS.map(year => {
        const intYear = parseInt(year);
        const taxYear =
          (intYear - 1).toString().substring(2) +
          '/' +
          intYear.toString().substr(2);
        return (
          <Field
            key={year}
            name={name + '.year_end_' + year}
            render={renderProps => {
              return (
                <Form.Field
                  error={!!(renderProps.meta.touched && renderProps.meta.error)}
                  style={{
                    backgroundColor: getTaxStatusColour(
                      renderProps.input.value
                    ),
                    textAlign: 'center',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    border: '1px solid rgba(34,36,38,.15)',
                    paddingTop: '1em',
                    paddingBottom: '1em'
                  }}
                >
                  {taxYear}
                  <br />
                  <SelectAdapter
                    compact
                    {...renderProps}
                    options={[
                      { key: -1, text: '', value: null },
                      ...TAX_STATUS_OPTIONS
                    ]}
                    style={{
                      backgroundColor: getTaxStatusColour(
                        renderProps.input.value
                      )
                    }}
                  />
                  {renderProps.meta.touched && renderProps.meta.error ? (
                    <Message negative>
                      <Message.Header>{label} is invalid</Message.Header>
                      {renderProps.meta.error.error}
                    </Message>
                  ) : null}
                </Form.Field>
              );
            }}
          />
        );
      })}
    </Form.Group>
  </div>
);

SubmissionHistoryField.propTypes = {
  name: PropTypes.string.isRequired
};
