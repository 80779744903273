import React from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import { FormWrapper } from './FormApi';
import { rootStore } from '../../mobx/RootStore';
import { PRIORITY_OPTIONS } from '../../types/enum-options';
import { TodoArrayField } from './components/TodoArrayField';
import { TextAreaField } from './components/TextAreaField';
import { SelectField } from './components/SelectField';
import { CheckboxField } from './components/CheckboxField';
import { DatePickerField } from './components/DatePickerField';

const TodoForm = props => {
  const { initialValues, formSubmitHandler, onCancel } = props;
  return (
    <FormWrapper
      initialValues={initialValues || { tasks: [{ text: '', done: false }] }}
      formSubmitHandler={formSubmitHandler}
      onCancel={onCancel}
    >
      <Grid stackable verticalAlign="middle">
        <Grid.Row verticalAlign="middle" columns={16}>
          <Grid.Column mobile={16} computer={16} largeScreen={8} widescreen={8}>
            <SelectField
              name="related_entity"
              label="Related Client"
              fluid
              search
              options={[
                {
                  key: 'empty',
                  value: null,
                  text: ''
                },
                ...rootStore.suggestionsStore.suggestions
              ]}
              placeholder="Related Client..."
            />
          </Grid.Column>
          <Grid.Column mobile={8} computer={8} largeScreen={4} widescreen={4}>
            <CheckboxField name="completed" label="Completed" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row verticalAlign="middle" columns={16}>
          <Grid.Column mobile={8} computer={8} largeScreen={4} widescreen={4}>
            <SelectField
              name="assignee"
              label="Assigned To"
              fluid
              search
              placeholder="Assigned to..."
              options={rootStore.userStore.userOptions}
            />
          </Grid.Column>
          <Grid.Column mobile={8} computer={8} largeScreen={4} widescreen={4}>
            <SelectField
              name="priority"
              label="Priority"
              fluid
              placeholder="Priority"
              options={PRIORITY_OPTIONS}
            />
          </Grid.Column>
          <Grid.Column mobile={8} computer={8} largeScreen={4} widescreen={4}>
            <DatePickerField
              name="deadline"
              label="Deadline"
              placeholder="Deadline"
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Grid stackable>
        <Grid.Row columns={16}>
          <Grid.Column
            mobile={16}
            computer={16}
            largeScreen={16}
            widescreen={16}
          >
            <TextAreaField
              name="notes"
              rows={3}
              label="Notes"
              placeholder="Notes..."
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={16}>
          <Grid.Column width={16}>
            <Segment secondary>
              <TodoArrayField name="tasks" fluid placeholder="Task..." />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </FormWrapper>
  );
};

export default TodoForm;
