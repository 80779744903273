export const validateRequired = value => (value ? undefined : 'Required');

export const validateEmail = value =>
  value && !/^.+@.*(\..+)+$/i.test(value) ? 'Invalid email address' : undefined;

export const validatePersonalUTR = value =>
  value && !/^[0-9]{5} [0-9]{5}$/i.test(value)
    ? 'Invalid Personal UTR'
    : undefined;

export const validateLtdCoUTR = value =>
  value && !/^[0-9]{3}\/[0-9]{5} [0-9]{5}$/i.test(value)
    ? 'Invalid Limited Company UTR'
    : undefined;

export const validateNINumber = value =>
  value && !/^[A-Z]{2}[0-9]{6}[A-Z]{1}$/i.test(value)
    ? 'Invalid NI Number'
    : undefined;

export const validateCompaniesHouseRegNo = value =>
  value && !/^[A-Z0-9]{2}[0-9]{6}$/i.test(value)
    ? 'Invalid Companies House Registration Number'
    : undefined;

export const validatePhoneNumber = value =>
  value && !/^\+?[0-9() ]*$/i.test(value) ? 'Invalid phone number' : undefined;

export const validatePAYERef = value =>
  value && !/^[0-9]{3}\/[A-Z]{1,2}[0-9]{5,8}$/i.test(value)
    ? 'Invalid PAYE Reference'
    : undefined;

export const validateSICCode = value =>
  value && !/^[0-9]{4,5}( [0-9]{3}){0,1}$/i.test(value)
    ? 'Invalid SIC Code'
    : undefined;

export const validateAccountOfficeRef = value =>
  value && !/^[0-9]{3}[A-Z]{2}[0-9]{8}$/i.test(value)
    ? 'Invalid Accounts Office Reference'
    : undefined;

export const validateCompaniesHouseAccCode = value =>
  value && !/^[a-zA-Z0-9]{6}$/i.test(value)
    ? 'Invalid Companies House Auth Code'
    : undefined;

export const validateVATNo = value =>
  value && !/^[0-9]{3} [0-9]{4} [0-9]{2}( [0-9]{3}){0,1}$/i.test(value)
    ? 'Invalid VAT Number'
    : undefined;
