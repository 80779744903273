import { observable } from 'mobx';
import {
  dateAccessor,
  addressAccessor,
  lastModifiedAccessor,
  dateSort,
  lastModifiedSort,
  directorsAccessor,
  directorsEmailsAccessor,
  boolAccessor
} from '../../utils/utils';
import { TableUiStore } from './TableUiStore';

export class LeadUiStore extends TableUiStore {
  @observable
  sortBy = [
    {
      id: 'created',
      desc: true
    }
  ];

  @observable
  columns = [
    {
      id: 'created',
      Header: 'Created',
      accessor: dateAccessor('created'),
      sortMethod: dateSort,
      show: true
    },
    {
      id: 'active',
      Header: 'Active',
      accessor: boolAccessor('active'),
      show: false
    },
    {
      id: 'new',
      Header: 'New',
      accessor: boolAccessor('new'),
      show: false
    },
    {
      Header: 'Name',
      accessor: 'name',
      show: true
    },
    {
      id: 'address',
      Header: 'Registered Address',
      accessor: addressAccessor,
      show: true
    },
    {
      id: 'operating_address',
      Header: 'Operating Address',
      accessor: addressAccessor,
      show: false
    },
    {
      Header: 'Shares',
      accessor: 'shares',
      show: false
    },
    {
      id: 'directors',
      Header: 'Directors',
      accessor: directorsAccessor,
      show: true
    },
    {
      Header: 'Registration Number',
      accessor: 'reg_no',
      show: false
    },
    {
      Header: 'UTR',
      accessor: 'utr',
      show: false
    },
    {
      Header: 'Companies House Auth Code',
      accessor: 'companies_house_acc_code',
      show: false
    },
    {
      Header: 'SIC Code',
      accessor: 'sic_code',
      show: false
    },
    {
      Header: 'Banking Preference',
      accessor: 'bank_pref',
      show: false
    },
    {
      Header: 'Spreadsheet Preference',
      accessor: 'spreadsheet_pref',
      show: false
    },
    {
      id: 'first_call_deadline',
      Header: 'First Call',
      accessor: dateAccessor('first_call_deadline'),
      sortMethod: dateSort,
      show: false
    },
    {
      id: 'first_call_complete',
      Header: 'First Call Complete',
      accessor: boolAccessor('first_call_complete'),
      show: false
    },
    {
      id: 'second_call_deadline',
      Header: 'Second Call',
      accessor: dateAccessor('second_call_deadline'),
      sortMethod: dateSort,
      show: false
    },
    {
      id: 'second_call_complete',
      Header: 'Second Call Complete',
      accessor: boolAccessor('second_call_complete'),
      show: false
    },
    {
      id: 'prekill_deadline',
      Header: 'Pre-Kill',
      accessor: dateAccessor('prekill_deadline'),
      sortMethod: dateSort,
      show: false
    },
    {
      id: 'prekill_complete',
      Header: 'Pre-Kill Email Complete',
      accessor: boolAccessor('prekill_complete'),
      show: false
    },
    {
      id: 'kill_deadline',
      Header: 'Kill',
      accessor: dateAccessor('kill_deadline'),
      sortMethod: dateSort,
      show: false
    },
    {
      id: 'kill_complete',
      Header: 'Kill Email Complete',
      accessor: boolAccessor('kill_complete'),
      show: false
    },
    {
      id: 'last_modified',
      Header: 'Last Modified',
      accessor: lastModifiedAccessor,
      sortMethod: lastModifiedSort,
      show: false
    },
    {
      id: 'directors_emails',
      Header: 'Directors Emails',
      accessor: directorsEmailsAccessor,
      show: true
    }
  ];

  constructor(rootStore) {
    super(rootStore, 'lead');
  }
}
