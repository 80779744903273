import React from 'react';
import { Icon } from 'semantic-ui-react';
import { InputField } from './InputField';

export const EmailField = props => (
  <InputField
    icon={
      <Icon
        name="at"
        link
        circular
        inverted
        onClick={() => {
          var newWindow = window.open('mailto:' + props.email, '_self');
          newWindow.focus();
        }}
      />
    }
    {...props}
  />
);

EmailField.propTypes = InputField.propTypes;
