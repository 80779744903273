import React from 'react';
import { Container, Form, Grid, Segment } from 'semantic-ui-react';
import {
  FIELD_STATUS_OPTIONS,
  COMPANY_STATUS_OPTIONS,
  SIC_OPTIONS
} from '../../types/enum-options';
import { InputField } from './components/InputField';
import { CheckboxField } from './components/CheckboxField';
import { DatePickerField } from './components/DatePickerField';
import { EmailField } from './components/EmailField';
import { FormWrapper } from './FormApi';
import {
  validateRequired,
  validateEmail,
  validatePhoneNumber,
  validateCompaniesHouseRegNo,
  validateLtdCoUTR,
  validateCompaniesHouseAccCode,
  validateVATNo,
  validatePAYERef,
  validateSICCode
} from '../../types/validation';
import { TabbedAddressField } from './components/AddressField';
import { SelectField } from './components/SelectField';
import { VATStatusField } from './components/VATStatusField';

export const LtdcoForm = ({ initialValues, formSubmitHandler, onCancel }) => (
  <FormWrapper
    initialValues={initialValues || { active: true }}
    formSubmitHandler={formSubmitHandler}
    onCancel={onCancel}
  >
    <Form.Group>
      <Container fluid>
        <Grid verticalAlign="middle">
          <Grid.Row width={16}>
            <Grid.Column width={10}>
              <InputField
                label="Name"
                name="name"
                placeholder="Name"
                validate={validateRequired}
              />
            </Grid.Column>
            <Grid.Column textAlign="center" width={3}>
              <DatePickerField label="Trial End" name="trial_end" />
            </Grid.Column>
            <Grid.Column width={3}>
              <CheckboxField
                name="active"
                toggle
                fitted
                width={3}
                label="Active"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Form.Group>
    <Grid stackable>
      <Grid.Row columns={16}>
        <Grid.Column mobile={16} computer={16} largeScreen={8} widescreen={8}>
          <TabbedAddressField
            names={[
              {
                title: 'Registered Office Address',
                name: 'address'
              },
              {
                title: 'Operating Address',
                name: 'operating_address'
              }
            ]}
          />
        </Grid.Column>
        <Grid.Column mobile={16} computer={16} largeScreen={8} widescreen={8}>
          <Segment>
            <EmailField
              label="Email"
              name="email"
              fluid
              validate={validateEmail}
              email={initialValues ? initialValues.email || '' : ''}
            />
            <InputField label="Directors" name="directors" fluid />
            <InputField label="Secretary" name="secretary" fluid />
            <Form.Group widths="equal">
              <InputField
                label="Telephone"
                name="tel"
                fluid
                validate={validatePhoneNumber}
              />
              <InputField
                label="Mobile"
                name="mob"
                fluid
                validate={validatePhoneNumber}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <InputField
                label="Registration Number"
                name="reg_no"
                fluid
                validate={validateCompaniesHouseRegNo}
              />
              <InputField
                label="UTR"
                name="utr"
                fluid
                validate={validateLtdCoUTR}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <InputField
                label="Companies House Auth Code"
                name="companies_house_acc_code"
                fluid
                validate={validateCompaniesHouseAccCode}
              />
              <InputField
                label="VAT Number"
                name="vat_no"
                fluid
                validate={validateVATNo}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <InputField
                label="PAYE Reference"
                name="paye_ref"
                fluid
                validate={validatePAYERef}
              />
              <SelectField
                label="SIC Code"
                name="sic_code"
                fluid
                search
                options={SIC_OPTIONS}
                validate={validateSICCode}
              />
            </Form.Group>
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Form.Group widths="equal">
      <InputField label="Source" name="source" fluid />
      <InputField label="VAT Schemes" name="vat_schemes" fluid />
    </Form.Group>
    <Segment>
      <Form.Group widths="equal">
        <SelectField
          label="Status"
          name="status"
          fluid
          options={COMPANY_STATUS_OPTIONS}
        />
        <DatePickerField label="Incorporation Date" name="incorporation_date" />
      </Form.Group>
    </Segment>
    <Segment>
      <Form.Group widths="equal">
        <DatePickerField label="Accounting Ref Date" name="acc_ref_date" />
        <DatePickerField label="Filing Deadline" name="filing_deadline" />
        <DatePickerField label="Annual Return" name="annual_return_date" />
      </Form.Group>
      <Form.Group widths="equal">
        <DatePickerField label="Period 1 End" name="period_1_end" />
        <DatePickerField label="Period 2 End" name="period_2_end" />
        <DatePickerField label="Period 3 End" name="period_3_end" />
        <DatePickerField label="Period 4 End" name="period_4_end" />
      </Form.Group>
    </Segment>
    <Segment>
      <Form.Group widths="equal">
        <SelectField
          label="UTR Status"
          name="utr_status"
          fluid
          options={FIELD_STATUS_OPTIONS}
        />
        <SelectField
          label="VAT Number Status"
          name="vatno_status"
          fluid
          options={FIELD_STATUS_OPTIONS}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <SelectField
          label="PAYE Reference Status"
          name="paye_ref_status"
          fluid
          options={FIELD_STATUS_OPTIONS}
        />
        <SelectField
          label="Companies House Auth Code Status"
          name="acc_code_status"
          fluid
          options={FIELD_STATUS_OPTIONS}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <SelectField
          label="Agent Authority Status"
          name="agent_authority_status"
          fluid
          options={FIELD_STATUS_OPTIONS}
        />
      </Form.Group>
      <VATStatusField label="VAT Status" name="vat" />
    </Segment>
  </FormWrapper>
);
