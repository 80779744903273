import { rootStore } from '../mobx/RootStore';

export var WACC_DB_API_ROOT;
var logApiCalls = false;

/* istanbul ignore if */
if (process.env.NODE_ENV === 'production') {
  WACC_DB_API_ROOT = 'https://app.waltonaccountancy.co.uk/waccdb/v1';
} else {
  WACC_DB_API_ROOT = 'http://localhost:5000/waccdb/v1';
  /* istanbul ignore if */
  if (process.env.NODE_ENV !== 'test') {
    logApiCalls = true;
  }
}

const handleResp = response => {
  if (response.status >= 400) {
    if (response.status == 401) {
      // Auth error
      rootStore.sessionStore.clearAuth();
    }
    throw {
      status: response.status,
      responseAsText: response.text().then(text => text)
    };
  }

  return response;
};

const translateResp = response => {
  if (response.headers.get('Content-Type') === 'application/json') {
    return response.json().then(json => ({
      headers: response.headers,
      status: response.status,
      json
    }));
  }

  return response.text().then(text => ({
    headers: response.headers,
    status: response.status,
    text
  }));
};

class DbApi {
  callApi(
    method,
    uri,
    onSuccess,
    onFailure,
    customHeaders = null,
    body = null,
    etag = null
  ) {
    let headers = new Headers();
    var jsonBody = null;

    headers.append('Accept', 'application/json');
    if (rootStore.sessionStore.access) {
      headers.append(
        'Authorization',
        'Bearer ' + rootStore.sessionStore.access
      );
    }

    if (body != null) {
      headers.append('Content-Type', 'application/json; charset=utf-8');
      jsonBody = JSON.stringify(body);
    }

    if (etag != null) {
      headers.append('If-Match', etag);
    }

    if (customHeaders) {
      customHeaders.forEach(element => {
        if (element.value) {
          headers.append(element.header, element.value);
        }
      });
    }

    var url = WACC_DB_API_ROOT + uri;

    /* istanbul ignore if */
    if (logApiCalls) {
      console.log('API Request: ', method, url, headers, jsonBody);
    }
    return fetch(url, { method, headers, body: jsonBody, cache: 'no-cache' })
      .then(handleResp)
      .then(translateResp)
      .then(data => {
        /* istanbul ignore if */
        if (logApiCalls) {
          console.log(
            'API Success: ',
            data.status,
            data.headers,
            data.json,
            data.text
          );
        }
        onSuccess(data);
      })
      .catch(error => {
        /* istanbul ignore if */
        if (logApiCalls) {
          console.error('API Failure: ', error);
        }
        onFailure(error);
      });
  }

  get(uri, onSuccess, onFailure, customHeaders = null) {
    return this.callApi('GET', uri, onSuccess, onFailure, customHeaders);
  }

  patch(uri, body, onSuccess, onFailure, customHeaders = null) {
    return this.callApi(
      'PATCH',
      uri,
      onSuccess,
      onFailure,
      customHeaders,
      body
    );
  }

  patchWithEtag(uri, etag, body, onSuccess, onFailure, customHeaders = null) {
    return this.callApi(
      'PATCH',
      uri,
      onSuccess,
      onFailure,
      customHeaders,
      body,
      etag
    );
  }

  post(uri, body, onSuccess, onFailure, customHeaders = null) {
    return this.callApi('POST', uri, onSuccess, onFailure, customHeaders, body);
  }

  put(uri, body, onSuccess, onFailure, customHeaders = null) {
    return this.callApi('PUT', uri, onSuccess, onFailure, customHeaders, body);
  }

  delete(uri, onSuccess, onFailure, customHeaders = null) {
    return this.callApi('DELETE', uri, onSuccess, onFailure, customHeaders);
  }
}

const WaccDbApi = new DbApi();

export default WaccDbApi;
