import React, { Component } from 'react';
import {
  Icon,
  Grid,
  Label,
  Container,
  Header,
  Select,
  List,
  Divider,
  Card,
  Segment,
  Button,
  Checkbox,
  Modal,
  Loader,
  Dimmer
} from 'semantic-ui-react';
import moment from 'moment';
import TodoForm from './form/TodoForm';
import { getCollectionTheme } from '../utils/theme';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { rootStore } from '../mobx/RootStore';
import { splitCollectionAndId, dateSort } from '../utils/utils';
import {
  prioToValue,
  PRIORITY_OPTIONS,
  TODO_SORT_BY_OPTIONS
} from '../types/enum-options';
import { confirmAlert } from './ConfirmationModal';

export class NewTodoModal extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({ open: true });
  }

  close(ev) {
    if (ev === null) {
      this.setState({ open: false });
    } else {
      if (confirm('Are you sure?')) {
        this.setState({ open: false });
      }
    }
  }

  render() {
    const { formSubmitHandler, trigger, todo, related_entity } = this.props;
    const { open } = this.state;

    return (
      <Modal
        onOpen={this.open}
        onClose={this.close}
        trigger={trigger}
        header="New Todo"
        open={open}
        content={
          <Segment>
            <TodoForm
              initialValues={
                related_entity
                  ? {
                      related_entity: related_entity,
                      tasks: [{ text: '', done: false }]
                    }
                  : null
              }
              formSubmitHandler={(formData, callback) => {
                formSubmitHandler(formData, callback).then(this.close(null));
              }}
              onCancel={dirty => {
                this.close();
              }}
            />
          </Segment>
        }
      />
    );
  }
}

class EditTodoModal extends Component {
  constructor(props) {
    super(props);

    this.state = { open: false };

    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  open() {
    this.setState({ open: true });
  }

  close(ev) {
    if (ev === null) {
      this.setState({ open: false });
    } else {
      if (confirm('Are you sure you wish to abandon edits?')) {
        this.setState({ open: false });
      }
    }
  }

  render() {
    const { formSubmitHandler, trigger, todo } = this.props;
    const { open } = this.state;

    return (
      <Modal
        onOpen={this.open}
        onClose={this.close}
        trigger={trigger}
        header="Edit Todo"
        open={open}
        content={
          <Segment>
            <TodoForm
              initialValues={{ ...todo }}
              formSubmitHandler={(formData, callback) => {
                formSubmitHandler(formData, callback).then(this.close(null));
              }}
              onCancel={dirty => {
                this.close();
              }}
            />
          </Segment>
        }
      />
    );
  }
}

export const todoDateSort = (a, b) => {
  if ((a === '' || !a) && (b === '' || !b)) {
    /* Both are empty and therefore they are equal */
    return 0;
  }

  if (a === '' || !a) {
    /* a is empty, and b is not therefore a must be less than b */
    return -1;
  }

  if (b === '' || !b) {
    /* b is empty, and a is not therefore a must be greater than b */
    return 1;
  }

  return moment.utc(a, 'YYYY-MM-DD').diff(moment.utc(b, 'YYYY-MM-DD'));
};

export const sortTodo = (a, b) => {
  if (rootStore.todoUiStore.sortBy === 'deadline') {
    /* Deadline */
    if (a.deadline == b.deadline) {
      console.log('Equal');
      return moment.utc(a.created).diff(moment.utc(b.created));
    }

    console.log(
      'a.deadline: ',
      a.deadline,
      'b.deadline',
      b.deadline,
      'dateSort: ',
      dateSort(a.deadline, b.deadline)
    );
    return todoDateSort(a.deadline, b.deadline);
  } else if (rootStore.todoUiStore.sortBy === 'created') {
    return moment.utc(a.created).diff(moment.utc(b.created));
  }

  /* Default */

  /* Priority first, then created date if equal */
  var prio_a = prioToValue(a.priority);
  var prio_b = prioToValue(b.priority);
  if (prio_a == prio_b) {
    return moment.utc(a.created).diff(moment.utc(b.created));
  }

  return prio_a - prio_b;
};

export const TodoCard = ({ history, todo, refresh }) => {
  var lowestPrio = false;
  var highestPrio = false;
  var prioColour = 'grey';
  var entityId = {
    collection: ''
  };
  if (todo.related_entity) {
    entityId = splitCollectionAndId(todo.related_entity);
  }
  switch (todo.priority) {
    case undefined:
    case null:
    case 'None':
      prioColour = 'grey';
      lowestPrio = true;
      break;

    case 'Low':
      prioColour = 'green';
      break;

    case 'Medium':
      prioColour = 'orange';
      break;

    case 'High':
      prioColour = 'red';
      highestPrio = true;
      break;
  }

  return (
    <Card
      key={todo._id.$oid}
      style={todo.completed ? { backgroundColor: '#cccccc' } : null}
    >
      <Card.Content>
        <Card.Header>
          <span style={{ textDecoration: 'underline' }}>
            {rootStore.userStore.getDisplayName(todo.assignee)}
          </span>
          {todo.completed ? <span> (Completed)</span> : <span />}
        </Card.Header>
        <Card.Meta textAlign="center">
          <div>
            <Button
              size="tiny"
              fluid
              disabled={!todo.related_entity}
              color={getCollectionTheme(entityId.collection).name}
              onClick={(event, data) => {
                history.push(
                  '/' + entityId.collection + '/' + entityId.id,
                  '_blank'
                );
              }}
            >
              {todo.related_entity
                ? rootStore.name(entityId.collection, entityId.id)
                : '---'}
            </Button>
            <br />
            {todo.deadline &&
              'Deadline ' + moment(todo.deadline).format('DD/MM/YYYY')}
          </div>
        </Card.Meta>
        <Card.Description>
          {todo.tasks && (
            <List>
              {todo.tasks.reduce((arr, item, itemIndex) => {
                if (!item.done) {
                  arr.push(
                    <List.Item key={itemIndex}>
                      <Icon
                        link
                        name="circle outline"
                        onClick={(event, data) => {
                          var updatedTodo = toJS(todo);
                          updatedTodo.tasks[itemIndex].done = !updatedTodo
                            .tasks[itemIndex].done;

                          rootStore.todoStore
                            .updateTodo(updatedTodo, data => {}, error => {})
                            .then(data => {
                              if (refresh) {
                                refresh();
                              }
                            });
                        }}
                      />
                      <List.Content>
                        <List.Description>
                          <span>{item.text}</span>
                        </List.Description>
                      </List.Content>
                    </List.Item>
                  );
                }
                return arr;
              }, [])}
            </List>
          )}
        </Card.Description>
      </Card.Content>
      <Card.Content extra>
        {todo.tasks && (
          <List>
            {todo.tasks.reduce((arr, item, itemIndex) => {
              if (item.done) {
                arr.push(
                  <List.Item key={itemIndex}>
                    <Icon
                      link
                      name="circle check outline"
                      onClick={(event, data) => {
                        var updatedTodo = toJS(todo);
                        updatedTodo.tasks[itemIndex].done = !updatedTodo.tasks[
                          itemIndex
                        ].done;

                        rootStore.todoStore
                          .updateTodo(updatedTodo, data => {}, error => {})
                          .then(data => {
                            if (refresh) {
                              refresh();
                            }
                          });
                      }}
                    />
                    <List.Content>
                      <List.Description>
                        <strike>{item.text}</strike>
                      </List.Description>
                    </List.Content>
                  </List.Item>
                );
              }
              return arr;
            }, [])}
          </List>
        )}
      </Card.Content>
      <Card.Content extra>
        {todo.notes ? ' ' + todo.notes + ' ' : '---'}
      </Card.Content>
      <Card.Content extra>
        <label>Priority: </label>
        <Label color={prioColour} circular empty />
        {' ' + (todo.priority || 'None')}
      </Card.Content>
      <Card.Content>
        {todo.completed ? (
          <Icon
            name="cancel"
            link
            onClick={(event, data) => {
              var editedTodo = toJS(todo);
              editedTodo.completed = !editedTodo.completed;

              rootStore.todoStore
                .updateTodo(editedTodo, data => {}, error => {})
                .then(data => {
                  if (refresh) {
                    refresh();
                  }
                });
            }}
          />
        ) : (
          <Icon
            name="check"
            link
            onClick={(event, data) => {
              confirmAlert({
                title: 'Complete Todo?',
                message:
                  ' Are you sure you want to mark this todo as complete?',
                onConfirm: () => {
                  var editedTodo = toJS(todo);
                  editedTodo.completed = !editedTodo.completed;

                  rootStore.todoStore
                    .updateTodo(editedTodo, data => {}, error => {})
                    .then(data => {
                      if (refresh) {
                        refresh();
                      }
                    });
                }
              });
            }}
          />
        )}
        <EditTodoModal
          todo={toJS(todo)}
          trigger={<Icon name="edit" link />}
          formSubmitHandler={(formData, callback) => {
            return rootStore.todoStore
              .updateTodo(
                formData,
                data => {
                  callback();
                },
                error => {
                  callback({
                    FORM_ERROR: 'Failed to submit'
                  });
                }
              )
              .then(data => {
                if (refresh) {
                  refresh();
                }
              });
          }}
        />
        <Icon
          name="trash"
          link
          onClick={(event, data) => {
            confirmAlert({
              title: 'Delete Todo?',
              message: 'Are you sure you want to delete this todo?',
              onConfirm: () => {
                rootStore.todoStore
                  .deleteTodo(todo._id.$oid, data => {}, error => {})
                  .then(data => {
                    if (refresh) {
                      refresh();
                    }
                  });
              }
            });
          }}
        />
        <Icon
          name="arrow circle up"
          link={!highestPrio}
          disabled={highestPrio}
          onClick={(event, data) => {
            if (highestPrio) {
              return;
            }
            var editedTodo = toJS(todo);
            switch (editedTodo.priority) {
              case undefined:
              case null:
              case 'None':
                editedTodo.priority = 'Low';
                break;

              case 'Low':
                editedTodo.priority = 'Medium';
                break;

              case 'Medium':
                editedTodo.priority = 'High';
                break;
            }

            rootStore.todoStore
              .updateTodo(editedTodo, data => {}, error => {})
              .then(data => {
                if (refresh) {
                  refresh();
                }
              });
          }}
        />
        <Icon
          name="arrow circle down"
          link={!lowestPrio}
          disabled={lowestPrio}
          onClick={(event, data) => {
            if (lowestPrio) {
              return;
            }
            var editedTodo = toJS(todo);
            switch (editedTodo.priority) {
              case 'Low':
                editedTodo.priority = 'None';
                break;

              case 'Medium':
                editedTodo.priority = 'Low';
                break;

              case 'High':
                editedTodo.priority = 'Medium';
                break;
            }

            rootStore.todoStore
              .updateTodo(editedTodo, data => {}, error => {})
              .then(data => {
                if (refresh) {
                  refresh();
                }
              });
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            color: 'rgba(0,0,0,.4)',
            fontSize: '1em'
          }}
        >
          Created {moment(todo.created).format('DD/MM/YYYY')} <br />
          {todo.created_by &&
            ' by ' + rootStore.userStore.getDisplayName(todo.created_by)}
        </div>
      </Card.Content>
    </Card>
  );
};

@observer
export default class Todo extends Component {
  componentDidMount() {
    rootStore.todoStore.refreshCollection();
    rootStore.userStore.refreshCollection();
  }

  render() {
    const {
      selectedOwner,
      showCompleted,
      visiblePriority,
      sortBy,
      sortDescending
    } = rootStore.todoUiStore;
    var visibleTodos = [];

    const pending =
      rootStore.clientStore.recordsPending ||
      rootStore.ltdcoStore.recordsPending ||
      rootStore.partnershipStore.recordsPending ||
      rootStore.leadStore.recordsPending;

    if (!pending) {
      visibleTodos = rootStore.todoStore.todos.filter(item => {
        if (item.completed === true && showCompleted === false) {
          return false;
        }

        if (visiblePriority && item.priority !== visiblePriority) {
          return false;
        }

        if (selectedOwner && item.assignee !== selectedOwner) {
          return false;
        }
        return true;
      });
      visibleTodos.sort(sortTodo);
      if (rootStore.todoUiStore.sortDescending) {
        visibleTodos.reverse();
      }
    }

    return (
      <Container fluid>
        <Grid stackable verticalAlign="middle">
          <Grid.Row column={16}>
            <Grid.Column width={16} textAlign="center">
              <Header>Todo List</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row column={16}>
            <Grid.Column width={2}>
              <label>User: </label>
            </Grid.Column>
            <Grid.Column width={4}>
              <Select
                fluid
                options={rootStore.userStore.userOptions}
                value={selectedOwner}
                onChange={(event, value) => {
                  rootStore.todoUiStore.setSelectedOwner(value.value);
                }}
              />
            </Grid.Column>
            <Grid.Column width={2}>
              <label>Priority: </label>
            </Grid.Column>
            <Grid.Column width={4}>
              <Select
                fluid
                options={[
                  {
                    key: -1,
                    value: null,
                    text: ''
                  },
                  ...PRIORITY_OPTIONS
                ]}
                value={visiblePriority}
                onChange={(event, value) => {
                  rootStore.todoUiStore.setPriority(value.value);
                }}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Checkbox
                label="Show Completed?"
                checked={showCompleted}
                onChange={(event, data) => {
                  rootStore.todoUiStore.toggleShowCompleted();
                }}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row column={16}>
            <Grid.Column width={2}>
              <label>Sort By: </label>
            </Grid.Column>
            <Grid.Column width={4}>
              <Select
                fluid
                options={[
                  {
                    key: -1,
                    value: null,
                    text: ''
                  },
                  ...TODO_SORT_BY_OPTIONS
                ]}
                value={sortBy}
                onChange={(event, value) => {
                  rootStore.todoUiStore.setSortBy(value.value);
                }}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Checkbox
                label="Sort Descending"
                checked={sortDescending}
                onChange={(event, data) => {
                  rootStore.todoUiStore.toggleSortDescending();
                }}
              />
            </Grid.Column>
            <Grid.Column width={2} />
            <Grid.Column width={4} textAlign="right">
              <NewTodoModal
                formSubmitHandler={(formData, callback) => {
                  return rootStore.todoStore.createTodo(
                    formData,
                    data => {
                      callback();
                    },
                    error => {
                      callback({
                        ...error,
                        FORM_ERROR: 'Failed to submit'
                      });
                    }
                  );
                }}
                trigger={
                  <Button icon labelPosition="right">
                    Create Todo<Icon name="sticky note" />
                  </Button>
                }
              />
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row>
            <Grid.Column width={16}>
              <Dimmer.Dimmable
                as={Container}
                fluid
                dimmed={rootStore.todoStore.todosPending}
              >
                <Dimmer active={rootStore.todoStore.todosPending} inverted>
                  <Loader>Loading</Loader>
                </Dimmer>
                <Card.Group>
                  {visibleTodos.map((todo, index) => (
                    <TodoCard
                      history={this.props.history}
                      key={todo._id.$oid}
                      todo={todo}
                    />
                  ))}
                </Card.Group>
              </Dimmer.Dimmable>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}
