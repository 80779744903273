import React from 'react';
import {
  toJS,
  computed,
  observable,
  action,
  isObservableArray,
  isObservableObject
} from 'mobx';
import {
  CLIENT_DESCRIPTOR,
  LTDCO_DESCRIPTOR,
  PARTNERSHIP_DESCRIPTOR,
  LEAD_DESCRIPTOR,
  CONTACT_DESCRIPTOR
} from '../../types/descriptors';
import { Icon } from 'semantic-ui-react';
import { splitCollectionAndId } from '../../utils/utils';
import { UNKNOWN_COLOUR, getCollectionTheme } from '../../utils/theme';
import Fuse from 'fuse.js';

const highlightMatch = result => {
  return (
    <span>
      {result.matches.map((match, index) => {
        var elements = [];
        var start = 0;
        var end = 0;

        match.indices.forEach(pair => {
          end = pair[0];
          elements.push(
            <span key={elements.length}>
              {match.value.substring(start, end)}
            </span>
          );
          start = end;
          end = pair[1] + 1;
          elements.push(
            <mark key={elements.length}>
              {match.value.substring(start, end)}
            </mark>
          );
          start = end;
        });
        elements.push(
          <span key={elements.length}>{match.value.substring(start)}</span>
        );
        return (
          <i key={index}>
            <br />
            <strong>{match.key}: </strong>
            {elements}
          </i>
        );
      })}
    </span>
  );
};

export const stringifyObservable = record => {
  if (record === null) {
    return 'null';
  }

  if (record === undefined) {
    return 'undefined';
  }

  if (isObservableArray(record) || Array.isArray(record)) {
    const values = record.map(value => {
      if (isObservableArray(value) || Array.isArray(value)) {
        return stringifyObservable(value);
      } else if (
        isObservableObject(value) ||
        (typeof value === 'object' && value !== null)
      ) {
        return stringifyObservable(value);
      } else {
        if (value === null) {
          return 'null';
        }

        if (value === undefined) {
          return 'undefined';
        }
        return value.toString();
      }
    });
    return values.toString();
  } else if (
    isObservableObject(record) ||
    (typeof record === 'object' && record !== null)
  ) {
    const values = Object.values(record).map(value => {
      if (isObservableArray(value) || Array.isArray(value)) {
        return stringifyObservable(value);
      } else if (
        isObservableObject(value) ||
        (typeof value === 'object' && value !== null)
      ) {
        return stringifyObservable(value);
      } else {
        if (value === null) {
          return 'null';
        }

        if (value === undefined) {
          return 'undefined';
        }

        return value.toString();
      }
    });
    return values.toString();
  } else {
    return record.toString();
  }
};

export class SuggestionsStore {
  @observable filter = '';
  @observable searchFilter = '';

  constructor(rootStore) {
    this.rootStore = rootStore;
  }

  @computed
  get suggestions() {
    var suggestions = [];

    this.rootStore.clientStore.recordsById.forEach((client, id, map) => {
      const namespacedId = 'clients/' + client._id.$oid;
      suggestions.push({
        key: namespacedId,
        value: namespacedId,
        text: client.name,
        search: stringifyObservable(client).toLowerCase(),
        label: {
          color: CLIENT_DESCRIPTOR.colour.name,
          empty: true,
          circular: true
        }
      });
    });

    this.rootStore.partnershipStore.recordsById.forEach(
      (partnership, id, map) => {
        const namespacedId = 'partnerships/' + partnership._id.$oid;
        suggestions.push({
          key: namespacedId,
          value: namespacedId,
          text: partnership.name,
          search: stringifyObservable(partnership).toLowerCase(),
          label: {
            color: PARTNERSHIP_DESCRIPTOR.colour.name,
            empty: true,
            circular: true
          }
        });
      }
    );

    this.rootStore.ltdcoStore.recordsById.forEach((ltdco, id, map) => {
      const namespacedId = 'ltd_companies/' + ltdco._id.$oid;
      suggestions.push({
        key: namespacedId,
        value: namespacedId,
        text: ltdco.name,
        search: stringifyObservable(ltdco).toLowerCase(),
        label: {
          color: LTDCO_DESCRIPTOR.colour.name,
          empty: true,
          circular: true
        }
      });
    });

    this.rootStore.leadStore.recordsById.forEach((lead, id, map) => {
      const namespacedId = 'leads/' + lead._id.$oid;
      suggestions.push({
        key: namespacedId,
        value: namespacedId,
        text: lead.name,
        search: stringifyObservable(lead).toLowerCase(),
        label: {
          color: LEAD_DESCRIPTOR.colour.name,
          empty: true,
          circular: true
        }
      });
    });

    return suggestions.sort((a, b) => {
      const nameA = a.text.toLowerCase();
      const nameB = b.text.toLowerCase();
      if (nameA < nameB) {
        return -1;
      }

      /* istanbul ignore else */
      if (nameA > nameB) {
        return 1;
      }

      /* istanbul ignore next */
      return 0;
    });
  }

  @computed
  get suggestionsPending() {
    return (
      this.rootStore.clientStore.recordsPending ||
      this.rootStore.partnershipStore.recordsPending ||
      this.rootStore.ltdcoStore.recordsPending ||
      this.rootStore.leadStore.recordsPending
    );
  }

  @computed
  get filteredSuggestions() {
    const lowercaseFilter = this.filter.toLowerCase();
    return this.suggestions.filter(value => {
      return value.text.toLowerCase().includes(lowercaseFilter);
    });
  }

  @action.bound
  updateFilter(filter) {
    this.filter = filter;
  }

  @computed
  get filteredSearch() {
    const options = {
      shouldSort: true,
      includeMatches: true,
      threshold: 0.4,
      location: 0,
      distance: 1000,
      minMatchCharLength: 1,
      keys: [
        'name',
        'directors',
        'email',
        'tel',
        'mob',
        'directors.name',
        'directors.email',
        'directors.tel',
        'directors.mob',
        'reg_no'
      ]
    };
    const fuse = new Fuse(toJS(this.rootStore.allSearch), options);
    return fuse.search(this.searchFilter).map(result => ({
      key: result.item.__namespace,
      value: result.item.__namespace,
      text: result.item.__text,
      suffix: highlightMatch(result),
      label: result.item.__label,
      render: result.item.__render
    }));
  }

  @action.bound
  updateSearchFilter(filter) {
    this.searchFilter = filter;
  }
}
