import React, { Component } from 'react';
import {
  Icon,
  Grid,
  Container,
  Header,
  Input,
  Form,
  Button
} from 'semantic-ui-react';
import { rootStore } from '../mobx/RootStore';
import { observer } from 'mobx-react';

@observer
export default class QuickLinks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      editing: false,
      editedQuickLinks: null
    };
  }

  componentDidMount() {
    this.refreshQuickLinks();
  }

  refreshQuickLinks = () => {
    rootStore.quickLinks.refreshCollection();
  };

  updateQuickLinks = () => {
    rootStore.quickLinks.replace(
      this.state.editedQuickLinks,
      data => {},
      error => {
        this.setState({
          error: 'Failed to update quick links'
        });
      }
    );
  };

  render() {
    const pending = rootStore.quickLinks.quickLinksPending;

    return (
      <Container fluid>
        <Grid columns={16}>
          <Grid.Row columns={16}>
            <Grid.Column width={2}>
              <Icon
                link
                name="edit"
                onClick={(event, data) => {
                  this.setState({
                    editing: !this.state.editing,
                    editedQuickLinks: !this.state.editing
                      ? /* Automatically add one row for convenience */
                        JSON.parse(
                          JSON.stringify(rootStore.quickLinks.quickLinks)
                        ).concat({
                          name: '',
                          link: ''
                        })
                      : null
                  });
                }}
              />
            </Grid.Column>
            <Grid.Column width={12} textAlign="center">
              <Header>Quick Links</Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              {rootStore.homeUiStore.quicklinkMinimised ? (
                <Icon
                  link
                  name="window restore"
                  onClick={(event, data) => {
                    rootStore.homeUiStore.toggleQuickLinksVisibility();
                  }}
                />
              ) : (
                <Icon
                  link
                  name="window minimize"
                  onClick={(event, data) => {
                    rootStore.homeUiStore.toggleQuickLinksVisibility();
                  }}
                />
              )}
            </Grid.Column>
          </Grid.Row>
          {rootStore.homeUiStore.quicklinkMinimised &&
          !this.state.editing ? null : (
            <Grid.Row>
              <Grid.Column width={16}>
                {this.state.editing ? (
                  <div>
                    <Form>
                      {this.state.editedQuickLinks.map((link, index) => {
                        return (
                          <Form.Group key={index} inline widths="equal">
                            <Form.Field>
                              <Input
                                fluid
                                label="Name"
                                value={link.name}
                                onChange={(event, data) => {
                                  var editedLinks = [
                                    ...this.state.editedQuickLinks
                                  ];
                                  editedLinks[index].name = data.value;
                                  this.setState({
                                    editedQuickLinks: editedLinks
                                  });
                                }}
                              />
                            </Form.Field>
                            <Form.Field>
                              <Input
                                fluid
                                label="Link"
                                value={link.url}
                                onChange={(event, data) => {
                                  var editedLinks = [
                                    ...this.state.editedQuickLinks
                                  ];
                                  editedLinks[index].url = data.value;
                                  this.setState({
                                    editedQuickLinks: editedLinks
                                  });
                                }}
                              />
                            </Form.Field>
                            <Button
                              negative
                              icon
                              onClick={(event, data) => {
                                this.setState({
                                  editedQuickLinks: this.state.editedQuickLinks.filter(
                                    (link, linkindex) => {
                                      return linkindex !== index;
                                    }
                                  )
                                });
                              }}
                            >
                              <Icon name="trash" />
                            </Button>
                          </Form.Group>
                        );
                      })}
                    </Form>
                    <Grid columns={16}>
                      <Grid.Row>
                        <Grid.Column width={4} textAlign="left">
                          <Button
                            primary
                            icon
                            onClick={(event, data) => {
                              event.preventDefault();
                              this.setState({
                                editedQuickLinks: this.state.editedQuickLinks.concat(
                                  {
                                    name: '',
                                    link: ''
                                  }
                                )
                              });
                            }}
                          >
                            <Icon name="plus" />
                          </Button>
                        </Grid.Column>
                        <Grid.Column width={12} textAlign="right">
                          <Button
                            type="submit"
                            icon
                            positive
                            onClick={(event, data) => {
                              event.preventDefault();
                              this.setState({
                                editing: false
                              });
                              this.updateQuickLinks();
                            }}
                          >
                            <Icon name="checkmark" />
                          </Button>
                          <Button
                            icon
                            negative
                            onClick={(event, data) => {
                              event.preventDefault();
                              this.setState({
                                editing: false
                              });
                            }}
                          >
                            <Icon name="remove" />
                          </Button>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                ) : (
                  <div>
                    {!pending &&
                      rootStore.quickLinks.quickLinks.map(link => {
                        return (
                          <div key={link.name}>
                            <a href={link.url} target="_blank">
                              {link.name + ' '}
                              <Icon name="external" />
                            </a>
                            <br />
                          </div>
                        );
                      })}
                  </div>
                )}
              </Grid.Column>
            </Grid.Row>
          )}
        </Grid>
      </Container>
    );
  }
}
