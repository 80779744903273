import { observable, action } from 'mobx';
import StorageManager from '../../utils/StorageManager';

export class HomeUiStore {
  @observable activityMinimised = false;
  @observable quicklinkMinimised = false;

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.homeStorage = new StorageManager('homeUi', {
      activityMinimised: this.activityMinimised,
      quicklinkMinimised: this.quicklinkMinimised
    });

    const storedSettings = this.homeStorage.get();
    Object.keys(storedSettings).forEach((key, index) => {
      /* istanbul ignore else  */
      if (this.hasOwnProperty(key)) {
        this[key] = storedSettings[key];
      }
    });
  }

  @action.bound
  toggleActivityVisibility() {
    this.activityMinimised = !this.activityMinimised;
    this.homeStorage.put({
      activityMinimised: this.activityMinimised,
      quicklinkMinimised: this.quicklinkMinimised
    });
  }

  @action.bound
  toggleQuickLinksVisibility() {
    this.quicklinkMinimised = !this.quicklinkMinimised;
    this.homeStorage.put({
      activityMinimised: this.activityMinimised,
      quicklinkMinimised: this.quicklinkMinimised
    });
  }
}
