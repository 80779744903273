import React from 'react';
import { Dropdown, Form, Message, Segment } from 'semantic-ui-react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

import {
  VAT_STATUS_OPTIONS,
  VAT_SOURCE_OPTIONS,
  VAT_PERIOD_OPTIONS
} from '../../../types/enum-options';
import {
  VAT_PERIODS,
  getVATStatusColour,
  getVATStatus
} from '../../../utils/utils';
import { SelectAdapter, SelectField } from './SelectField';
import { CheckboxField } from './CheckboxField';
import { InputField } from './InputField';

export const IndividualVATStatusField = ({
  name,
  label,
  nolabel,
  period,
  ...props
}) => {
  const periodText = period.charAt(0).toUpperCase() + period.substr(1, 2);
  const yearText = period.substr(4, 4);
  return (
    <Field
      key={period}
      name={name}
      render={renderProps => {
        const value = renderProps.input.value
          ? { ...renderProps.input.value }
          : {
              status: 'Pending',
              comment: ''
            };
        return (
          <Form.Field
            error={!!(renderProps.meta.touched && renderProps.meta.error)}
            style={{
              backgroundColor: getVATStatusColour(value.status),
              textAlign: 'center',
              fontWeight: 'bold',
              textDecoration: 'underline',
              border: '1px solid rgba(34,36,38,.15)',
              paddingTop: '1em',
              paddingBottom: '1em'
            }}
          >
            {!nolabel && periodText + ' ' + yearText}
            {!nolabel && <br />}
            <Dropdown text={value.comment || value.status || 'Unknown'} compact>
              <Dropdown.Menu>
                <Dropdown.Header content="Comments" />
                <InputField
                  name={name + '.comment'}
                  /* This stops the dropdown from closing when
                  * focus is gained by the input */
                  onClick={e => e.stopPropagation()}
                />
                <Dropdown.Header content="Status" />
                <SelectField
                  name={name + '.status'}
                  options={VAT_STATUS_OPTIONS}
                />
              </Dropdown.Menu>
            </Dropdown>
            {renderProps.meta.touched && renderProps.meta.error ? (
              <Message negative>
                <Message.Header>{label} is invalid</Message.Header>
                {renderProps.meta.error.error}
              </Message>
            ) : null}
          </Form.Field>
        );
      }}
    />
  );
};

export const VATStatusField = ({ name, label, ...props }) => (
  <Field
    name={name}
    render={rootRenderProps => {
      return (
        <Segment>
          {label && (
            <label as="h5" style={{ fontWeight: 'bold', paddingBottom: '1em' }}>
              {label}
            </label>
          )}
          <br />
          <Form.Group widths="equal">
            <CheckboxField name={name + '.track'} label="Track" />
            <CheckboxField name={name + '.monthly'} label="Monthly" />
            <SelectField
              name={name + '.source'}
              label="Source"
              options={VAT_SOURCE_OPTIONS}
            />
            <SelectField
              name={name + '.period_type'}
              label="Period Type"
              options={VAT_PERIOD_OPTIONS}
            />
          </Form.Group>
          <Form.Group widths="equal">
            {VAT_PERIODS.map((period, index) => {
              const periodText =
                period.charAt(0).toUpperCase() + period.substr(1, 2);
              if (
                rootRenderProps.input.value.monthly ||
                (rootRenderProps.input.value.period_type &&
                  rootRenderProps.input.value.period_type.includes(periodText))
              ) {
                return (
                  <IndividualVATStatusField
                    key={period}
                    period={period}
                    name={name + '.submissions.' + period}
                  />
                );
              } else {
                return null;
              }
            })}
          </Form.Group>
        </Segment>
      );
    }}
  />
);

VATStatusField.propTypes = {
  name: PropTypes.string.isRequired
};
