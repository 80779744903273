import React from 'react';
import { Icon } from 'semantic-ui-react';
import { observable } from 'mobx';
import {
  dateAccessor,
  addressAccessor,
  lastModifiedAccessor,
  dateSort,
  lastModifiedSort,
  boolAccessor
} from '../../utils/utils';
import { TableUiStore } from './TableUiStore';

export class ContactUiStore extends TableUiStore {
  @observable
  sortBy = [
    {
      id: 'name',
      desc: false
    }
  ];

  @observable
  columns = [
    {
      id: 'created',
      Header: 'Created',
      accessor: dateAccessor('created'),
      sortMethod: dateSort,
      show: true
    },
    {
      Header: 'Name',
      accessor: 'name',
      show: true
    },
    {
      Header: 'Description',
      accessor: 'description',
      show: false
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      show: false
    },
    {
      id: 'address',
      Header: 'Address',
      accessor: addressAccessor,
      show: true
    },
    {
      id: 'public',
      Header: 'Public',
      accessor: boolAccessor('public'),
      show: false
    },
    {
      Header: 'Email',
      accessor: 'email',
      show: true,
      Cell: row => {
        return (
          <div>
            <span style={{ verticalAlign: 'middle' }}>{row.value}</span>
            {row.value && (
              <Icon
                name="at"
                link
                circular
                inverted
                color="grey"
                style={{ float: 'right' }}
                onClick={e => {
                  var newWindow = window.open('mailto:' + row.value, '_blank');
                  newWindow.focus();
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            )}
          </div>
        );
      }
    },
    {
      Header: 'Tel 1',
      accessor: 'tel_1',
      show: true
    },
    {
      Header: 'Tel 2',
      accessor: 'tel_2',
      show: false
    },
    {
      Header: 'Tel 3',
      accessor: 'tel_3',
      show: false
    },
    {
      Header: 'Owner',
      accessor: 'owner',
      show: false
    },
    {
      id: 'last_modified',
      Header: 'Last Modified',
      accessor: lastModifiedAccessor,
      sortMethod: lastModifiedSort,
      show: false
    }
  ];

  constructor(rootStore) {
    super(rootStore, 'contact');
  }
}
