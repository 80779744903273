import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import {
  Container,
  Divider,
  Icon,
  Menu,
  Segment,
  Grid,
  Sidebar,
  Image,
  Responsive
} from 'semantic-ui-react';

import _ from 'lodash';
import SessionManager from './SessionManager';
import SearchAll from './SearchAll';
import { observer } from 'mobx-react';
import { rootStore } from '../mobx/RootStore';
import logo from '../../public/images/LogoNoText.png';
import fullLogo from '../../public/images/SmallLogo.png';
import Login from './Login';

const MobileNavBar = ({
  children,
  leftGroup,
  centerGroup,
  rightGroup,
  onPusherClick,
  onToggle,
  visible
}) => (
  <Sidebar.Pushable>
    <Sidebar
      as={Menu}
      animation="overlay"
      icon="labeled"
      inverted
      items={leftGroup.concat(centerGroup)}
      vertical
      visible={visible}
    />
    <Sidebar.Pusher
      dimmed={visible}
      onClick={onPusherClick}
      style={{ minHeight: '100vh' }}
    >
      <Menu fixed="top" inverted>
        <Menu.Item as={Link} to="/">
          <Image size="mini" src={logo} />
        </Menu.Item>
        <Menu.Item onClick={onToggle}>
          <Icon name="sidebar" />
        </Menu.Item>
        <Menu.Menu position="right">
          {_.map(rightGroup, (item) => (
            <Menu.Item {...item} />
          ))}
        </Menu.Menu>
      </Menu>
      {children}
    </Sidebar.Pusher>
  </Sidebar.Pushable>
);

const StandardNavBar = ({ leftGroup, centerGroup, rightGroup }) => (
  <Menu fixed="top" inverted>
    <Menu.Item as={Link} to="/">
      <Image size="mini" src={logo} />
    </Menu.Item>
    {_.map(leftGroup, (item) => (
      <Menu.Item {...item} />
    ))}
    <Menu color="red" inverted>
      {_.map(centerGroup, (item) => (
        <Menu.Item {...item} />
      ))}
    </Menu>
    <Menu.Menu position="right">
      {_.map(rightGroup, (item) => (
        <Menu.Item {...item} />
      ))}
    </Menu.Menu>
  </Menu>
);

const NavBarChildren = ({ children }) => (
  <Container fluid style={{ marginTop: '5em' }}>
    {children}
  </Container>
);

class NavBar extends Component {
  state = {
    visible: false
  };

  handlePusher = () => {
    const { visible } = this.state;

    if (visible) {
      this.setState({ visible: false });
    }
  };

  handleToggle = () => this.setState({ visible: !this.state.visible });

  render() {
    const { children, leftGroup, centerGroup, rightGroup } = this.props;
    const { visible } = this.state;

    return (
      <div>
        <Responsive maxWidth={Responsive.onlyLargeScreen.minWidth}>
          <MobileNavBar
            leftGroup={leftGroup}
            centerGroup={centerGroup}
            rightGroup={rightGroup}
            onPusherClick={this.handlePusher}
            onToggle={this.handleToggle}
            visible={visible}
          >
            <NavBarChildren>{children}</NavBarChildren>
          </MobileNavBar>
        </Responsive>
        <Responsive minWidth={Responsive.onlyLargeScreen.minWidth}>
          <StandardNavBar
            leftGroup={leftGroup}
            centerGroup={centerGroup}
            rightGroup={rightGroup}
          />
          <NavBarChildren>{children}</NavBarChildren>
        </Responsive>
      </div>
    );
  }
}

@observer
class Layout extends Component {
  render() {
    const { location } = this.props;
    var leftGroup = [
      {
        as: Link,
        size: 'tiny',
        name: 'home',
        key: 'home',
        to: '/',
        active: location.pathname === '/'
      }
    ];
    var centerGroup = [];
    var rightGroup = [
      {
        content: <SessionManager />,
        size: 'tiny',
        key: 'session'
      }
    ];

    if (rootStore.sessionStore.loggedIn) {
      leftGroup = leftGroup.concat([
        {
          as: Link,
          size: 'tiny',
          name: 'clients',
          key: 'clients',
          to: '/clients',
          active: location.pathname.startsWith('/clients')
        },
        {
          as: Link,
          size: 'tiny',
          name: 'ltd_companies',
          key: 'ltd_companies',
          to: '/ltd_companies',
          active: location.pathname.startsWith('/ltd_companies')
        },
        {
          as: Link,
          size: 'tiny',
          name: 'partnerships',
          key: 'partnerships',
          to: '/partnerships',
          active: location.pathname.startsWith('/partnerships')
        },
        {
          as: Link,
          size: 'tiny',
          name: 'deadlines',
          key: 'deadlines',
          to: '/deadlines',
          active: location.pathname.startsWith('/deadlines')
        },
        {
          as: Link,
          size: 'tiny',
          name: 'reports',
          key: 'reports',
          to: '/reports',
          active: location.pathname.startsWith('/reports')
        },
        {
          as: Link,
          content: <p>VAT</p>,
          size: 'tiny',
          name: 'vat',
          key: 'vat',
          to: '/vat',
          active: location.pathname.startsWith('/vat')
        }
      ]);

      if (rootStore.sessionStore.privs.includes('ReadLeads')) {
        centerGroup = centerGroup.concat([
          {
            as: Link,
            content: <p>99p Registrations</p>,
            size: 'tiny',
            name: '99p Registrations',
            key: 'leads',
            to: '/leads',
            active: location.pathname.startsWith('/leads')
          }
        ]);
      }

      if (rootStore.sessionStore.privs.includes('ConfigureUsers')) {
        leftGroup.push({
          as: Link,
          size: 'tiny',
          name: 'users',
          key: 'users',
          to: '/users',
          active: location.pathname.startsWith('/users')
        });
      }

      if (rootStore.sessionStore.privs.includes('ConfigureAdmin')) {
        leftGroup.push({
          as: Link,
          size: 'tiny',
          name: 'admin',
          key: 'admin',
          to: '/admin',
          active: location.pathname.startsWith('/admin')
        });
      }

      rightGroup.unshift(
        {
          as: Link,
          size: 'tiny',
          name: 'contacts',
          key: 'contacts',
          to: '/contacts',
          content: <Icon name="address card" size="large" />
        },
        {
          content: <SearchAll history={this.props.history} />,
          key: 'search'
        }
      );
    }

    return (
      <NavBar
        leftGroup={leftGroup}
        centerGroup={centerGroup}
        rightGroup={rightGroup}
      >
        <Grid>
          <Grid.Row columns={16}>
            <Grid.Column width={1} />
            <Grid.Column width={14}>
              {rootStore.sessionStore.loggedIn ? (
                <Segment>{this.props.children}</Segment>
              ) : (
                <Segment style={{ display: 'flex', justifyContent: 'center' }}>
                  <Login />
                </Segment>
              )}
              <Divider />
            </Grid.Column>
            <Grid.Column width={1} />
          </Grid.Row>
          <Grid.Row columns={16}>
            <Grid.Column width={1} />
            <Grid.Column width={14} verticalAlign="top" textAlign="center">
              {process.env.VERSION + ' '}
              <Icon name="copyright" /> 2020 Mark Walton
              <Image size="small" src={fullLogo} centered />
            </Grid.Column>
            <Grid.Column width={1} />
          </Grid.Row>
        </Grid>
      </NavBar>
    );
  }
}

export default withRouter(Layout);
