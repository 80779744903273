import { needData } from '../utils/utils';
import { SIC_CODES } from '../utils/sic_codes';

export const STYLE_OPTIONS = [
  { key: 0, text: 'Unknown', value: 'Unknown' },
  { key: 1, text: 'Personal', value: 'Personal' },
  { key: 2, text: 'Book Keeping', value: 'Book Keeping' },
  { key: 3, text: 'Sole Trader', value: 'Sole Trader' },
  { key: 4, text: 'Partnership', value: 'Partnership' },
  { key: 5, text: 'Limited Company', value: 'Limited Company' },
  { key: 6, text: 'PAYE', value: 'PAYE' },
  { key: 7, text: 'Director', value: 'Director' },
  { key: 8, text: 'Partner', value: 'Partner' },
  { key: 9, text: 'Property', value: 'Property' },
  { key: 10, text: 'Capital Gains', value: 'Capital Gains' },
  { key: 11, text: 'Accountant', value: 'Accountant' },
  { key: 12, text: 'Deceased', value: 'Deceased' },
  { key: 13, text: 'Trust', value: 'Trust' }
];

export const AML_STATUS_OPTIONS = [
  { key: 0, text: 'Pending', value: 'Pending' },
  { key: 1, text: 'Verified Electronically', value: 'Verified Electronically' },
  { key: 2, text: 'Verified At Home', value: 'Verified At Home' },
  { key: 3, text: 'Verified Manually', value: 'Verified Manually' },
  { key: 4, text: 'Failed', value: 'Failed' },
  { key: 5, text: 'Disengaged', value: 'Disengaged' },
  { key: 6, text: 'Not Required', value: 'Not Required' }
];

export const AML_RISK_OPTIONS = [
  { key: 0, text: 'Pending', value: 'Pending' },
  { key: 1, text: 'High', value: 'High' },
  { key: 2, text: 'Medium', value: 'Medium' },
  { key: 3, text: 'Low', value: 'Low' }
];

export const TAX_STATUS_OPTIONS = [
  { key: 0, text: 'Unknown', value: 'Unknown' },
  { key: 1, text: 'Pending', value: 'Pending' },
  { key: 2, text: 'Submitted', value: 'Submitted' },
  { key: 3, text: 'Not Required', value: 'Not Required' },
  { key: 4, text: 'De-register', value: 'De-register' }
];

export const VAT_STATUS_OPTIONS = [
  { key: -1, text: '', value: null },
  { key: 0, text: 'Unknown', value: 'Unknown' },
  { key: 1, text: 'Pending', value: 'Pending' },
  { key: 2, text: 'Submitted', value: 'Submitted' },
  { key: 3, text: 'Not Required', value: 'Not Required' },
  { key: 4, text: 'Adjustment', value: 'Adjustment' }
];

export const VAT_SOURCE_OPTIONS = [
  { key: -1, text: '', value: null },
  { key: 0, text: 'Email', value: 'Email' },
  { key: 1, text: 'QuickBooks', value: 'QuickBooks' },
  { key: 2, text: 'Xero', value: 'Xero' }
];

export const VAT_PERIOD_OPTIONS = [
  { key: -1, text: '', value: null },
  { key: 0, text: 'Jan, Apr, Jul, Oct', value: 'Jan, Apr, Jul, Oct' },
  { key: 1, text: 'Feb, May, Aug, Nov', value: 'Feb, May, Aug, Nov' },
  { key: 2, text: 'Mar, Jun, Sep, Dec', value: 'Mar, Jun, Sep, Dec' }
];

export const COMPANY_STATUS_OPTIONS = [
  { key: 0, text: 'Unknown', value: 'Unknown' },
  { key: 1, text: 'Pending', value: 'Pending' },
  { key: 2, text: 'Active', value: 'Active' },
  { key: 3, text: 'Dissolved', value: 'Dissolved' },
  { key: 4, text: 'Dormant', value: 'Dormant' },
  { key: 5, text: 'Disengaged', value: 'Disengaged' }
];

export const PRIVILEGE_OPTIONS = [
  { key: 0, text: 'Login', value: 'Login' },
  { key: 1, text: 'Configure Users', value: 'ConfigureUsers' },
  { key: 2, text: 'Configure Clients', value: 'ConfigureClients' },
  { key: 3, text: 'Configure Partnerships', value: 'ConfigurePartnerships' },
  { key: 4, text: 'Configure LtdCompanies', value: 'ConfigureLtdCompanies' },
  { key: 5, text: 'Configure Notes', value: 'ConfigureNotes' },
  { key: 6, text: 'Configure Fees', value: 'ConfigureFees' },
  { key: 7, text: 'Configure Self', value: 'ConfigureSelf' },
  { key: 8, text: 'Configure Admin', value: 'ConfigureAdmin' },
  { key: 9, text: 'Read Leads', value: 'ReadLeads' },
  { key: 10, text: 'Configure Leads', value: 'ConfigureLeads' },
  { key: 11, text: 'View Financials', value: 'ViewFinancials' }
];

export const TITLE_OPTIONS = [
  { key: 0, text: '', value: '' },
  { key: 1, text: 'Mr', value: 'Mr' },
  { key: 2, text: 'Mrs', value: 'Mrs' },
  { key: 3, text: 'Miss', value: 'Miss' },
  { key: 4, text: 'Ms', value: 'Ms' },
  { key: 5, text: 'Master', value: 'Master' },
  { key: 6, text: 'Mx', value: 'Mx' },
  { key: 7, text: 'Dr', value: 'Dr' }
];

export const FEE_FREQUENCY_OPTIONS = [
  { key: 0, value: 'Anually', text: 'Anually' },
  { key: 1, value: 'Monthly', text: 'Monthly' },
  { key: 2, value: 'One Off', text: 'One Off' }
];

export const NOTE_STATE_OPTIONS = [
  { key: 0, value: 'New', text: 'New' },
  { key: 1, value: 'Open', text: 'Open' },
  { key: 2, value: 'In Progress', text: 'In Progress' },
  { key: 3, value: 'Complete', text: 'Complete' },
  { key: 4, value: 'No Work', text: 'No Work' }
];

export const FIELD_STATUS_OPTIONS = [
  { key: 0, value: 'Not Required', text: 'Not Required' },
  { key: 1, value: 'Work Required', text: 'Work Required' },
  { key: 2, value: 'Awaiting Data', text: 'Awaiting Data' },
  {
    key: 3,
    value: 'Client Information Required',
    text: 'Client Information Required'
  },
  {
    key: 4,
    value: 'OK',
    text: 'OK'
  }
];

export const BANKING_PREF_OPTIONS = [
  { key: -1, value: null, text: '' },
  { key: 0, value: 'None', text: 'None' },
  { key: 1, value: 'Barclays', text: 'Barclays' },
  { key: 2, value: 'Lloyds', text: 'Lloyds' },
  { key: 3, value: 'CardOne', text: 'Card One' },
  { key: 4, value: 'WorldPay', text: 'World Pay' },
  { key: 5, value: 'CashPlus', text: 'Cash Plus' },
  { key: 6, value: 'Tide', text: 'Tide' },
  { key: 7, value: 'TSB Business Banking', text: 'TSB Business Banking' },
  { key: 7, value: 'TSBBusinessing', text: 'TSB Business Banking' },
  { key: 8, value: 'Mettle', text: 'Mettle' },
  { key: 9, value: 'Starling', text: 'Starling' },
  { key: 10, value: 'Metro', text: 'Metro' },
  { key: 11, value: 'ANNA', text: 'ANNA' },
  { key: 12, value: 'Countingup', text: 'Countingup' },
  { key: 13, value: 'Natwest', text: 'Natwest' },
  { key: 14, value: 'Monzo', text: 'Monzo' }
];

export const SPREADSHEET_PREF_OPTIONS = [
  { key: -1, value: null, text: '' },
  { key: 0, value: 'None', text: 'None' },
  { key: 1, value: 'Standard', text: 'Standard' },
  { key: 2, value: 'VAT', text: 'VAT' }
];

export const PRIORITY_OPTIONS = [
  {
    key: 0,
    value: 'None',
    text: 'None',
    label: { color: 'grey', empty: true, circular: true }
  },
  {
    key: 1,
    value: 'Low',
    text: 'Low',
    label: { color: 'green', empty: true, circular: true }
  },
  {
    key: 2,
    value: 'Medium',
    text: 'Medium',
    label: { color: 'orange', empty: true, circular: true }
  },
  {
    key: 3,
    value: 'High',
    text: 'High',
    label: { color: 'red', empty: true, circular: true }
  }
];

export const prioToValue = prio => {
  switch (prio) {
    case undefined:
    case null:
    case 'None':
      return 0;

    case 'Low':
      return 1;

    case 'Medium':
      return 2;

    case 'High':
      return 3;
  }

  return -1;
};

export const FIELD_STATUS_REQUIRING_WORK = () => {
  var filtered = FIELD_STATUS_OPTIONS.filter(item => {
    return needData(item.value);
  });

  filtered.unshift({ key: 98, value: 'Pending', text: 'Pending' });
  filtered.unshift({ key: 99, value: 'All', text: 'All' });
  return filtered;
};

export const SIC_OPTIONS = Object.keys(SIC_CODES).map(key => ({
  key,
  text: key + ' - ' + SIC_CODES[key],
  value: key
}));

export const TODO_SORT_BY_OPTIONS = [
  { key: 1, value: 'priority', text: 'Priority' },
  { key: 2, value: 'deadline', text: 'Deadline' },
  { key: 3, value: 'created', text: 'Created' }
];
