import { action, computed } from 'mobx';
import { Record, RecordStore } from '../records/RecordStore';

export class Partnership extends Record {
  constructor(id, rootStore) {
    super(id, rootStore);
    this.collectionName = 'partnerships';
    this.get();
  }
}

export class PartnershipStore extends RecordStore {
  constructor(rootStore) {
    super(rootStore);
    this.collectionName = 'partnerships';
  }

  @action
  get(id) {
    this.record = new Partnership(id, this.rootStore);
  }

  @computed
  get needAmlStatus() {
    return [];
  }
}
