import { observable, action } from 'mobx';
import StorageManager from '../../utils/StorageManager';
import moment from 'moment';
import {
  DEADLINE_TASK_COLOUR,
  UNKNOWN_COLOUR,
  DEADLINE_REF_DATE_COLOUR,
  DEADLINE_FILING_COLOUR,
  LTDCO_COLOUR,
  DEADLINE_PERIOD_END_COLOUR,
  CLIENT_COLOUR,
  LEAD_COLOUR
} from '../../utils/theme';

export const DEADLINE_OPTIONS = [
  {
    theme: DEADLINE_TASK_COLOUR,
    name: 'enable_tasks',
    title: 'Show Tasks'
  },
  {
    theme: DEADLINE_REF_DATE_COLOUR,
    name: 'enable_refdate',
    title: 'Show Reference Date'
  },
  {
    theme: DEADLINE_FILING_COLOUR,
    name: 'enable_filing',
    title: 'Show Filing Deadlines'
  },
  {
    theme: DEADLINE_PERIOD_END_COLOUR,
    name: 'enable_qend',
    title: 'Show Quarter End'
  },
  {
    theme: LTDCO_COLOUR,
    name: 'enable_return',
    title: 'Show Annual Return'
  },
  {
    theme: LEAD_COLOUR,
    name: 'enable_leads',
    title: 'Show Lead Deadlines'
  },
  {
    theme: UNKNOWN_COLOUR,
    name: 'enable_todos',
    title: 'Show Todos'
  }
];

export class DeadlinesUiStore {
  @observable
  deadlines = {
    fromDate: moment()
      .startOf('day')
      .format('YYYY-MM-DD'),
    toDate: moment()
      .add(2, 'M')
      .endOf('day')
      .format('YYYY-MM-DD'),
    enable_tasks: true,
    enable_refdate: true,
    enable_filing: true,
    enable_qend: true,
    enable_return: true,
    enable_leads: true,
    enable_todos: true,
    active_view: 0,
    calendar_view: 'month'
  };

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.uiStorage = new StorageManager('deadlinesUi', this.deadlines);

    const storedUi = this.uiStorage.get();
    Object.keys(storedUi).forEach((key, index) => {
      /* istanbul ignore else  */
      if (this.deadlines.hasOwnProperty(key)) {
        this.deadlines[key] = storedUi[key];
      }
    });
  }

  @action.bound
  setDate(field, date) {
    this.deadlines[field] = date;
    this.uiStorage.put(this.deadlines);
  }

  @action.bound
  setValue(field, value) {
    this.deadlines[field] = value;
    this.uiStorage.put(this.deadlines);
  }
}
