import { observable, action } from 'mobx';
import WaccDbApi from '../../api/WaccDbApi';

export class TodoStore {
  @observable todos = [];
  @observable todosPending = false;

  constructor(rootStore) {
    this.rootStore = rootStore;

    this.collectionLastModified = null;
  }

  getTodos = () => {
    this.refreshCollection();
    return this.todos;
  };

  @action
  refreshCollection() {
    this.todosPending = true;
    const call = WaccDbApi.get(
      '/todos?$expand=1',
      this.fetchTodosSuccess,
      this.fetchTodosError,
      [
        {
          header: 'If-Modified-Since',
          value: this.collectionLastModified
        }
      ]
    );
  }

  @action.bound
  fetchTodosSuccess(data) {
    this.todosPending = false;
    if (data.status != 304) {
      this.todos = data.json.Members;
      this.collectionLastModified = data.headers.get('Last-Modified');
    }
  }

  @action.bound
  fetchTodosError(error) {
    this.todosPending = false;
  }

  deleteTodo = (id, onSuccess, onFailure, batch = false) => {
    return WaccDbApi.delete(
      '/todos/' + id,
      data => {
        if (!batch) {
          this.refreshCollection();
        }
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  createTodo = (todo, onSuccess, onFailure) => {
    return WaccDbApi.post(
      '/todos',
      todo,
      data => {
        this.refreshCollection();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  updateTodo = (todo, onSuccess, onFailure) => {
    return WaccDbApi.patch(
      '/todos/' + todo._id.$oid,
      todo,
      data => {
        this.refreshCollection();
        onSuccess(data);
      },
      error => {
        onFailure(error);
      }
    );
  };

  deleteCompleted = () => {
    this.todos.forEach(todo => {
      if (todo.completed) {
        this.deleteTodo(todo._id.$oid, data => {}, error => {}, true);
      }
    });

    this.refreshCollection();
  };
}
