import { observable, computed, action } from 'mobx';
import WaccDbApi from '../../api/WaccDbApi';

const PAGE_SIZE = 10;

export class ActivityStore {
  @observable activity = [];
  @observable activityPending = false;
  @observable pageOffset = 0;
  @observable pageSize = PAGE_SIZE;
  @observable firstPage = true;
  @observable lastPage = false;
  @observable total = 0;

  constructor(rootStore, pageSize = PAGE_SIZE) {
    this.rootStore = rootStore;
    this.pageSize = pageSize;
  }

  getActivity = () => {
    this.refreshPage();
    return this.activity;
  };

  @action
  refreshPage() {
    this.activityPending = true;
    const call = WaccDbApi.get(
      '/activity?$expand=1&$dir=descending&$top=' +
        this.pageSize.toString() +
        '&$skip=' +
        this.pageOffset.toString(),
      this.fetchActivitySuccess,
      this.fetchActivityError
    );
  }

  @action.bound
  fetchActivitySuccess(data) {
    this.activityPending = false;
    this.activity = data.json.Members;
    this.total = data.json['Members@odata.count'];
    this.firstPage = this.pageOffset == 0 ? true : false;
    this.lastPage = this.pageOffset + this.pageSize >= this.total;
  }

  @action.bound
  fetchActivityError(error) {
    this.activityPending = false;
  }

  @action.bound
  updatePage(offset) {
    if (offset != this.pageOffset) {
      if (offset < 0) {
        offset = 0;
      }
      this.pageOffset = offset;
      this.firstPage = offset == 0 ? true : false;
      this.lastPage = this.pageOffset + this.pageSize >= this.total;
    }

    this.refreshPage();
  }

  @action.bound
  setPage(page) {
    this.updatePage((page - 1) * this.pageSize);
  }

  prevPage = () => {
    var offset = this.pageOffset - this.pageSize;
    if (offset < 0) {
      offset = 0;
    }

    this.updatePage(offset);
  };

  nextPage = () => {
    var offset = this.pageOffset + this.pageSize;
    if (offset > this.total) {
      offset = this.total;
    }

    this.updatePage(offset);
  };

  @computed
  get pageLimit() {
    return this.pageOffset + this.pageSize >= this.total
      ? this.total
      : this.pageOffset + this.pageSize;
  }

  @computed
  get pageCount() {
    return parseInt((this.total ? this.total - 1 : 0) / this.pageSize + 1);
  }
}
