import React from 'react';
import { toJS, configure, action, computed, observable } from 'mobx';
import { ClientStore } from './clients/ClientStore';
import { LtdCoStore } from './ltd_companies/LtdCoStore';
import { PartnershipStore } from './partnerships/PartnershipStore';
import { SuggestionsStore } from './ui/SuggestionsStore';
import { UserStore } from './users/UserStore';
import { ClientUiStore } from './ui/ClientUiStore';
import { LtdcoUiStore } from './ui/LtdcoUiStore';
import { PartnershipUiStore } from './ui/PartnershipUiStore';
import { UserUiStore } from './ui/UserUiStore';
import { SessionStore } from './ui/SessionStore';
import { HomeUiStore } from './ui/HomeUiStore';
import { QuickLinksStore } from './quicklinks/QuickLinksStore';
import { NoteStore } from './notes/NoteStore';
import { DeadlinesUiStore } from './ui/DeadlinesUiStore';
import { ActivityStore } from './activity/ActivityStore';
import { TodoStore } from './todos/TodoStore';
import { TodoUiStore } from './ui/TodoUiStore';
import { Icon } from 'semantic-ui-react';
import {
  CLIENT_DESCRIPTOR,
  LTDCO_DESCRIPTOR,
  PARTNERSHIP_DESCRIPTOR,
  LEAD_DESCRIPTOR,
  CONTACT_DESCRIPTOR
} from '../types/descriptors';
import { needData, needAmlStatus, splitCollectionAndId } from '../utils/utils';
import { LeadStore } from './leads/LeadStore';
import { LeadUiStore } from './ui/LeadUiStore';
import { FilterStore } from './filters/FilterStore';
import { ContactStore } from './contacts/ContactStore';
import { ContactUiStore } from './ui/ContactUiStore';
import { VATSchedulerUiStore } from './ui/VATSchedulerUiStore';
import { VatStore } from './VatStore';
import { getCollectionTheme, UNKNOWN_COLOUR } from '../utils/theme';

configure({
  enforceActions: true
});

export class RootStore {
  @observable searchInactive = false;

  constructor() {
    this.clientStore = new ClientStore(this);
    this.leadStore = new LeadStore(this);
    this.ltdcoStore = new LtdCoStore(this);
    this.partnershipStore = new PartnershipStore(this);
    this.suggestionsStore = new SuggestionsStore(this);
    this.userStore = new UserStore(this);
    this.clientUiStore = new ClientUiStore(this);
    this.leadUiStore = new LeadUiStore(this);
    this.homeUiStore = new HomeUiStore(this);
    this.ltdcoUiStore = new LtdcoUiStore(this);
    this.partnershipUiStore = new PartnershipUiStore(this);
    this.userUiStore = new UserUiStore(this);
    this.sessionStore = new SessionStore(this);
    this.quickLinks = new QuickLinksStore(this);
    this.noteStore = new NoteStore(this);
    this.deadlinesUiStore = new DeadlinesUiStore(this);
    this.activityStore = new ActivityStore(this);
    this.todoStore = new TodoStore(this);
    this.todoUiStore = new TodoUiStore(this);
    this.filterStore = new FilterStore(this);
    this.contactStore = new ContactStore(this);
    this.contactUiStore = new ContactUiStore(this);
    this.vatSchedulerUiStore = new VATSchedulerUiStore(this);
    this.vatStore = new VatStore(this);
  }

  @action
  refreshAll() {
    this.clientStore.refreshCollection();
    this.ltdcoStore.refreshCollection();
    this.partnershipStore.refreshCollection();
    this.userStore.refreshCollection();
    this.leadStore.refreshCollection();
    this.noteStore.refreshCollection();
    this.contactStore.refreshCollection();
  }

  name = (collection, id) => {
    switch (collection) {
      case 'clients':
        if (!this.clientStore.recordsPending) {
          return computed(() => this.clientStore.name(id)).get();
        }
        break;
      case 'ltd_companies':
        if (!this.ltdcoStore.recordsPending) {
          return computed(() => this.ltdcoStore.name(id)).get();
        }
        break;
      case 'partnerships':
        if (!this.partnershipStore.recordsPending) {
          return computed(() => this.partnershipStore.name(id)).get();
        }
        break;
      case 'leads':
        if (!this.leadStore.recordsPending) {
          return computed(() => this.leadStore.name(id)).get();
        }
        break;
      case 'users':
        if (!this.userStore.recordsPending) {
          return computed(() => this.userStore.getDisplayName(id)).get();
        }
        break;
      case 'quick_urls':
        return computed(() => 'Quick Links').get();
    }

    return computed(() => 'Unknown').get();
  };

  @computed
  get needUtr() {
    var recordsNeedingUTR = [];

    recordsNeedingUTR = this.clientStore.records.reduce((arr, item) => {
      if (!item.active) {
        return arr;
      }
      if (needData(item.utr_status)) {
        arr.push({
          descriptor: CLIENT_DESCRIPTOR,
          id: item._id.$oid,
          name: item.name,
          status: item.utr_status
        });
      }
      return arr;
    }, recordsNeedingUTR);

    recordsNeedingUTR = this.ltdcoStore.records.reduce((arr, item) => {
      if (!item.active) {
        return arr;
      }
      if (needData(item.utr_status)) {
        arr.push({
          descriptor: LTDCO_DESCRIPTOR,
          id: item._id.$oid,
          name: item.name,
          status: item.utr_status
        });
      }
      return arr;
    }, recordsNeedingUTR);

    recordsNeedingUTR = this.partnershipStore.records.reduce((arr, item) => {
      if (!item.active) {
        return arr;
      }
      if (needData(item.utr_status)) {
        arr.push({
          descriptor: PARTNERSHIP_DESCRIPTOR,
          id: item._id.$oid,
          name: item.name,
          status: item.utr_status
        });
      }
      return arr;
    }, recordsNeedingUTR);

    return recordsNeedingUTR;
  }

  @computed
  get needAgentAuthority() {
    var recordsNeedingAgentAuth = [];

    recordsNeedingAgentAuth = this.clientStore.records.reduce((arr, item) => {
      if (!item.active) {
        return arr;
      }
      if (needData(item.agent_authority_status)) {
        arr.push({
          descriptor: CLIENT_DESCRIPTOR,
          id: item._id.$oid,
          name: item.name,
          status: item.agent_authority_status
        });
      }
      return arr;
    }, recordsNeedingAgentAuth);

    recordsNeedingAgentAuth = this.ltdcoStore.records.reduce((arr, item) => {
      if (!item.active) {
        return arr;
      }
      if (needData(item.agent_authority_status)) {
        arr.push({
          descriptor: LTDCO_DESCRIPTOR,
          id: item._id.$oid,
          name: item.name,
          status: item.agent_authority_status
        });
      }
      return arr;
    }, recordsNeedingAgentAuth);

    recordsNeedingAgentAuth = this.partnershipStore.records.reduce(
      (arr, item) => {
        if (!item.active) {
          return arr;
        }
        if (needData(item.agent_authority_status)) {
          arr.push({
            descriptor: PARTNERSHIP_DESCRIPTOR,
            id: item._id.$oid,
            name: item.name,
            status: item.agent_authority_status
          });
        }
        return arr;
      },
      recordsNeedingAgentAuth
    );

    return recordsNeedingAgentAuth;
  }

  @computed
  get needAmlStatus() {
    var recordsNeedingAmlStatus = [];

    recordsNeedingAmlStatus = this.clientStore.records.reduce((arr, item) => {
      if (!item.active) {
        return arr;
      }
      if (needAmlStatus(item.aml_status)) {
        arr.push({
          descriptor: CLIENT_DESCRIPTOR,
          id: item._id.$oid,
          name: item.name,
          status: item.aml_status
        });
      }
      return arr;
    }, recordsNeedingAmlStatus);

    return recordsNeedingAmlStatus;
  }

  @computed
  get needVatNo() {
    var recordsNeedingVatNo = [];
    recordsNeedingVatNo = this.clientStore.records.reduce((arr, item) => {
      if (!item.active) {
        return arr;
      }
      if (needData(item.vatno_status)) {
        arr.push({
          descriptor: CLIENT_DESCRIPTOR,
          id: item._id.$oid,
          name: item.name,
          status: item.aml_status
        });
      }
      return arr;
    }, recordsNeedingVatNo);

    recordsNeedingVatNo = this.ltdcoStore.records.reduce((arr, item) => {
      if (!item.active) {
        return arr;
      }
      if (needData(item.vatno_status)) {
        arr.push({
          descriptor: LTDCO_DESCRIPTOR,
          id: item._id.$oid,
          name: item.name,
          status: item.aml_status
        });
      }
      return arr;
    }, recordsNeedingVatNo);

    return recordsNeedingVatNo;
  }

  find = (id) => {
    var record = this.clientStore.recordsById.get(id);
    if (record) {
      return {
        collection: 'clients',
        record
      };
    }

    record = this.ltdcoStore.recordsById.get(id);
    if (record) {
      return {
        collection: 'ltd_companies',
        record
      };
    }

    record = this.partnershipStore.recordsById.get(id);
    if (record) {
      return {
        collection: 'partnerships',
        record
      };
    }

    return null;
  };

  @computed
  get all() {
    const clients = this.clientStore.records
      ? this.clientStore.records.map((item) => ({
          ...item,
          __namespace: 'clients/' + item._id.$oid,
          __text: item.name,
          __label: {
            color: CLIENT_DESCRIPTOR.colour.name,
            empty: true,
            circular: true
          }
        }))
      : [];
    const partnerships = this.partnershipStore.records
      ? this.partnershipStore.records.map((item) => ({
          ...item,
          __namespace: 'partnerships/' + item._id.$oid,
          __text: item.name,
          __label: {
            color: PARTNERSHIP_DESCRIPTOR.colour.name,
            empty: true,
            circular: true
          }
        }))
      : [];
    const ltdcos = this.ltdcoStore.records
      ? this.ltdcoStore.records.map((item) => ({
          ...item,
          __namespace: 'ltd_companies/' + item._id.$oid,
          __text: item.name,
          __label: {
            color: LTDCO_DESCRIPTOR.colour.name,
            empty: true,
            circular: true
          }
        }))
      : [];
    const leads = this.leadStore.records
      ? this.leadStore.records.map((item) => ({
          ...{
            ...item,
            address: toJS(item.address),
            operating_address: toJS(item.operating_address),
            directors: toJS(item.directors)
          },
          __namespace: 'leads/' + item._id.$oid,
          __text: item.name,
          __label: {
            color: LEAD_DESCRIPTOR.colour.name,
            empty: true,
            circular: true
          }
        }))
      : [];
    const contacts = this.contactStore.records
      ? this.contactStore.records.map((item) => ({
          ...item,
          __namespace: 'contacts/' + item._id.$oid,
          __text: item.name,
          __label: {
            color: CONTACT_DESCRIPTOR.colour.name,
            empty: true,
            circular: true
          }
        }))
      : [];
    const todos = this.todoStore.todos
      ? this.todoStore.todos.map((item) => {
          var namespacedId = 'todo/' + item._id.$oid;
          var entity = {
            id: '',
            collection: ''
          };
          var theme = UNKNOWN_COLOUR;
          /* istanbul ignore else */
          if (item.related_entity) {
            namespacedId = item.related_entity;
            entity = splitCollectionAndId(namespacedId);
            theme = getCollectionTheme(entity ? entity.collection : null);
            namespacedId += '#todo-' + item._id.$oid;
          }
          var text = '';
          if (item.notes) {
            text += item.notes;
          }

          /* istanbul ignore else */
          if (item.tasks) {
            text += ', ';
            const tasks = item.tasks.map((task) => task.text);
            text += tasks.join(', ');
          }
          return {
            ...item,
            __namespace: namespacedId,
            text: text,
            render: () => {
              return (
                <div style={{ display: 'inline' }}>
                  <Icon name="list" />(
                  {this.name(
                    entity ? entity.collection : null,
                    entity ? entity.id : null
                  )}
                  ) -{text.substring(0, 16) + (text.length > 16 ? '...' : '')}
                </div>
              );
            },
            label: {
              color: theme.name,
              empty: true,
              circular: true
            }
          };
        })
      : [];
    const notes = this.noteStore.notes
      ? this.noteStore.notes.map((item) => {
          var namespacedId = 'note/' + item._id.$oid;
          var entity = {
            id: '',
            collection: ''
          };
          var theme = UNKNOWN_COLOUR;
          if (item.related_entity) {
            namespacedId = item.related_entity.replace(
              /\/waccdb\/v1\/(.*)/g,
              '$1'
            );
            entity = splitCollectionAndId(namespacedId);
            theme = getCollectionTheme(entity ? entity.collection : null);
            namespacedId += '#note-' + item._id.$oid;
          }
          return {
            ...item,
            __namespace: namespacedId,
            __text: item.note,
            __render: () => {
              return (
                <div style={{ display: 'inline' }}>
                  <Icon name="sticky note" />(
                  {this.name(
                    entity ? entity.collection : null,
                    entity ? entity.id : null
                  )}
                  ) -
                  {item.note.substring(0, 16) +
                    (item.note.length > 16 ? '...' : '')}
                </div>
              );
            },
            __label: {
              color: theme.name,
              empty: true,
              circular: true
            }
          };
        })
      : [];

    return clients.concat(partnerships, ltdcos, leads, contacts, todos, notes);
  }

  @computed
  get allSearch() {
    const clients = this.clientStore.records
      ? this.clientStore.records
          .filter((item) => this.searchInactive || item.active)
          .map((item) => ({
            ...item,
            __namespace: 'clients/' + item._id.$oid,
            __text: item.name,
            __label: {
              color: CLIENT_DESCRIPTOR.colour.name,
              empty: true,
              circular: true
            }
          }))
      : [];
    const partnerships = this.partnershipStore.records
      ? this.partnershipStore.records
          .filter((item) => this.searchInactive || item.active)
          .map((item) => ({
            ...item,
            __namespace: 'partnerships/' + item._id.$oid,
            __text: item.name,
            __label: {
              color: PARTNERSHIP_DESCRIPTOR.colour.name,
              empty: true,
              circular: true
            }
          }))
      : [];
    const ltdcos = this.ltdcoStore.records
      ? this.ltdcoStore.records
          .filter((item) => this.searchInactive || item.active)
          .map((item) => ({
            ...item,
            __namespace: 'ltd_companies/' + item._id.$oid,
            __text: item.name,
            __label: {
              color: LTDCO_DESCRIPTOR.colour.name,
              empty: true,
              circular: true
            }
          }))
      : [];
    const leads = this.leadStore.records
      ? this.leadStore.records
          .filter((item) => this.searchInactive || item.active)
          .map((item) => ({
            ...{
              ...item,
              address: toJS(item.address),
              operating_address: toJS(item.operating_address),
              directors: toJS(item.directors)
            },
            __namespace: 'leads/' + item._id.$oid,
            __text: item.name,
            __label: {
              color: LEAD_DESCRIPTOR.colour.name,
              empty: true,
              circular: true
            }
          }))
      : [];

    return clients.concat(partnerships, ltdcos, leads);
  }

  @action
  toggleSearchInactive() {
    this.searchInactive = !this.searchInactive;
  }
}

export const rootStore = new RootStore();
export const RootContext = new React.createContext({});
